import {
  DependsOnConstraint,
  RoundDownMaxConstraint,
  RoundUpIncrementConstraint,
  RoundUpMinConstraint,
} from "./inputConstraints";

export enum VariantOutputStatus {
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  CANCELLED = "CANCELLED",
}

export enum VariantOutputType {
  RFA = "RFA",
  THUMBNAIL = "THUMBNAIL",
  BOM = "BOM",
}

export type DynamicContentInput =
  | IPropertyInput
  | BooleanInput
  | TextInput
  | NumericInput
  | MultiValueNumericInput
  | MultiValueTextInput;

/**
 * Interface definition for a dynamic content templates.
 */
export interface DynamicContentProduct {
  schemaVersion: number;
  // Tenancy Id i.e. unique id of project
  tenancyId: string;
  // The ID of the content i.e. unique Id of this product template
  contentId: string;
  // The location of the input data set i.e. the urn or relative file path
  dataSetLocation: string;
  name: string;
  // The objectKey containing the location of the
  // thumbnail for this template
  thumbnail: string;
  context: TemplateContext;
  inputs: DynamicContentInput[];
  rules: TemplateRule;
  outputs: TemplateOutput[];
  createdAt: string;
  updatedAt: string;
}

export interface TemplateContext {
  // path to project file from top level i.e. "ProjectData/Project.ipj",
  projectFile: string;
  // path to assembly from top level i.e. "ProjectData/TopLevelAssembly.iam"
  topLevelAssembly: string;
  engine: {
    // location of engine i.e. DA4I or Local_Inventor
    location: string;
    // engine version i.e. 2023
    version: string;
  };
  workspace: {
    location: string; // bimdocs or Forge content or S3
    // This is the full path to the folder on the location
    // i.e. /parent1-urn/parent2-urn/parent3-urn/folder-urn
    folderPath: string;
  };
}

/**
 * Input Definitions
 */
export enum TemplateInputType {
  IProperty = "iProperty",
  Boolean = "boolean",
  Numeric = "numeric",
  MultiValueNumeric = "multi-value-numeric",
  MultiValueText = "multi-value-text",
  Text = "text",
}

export interface TemplateInput {
  name: string;
  type: TemplateInputType;
  label: string;
  visible: boolean;
  readOnly: boolean;
}

export interface IPropertyInput extends TemplateInput {
  type: TemplateInputType.IProperty;
  category: string;
  value: string;
}
export interface BooleanInput extends TemplateInput {
  type: TemplateInputType.Boolean;
  value: boolean;
  trueLabel?: string;
  falseLabel?: string;
  // TODO: change to onChange: DependsOnConstraint[];
  // when we have dependsOn implemented. should not be undefined
  onChange?: DependsOnConstraint[];
}

export interface TextInput extends TemplateInput {
  type: TemplateInputType.Text;
  value: string;
  unit: string;
}

export interface NumericInput extends TemplateInput {
  type: TemplateInputType.Numeric;
  unit: string;
  value: number;
  min?: number;
  max?: number;
  increment?: number;
  // TODO: change to onChange: ...
  // when we have Round Constraint implemented. should not be undefined
  onChange?: (RoundUpMinConstraint | RoundDownMaxConstraint | RoundUpIncrementConstraint)[];
}

export interface MultiValueNumericInput extends TemplateInput {
  type: TemplateInputType.MultiValueNumeric;
  unit: string;
  min?: number;
  max?: number;
  values: number[];
  value: number | undefined;
  allowCustomValue: boolean;
  // TODO: change to onChange: ...
  // when we have Round Constraint implemented. should not be undefined
  onChange: (RoundUpMinConstraint | RoundDownMaxConstraint)[];
}

export interface MultiValueTextInput extends TemplateInput {
  type: TemplateInputType.MultiValueText;
  unit: string;
  values: string[];
  value: string | undefined;
}

/**
 * Rules
 */
export interface TemplateRule {
  [key: string]: {
    ruleLabel?: string;
    errorMessage?: string;
    code: string;
  };
}

export interface TemplateOutput {
  type: string;
  options?: {
    modelStates?: string[];
    category?: string;
    family?: string;
  };
}

export interface DynamicContentVariant extends Omit<DynamicContentProduct, "outputs"> {
  variantId: string;
  contentId: string;
  outputs: DynamicContentVariantOutput[];
  createdAt: string;
  updatedAt: string;
}

export interface DynamicContentVariantOutput {
  type: string;
  modelState?: string;
  category?: string;
  family?: string;
  status: VariantOutputStatus;
  urn?: string;
}

export interface VariantsQueryParameters {
  productId: string;
  projectId: string;
}

export interface BatchFetchVariantsResult {
  results: DynamicContentVariant[];
  errors: PromiseRejectedResult[];
}

export interface BatchFetchProductsResult {
  results: DynamicContentProduct[];
  errors: PromiseRejectedResult[];
}
