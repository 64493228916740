// import of React is required for jest mocking
// eslint-disable-next-line
import React, { useRef, useEffect, useContext, FC } from "react";
import { ViewerWapper } from "./ModelViewer.style";
import viewerService from "../../../services/viewer/viewerService";
import UserContext from "../../../context/UserStore/User.context";
import AccountProjectContext from "../../../context/AccountProjectStore/AccountProject.context";
import { MODEL_VIEWER_TIMER } from "../../../global/constants/model";
import ModelSelectionContext from "../../../context/ModelSelectionStore/ModelSelection.context";

export const ModelViewer: FC = () => {
  const viewerRef = useRef<HTMLDivElement>(null);
  const { token } = useContext(UserContext);
  const { currentlyOpenModel: currentlyOpenModel } = useContext(ModelSelectionContext);

  const { projectId } = useContext(AccountProjectContext);

  useEffect(() => {
    async function initialize() {
      if (viewerRef.current) {
        viewerRef.current.appendChild(viewerService.viewerNode);
        viewerService.resizeViewer();
      }

      if (!viewerService.initialized) {
        await viewerService.initializeViewer({
          documentId: currentlyOpenModel?.lmvModelFileId,
          projectId: projectId || "",
          getAccessToken: (get) => {
            get(token, MODEL_VIEWER_TIMER);
          },
        });
      }
    }

    initialize();
  }, [currentlyOpenModel?.lmvModelFileId, projectId, token]);

  useEffect(() => {
    if (projectId && currentlyOpenModel?.lmvModelFileId && viewerService.initialized) {
      // Load document
      viewerService.loadDocument(projectId, currentlyOpenModel?.lmvModelFileId);
    }
  }, [projectId, currentlyOpenModel?.lmvModelFileId]);
  return <ViewerWapper ref={viewerRef} />;
};
