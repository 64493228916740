import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import { adskColors } from "../InsightsPage.styles";
import { randomInt } from "./mocks";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
  indexAxis: "y" as const,
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {},
    title: {
      display: true,
      text: "Manufacturing Efficiency",
    },
  },
};

const labels = ["Steel Stairs", "Wooden Stairs", "Balconies", "Railings"];

export const data = {
  labels,
  datasets: [
    {
      label: "Variants per product",
      data: labels.map(() => randomInt(10, 100)),
      borderColor: adskColors[0],
      backgroundColor: adskColors[0],
    },
    {
      label: "Instances per variants",
      data: labels.map(() => randomInt(10, 100)),
      borderColor: adskColors[1],
      backgroundColor: adskColors[1],
    },
  ],
};

export const HorizontalBarChart2 = (): JSX.Element => <Bar options={options} data={data} />;
