import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

export const ConfirmationDialogContainer = styled(Box)(() => ({
  margin: "auto",
  marginTop: "30vh",
  maxWidth: "600px",
  backgroundColor: "white",
  borderRadius: "2px",
  boxShadow: "0px 0px 10px 0px #444",
}));

export const ConfirmationDialogTitle = styled(Box)(() => ({
  padding: "24px",
}));

export const ConfirmationDialogBody = styled(Box)(() => ({
  padding: "24px",
}));

export const ConfirmationDialogFooter = styled(Box)(() => ({
  padding: "24px",
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  gap: "8px",
}));
