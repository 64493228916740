import { useContext, useEffect, useState } from "react";

import ModelDetails from "../../components/ProductsPage/ModelDetails";
import ModelsFolderBrowser from "../../components/ProductsPage/ModelsFolderBrowser";

import { BIM360Document } from "types/bim360";
import VerticalTabBar from "../../components/Commons/VerticalTabBar";
import InstancesPanel from "../../components/ProductsPage/InstancesPanel";
import { ModelsFolderContent } from "../../components/ProductsPage/ModelsFolderContent/ModelsFolderContent";
import AccountProjectContext from "../../context/AccountProjectStore/AccountProject.context";
import ModelSelectionContext from "../../context/ModelSelectionStore/ModelSelection.context";
import ProductContext from "../../context/ProductStore/Product.context";
import { ProductsSubTabsContext } from "../../context/TabStore/Tab.context";
import UserContext from "../../context/UserStore/User.context";
import { productsSubTabs, PRODUCTS_SUBTABS } from "../../global/constants/tabs";
import { getFolderContent } from "../../services/bim360";
import { ProductsPageContainer, ProductsPageTabBar } from "./ProductsPage.styles";

import NotificationContext from "../../context/NotificationStore/Notification.context";

const ProductsPage = (): JSX.Element => {
  const { token } = useContext(UserContext);
  const { projectId, hasProjectChanged } = useContext(AccountProjectContext);
  const { resetProductStoreState } = useContext(ProductContext);
  const { selectedProductsSubTab, setSelectedProductsSubTab } = useContext(ProductsSubTabsContext);
  const { currentlyOpenModel, selectedModelFolder, resetModelSelectionStoreState, setSelectedModelFolder } =
    useContext(ModelSelectionContext);

  const { logAndShowNotification } = useContext(NotificationContext);

  const [lmvModelFile, setLmvModelFile] = useState<BIM360Document[] | null>(null);
  const [folderUrn, setFolderUrn] = useState<string>("");

  const [isPreviewLoading, setIsPreviewLoading] = useState<boolean>(false);

  const handleFolderSelection = async (folderUrn: string) => {
    if (!projectId) {
      return;
    }

    try {
      const folderContentResult = await getFolderContent(token, projectId, folderUrn);
      setFolderUrn(folderUrn);
      setLmvModelFile(folderContentResult);
    } catch (error) {
      logAndShowNotification({ error });
    }
  };

  const handleChangeProductsSubTab = (_: any, value: string) => {
    setSelectedProductsSubTab(value);
  };

  useEffect(() => {
    if (hasProjectChanged) {
      setSelectedProductsSubTab(PRODUCTS_SUBTABS.MODELS);
      setLmvModelFile(null);
      resetModelSelectionStoreState();
      resetProductStoreState();
    }
  }, [hasProjectChanged, resetModelSelectionStoreState, resetProductStoreState, setSelectedProductsSubTab]);

  return (
    <ProductsPageContainer>
      <ProductsPageTabBar>
        <VerticalTabBar
          value={selectedProductsSubTab}
          onChange={handleChangeProductsSubTab}
          tabs={[productsSubTabs.MODELS, { ...productsSubTabs.INSTANCES, disabled: !currentlyOpenModel }]}
          ariaLabel={productsSubTabs[selectedProductsSubTab as keyof typeof PRODUCTS_SUBTABS].label}
        />
      </ProductsPageTabBar>
      {selectedProductsSubTab === PRODUCTS_SUBTABS.INSTANCES && <InstancesPanel />}
      {selectedProductsSubTab === PRODUCTS_SUBTABS.MODELS && (
        <>
          <ModelsFolderBrowser handleFolderSelection={handleFolderSelection} />
          <ModelsFolderContent
            folderUrn={folderUrn}
            lmvModelFiles={lmvModelFile}
            selectedModelFolder={selectedModelFolder}
            setIsPreviewLoading={setIsPreviewLoading}
            setSelectedModelFolder={setSelectedModelFolder}
          />
          {(selectedModelFolder || isPreviewLoading) && (
            <ModelDetails
              isPreviewLoading={isPreviewLoading}
              folderUrn={folderUrn}
              selectedModelFolder={selectedModelFolder}
              setSelectedModelFolder={setSelectedModelFolder}
              setProductsSelectedSubTab={setSelectedProductsSubTab}
            />
          )}
        </>
      )}
    </ProductsPageContainer>
  );
};

export default ProductsPage;
