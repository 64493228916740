import axios from 'axios';
import * as _ from 'underscore';

/**
 * Creates a http request which return a promise
 * @param url - request URL
 * @param headers - request headers
 * @param method - Request type. Valid values are GET|POST
 * @param data - Body of the http request
 * @param options Request options
 * @return Promise containing the http response
 * @hidden
 */
export function requestHTTPPromise( url: string,
                                    headers: object,
                                    method: string = 'GET',
                                    data: string = '',
                                    options: object = {}): Promise<any> {
  method = method || 'GET';
  method.toLowerCase();
  return axios(_.extend({
  data,
  headers,
  method,
  url,
  }, options));
}

/**
 * Calculate expiry time from expires_in parameter.
 * @param expiresIn The amount of seconds from creation of the token until its expiry.
 * @return The expiry time (in date format) of the token.
 * @package
 * @hidden
 */
export function getTokenExpiry(expiresIn: string): number {
  // Subtract one minute to be on the safe side
  return Date.now() + ((parseInt(expiresIn, 10) - 60) * 1000);
}

/**
 * Remove duplicate entries from a list of scopes
 * @param scopes A space separated list of scopes
 * @return A list of scopes without duplicates
 * @package
 * @hidden
 */
export function removeDuplicateScopes(scopes: string): string {
  // Parse entries into array
  const scopeArray = scopes.split(' ');

  // Filter out duplicates
  const uniqueArray = scopeArray.filter((item, pos) => {
    return scopeArray.indexOf(item) === pos;
  });

  // Transform back into a string
  return uniqueArray.join(' ');
}

/**
 * Checks whether a given array is a superset of another array.
 * Note: There is no special treatment for duplicate entries. Ideally, there shouldn't be duplicates in the arrays.
 * @param firstArray The array that is checked.
 * @param secondArray The base array that <b>firstArray<b/> is checked against.
 * @return True, if <b>firstArray</b> is a superset of <b>secondArray</b>, false otherwise.
 * @hidden
 */
export function isSuperSet(firstArray: any[], secondArray: any[]): boolean {
  let superSet = true;
  for (const v2 of secondArray) {
    if (firstArray.indexOf(v2) === -1) {
      superSet = false;
      break;
    }
  }
  return superSet;
}
