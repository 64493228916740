import { useContext } from "react";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { AccountImage, AccountListContainer, AccountsListTitle, AccountsMenuList } from "./AccountsList.style";
import { BIM360Account } from "types/bim360";
import AccountProjectContext from "../../../context/AccountProjectStore/AccountProject.context";
import { BIM360StorageKeys } from "../../../global/constants/bim360";
import text from "../../../global/text.json";

interface AccountsListProps {
  visible: boolean;
  accounts: BIM360Account[] | undefined;
}

export const AccountsList = ({ visible, accounts }: AccountsListProps): JSX.Element => {
  const { setAccountId, setAccountName, setAccountImg, setProjectId, setProjectName } = useContext(AccountProjectContext);

  const handleAccountItemClick = (selectedAccount: BIM360Account) => {
    const { account_id, account_display_name, image_url } = selectedAccount;

    // Set selected account
    setAccountId(account_id);
    setAccountName(account_display_name);
    setAccountImg(image_url || null);
    window.localStorage.setItem(
      BIM360StorageKeys.SELECTED_ACCOUNT,
      JSON.stringify({
        accountId: account_id,
        accountName: account_display_name,
        accountImg: image_url,
      })
    );

    // Reset project
    setProjectId(null);
    setProjectName(null);
    window.localStorage.removeItem(BIM360StorageKeys.SELECTED_PROJECT);
  };
  const accountsText = text.accountsList;

  return (
    <AccountListContainer visible={visible}>
      <AccountsListTitle>
        <Typography variant="h6">{accountsText.accounts}</Typography>
      </AccountsListTitle>
      {accounts && (
        <AccountsMenuList>
          {accounts.map((account) => (
            <MenuItem key={account.account_display_name} onClick={() => handleAccountItemClick(account)}>
              <ListItemAvatar>
                <AccountImage src={account.image_url} />
              </ListItemAvatar>
              <Typography variant="body2">{account.account_display_name}</Typography>
            </MenuItem>
          ))}
        </AccountsMenuList>
      )}
    </AccountListContainer>
  );
};
