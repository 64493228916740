import { CircularProgress } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import NotificationContext from "../../context/NotificationStore/Notification.context";

export interface GatekeeperProps {
  children: any;
  initialize: () => Promise<any>;
}

const Gatekeeper: React.FC<GatekeeperProps> = ({ children, initialize }) => {
  const [loading, setLoading] = useState(true);
  const { logAndShowNotification } = useContext(NotificationContext);

  useEffect(() => {
    if (!loading) {
      return;
    }

    initialize()
      .catch((error) => {
        logAndShowNotification({ error });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [loading, initialize, logAndShowNotification]);

  return loading ? <CircularProgress /> : <>{children}</>;
};

export default Gatekeeper;
