import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

export const AccountDetailsContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  alignContent: "top",
  padding: "15px",
  minWidth: "400px",
  maxWidth: "400px",
}));

export const AccountImage = styled("img")(() => ({
  height: "48px",
  width: "auto",
}));

export const AccountTitleContainer = styled(Box)(() => ({
  marginLeft: "10px",
}));

export const AccountInfo = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}));
