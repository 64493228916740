import React, { useContext, useState, useEffect } from "react";

import Button from "@mui/material/Button";

import MissingThumbnail from "../../../../src/images/missingThumbnail.jpg";
import ConfirmationDialog from "../../Commons/ConfirmationDialog";
import KeyValueTable from "../../Commons/KeyValueTable";
import { KeyValueTableRow } from "../../../types/KeyValueTable";
import SidebarThumbnail from "../../Commons/SidebarThumbnail";

import AccountProjectContext from "../../../context/AccountProjectStore/AccountProject.context";
import { PRODUCTS_SUBTABS } from "../../../global/constants/tabs";
import { bim360Config } from "../../../services/config";
import CustomAccordion from "../../Commons/CustomAccordion";
import { DetailsSidebarContent, DetailsSidebarHeader } from "../../Commons/DetailsSidebar";
import { FolderContentRow } from "../ModelsFolderContent/ModelsFolderContent.types";
import { ModelDetailsSpinner, ModelDetailsSidebar } from "./ModelDetails.style";
import ProductContext from "../../../context/ProductStore/Product.context";
import text from "../../../global/text.json";
import ModelSelectionContext from "../../../context/ModelSelectionStore/ModelSelection.context";

interface ModelDetailsProps {
  folderUrn: string;
  selectedModelFolder: FolderContentRow | null;
  isPreviewLoading: boolean;
  setSelectedModelFolder: React.Dispatch<React.SetStateAction<FolderContentRow | null>>;
  setProductsSelectedSubTab: React.Dispatch<React.SetStateAction<string>>;
}

export const ModelDetails: React.FC<ModelDetailsProps> = ({
  folderUrn,
  selectedModelFolder,
  isPreviewLoading,
  setSelectedModelFolder,
  setProductsSelectedSubTab,
}): JSX.Element => {
  const { currentlyOpenModel, setCurrentlyOpenModel } = useContext(ModelSelectionContext);
  const { projectId } = useContext(AccountProjectContext);
  const { setInstances, setFilteredProductId } = useContext(ProductContext);
  const [openConfirmReplace, setOpenConfirmReplace] = useState(false);

  const handleConfirmReplaceOpen = () => setOpenConfirmReplace(true);
  const handleConfirmReplaceClose = () => setOpenConfirmReplace(false);

  const [openConfirmUnselect, setOpenConfirmUnselect] = useState(false);
  const handleShowCloseModelConfirmation = () => setOpenConfirmUnselect(true);
  const handleDismissCloseModelConfirmation = () => setOpenConfirmUnselect(false);

  const getModeLMVUrlLink = (
    BimDocsUrl: string,
    projectId: string | null,
    folderUrn: string,
    row: FolderContentRow | null
  ) => {
    if (projectId && row) {
      const url = BimDocsUrl + "/" + projectId + "/folders/" + folderUrn + "/detail/viewer/items/" + row.urn;
      return (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {row.fileName}
        </a>
      );
    }
    return row?.fileName;
  };

  const modelDetailsText = text.modelDetails;
  const fileInfo: KeyValueTableRow[] = [
    {
      label: modelDetailsText.name,
      value: getModeLMVUrlLink(bim360Config.documentsManagerUrl, projectId, folderUrn, selectedModelFolder),
    },
    { label: modelDetailsText.version, value: selectedModelFolder?.version },
    {
      label: modelDetailsText.lastUpdated,
      value: selectedModelFolder?.modifiedOn,
    },
    {
      label: modelDetailsText.products,
      value: selectedModelFolder?.productsCount,
    },
    {
      label: modelDetailsText.variants,
      value: selectedModelFolder?.variantsCount,
    },
    {
      label: modelDetailsText.instances,
      value: selectedModelFolder?.instancesCount,
    },
    {
      label: modelDetailsText.selected,
      value:
        selectedModelFolder?.selectedCount && selectedModelFolder?.instancesCount
          ? `${selectedModelFolder?.selectedCount} of ${selectedModelFolder?.instancesCount}`
          : undefined,
    },
  ];

  const handleOpenModelClick = () => {
    setCurrentlyOpenModel(selectedModelFolder);
    setFilteredProductId(undefined);
    setInstances(undefined);
    setProductsSelectedSubTab(PRODUCTS_SUBTABS.INSTANCES);
  };

  const handleCloseButtonClick = () => {
    setSelectedModelFolder(null);
  };

  const handleCloseModel = () => {
    setCurrentlyOpenModel(null);
  };

  useEffect(() => {
    // clear data grid when new project has been selected
    if (projectId) {
      setSelectedModelFolder(null);
    }
  }, [projectId, setSelectedModelFolder]);

  return (
    <>
      <ModelDetailsSidebar>
        {isPreviewLoading ? (
          <ModelDetailsSpinner size={75} />
        ) : (
          <>
            <DetailsSidebarHeader onClose={handleCloseButtonClick}>
              {!currentlyOpenModel && (
                <Button variant="contained" onClick={handleOpenModelClick} data-testid="modelDetailsOpenButton">
                  {modelDetailsText.openModel}
                </Button>
              )}
              {currentlyOpenModel && currentlyOpenModel.id === selectedModelFolder?.id && (
                <Button variant="outlined" onClick={handleShowCloseModelConfirmation} data-testid="modelDetailsCloseButton">
                  {modelDetailsText.closeModel}
                </Button>
              )}
              {currentlyOpenModel && currentlyOpenModel.id !== selectedModelFolder?.id && (
                <Button
                  variant="contained"
                  onClick={handleConfirmReplaceOpen}
                  size="small"
                  data-testid="modelDetailsChangeButton"
                >
                  {modelDetailsText.changeModel}
                </Button>
              )}
            </DetailsSidebarHeader>
            <DetailsSidebarContent>
              <CustomAccordion title={modelDetailsText.preview}>
                {selectedModelFolder && selectedModelFolder.thumbnail ? (
                  <SidebarThumbnail src={URL.createObjectURL(selectedModelFolder.thumbnail)} />
                ) : (
                  <SidebarThumbnail src={MissingThumbnail} />
                )}
              </CustomAccordion>
              <CustomAccordion title={modelDetailsText.modelDetails}>
                <KeyValueTable data={fileInfo} />
              </CustomAccordion>
            </DetailsSidebarContent>
          </>
        )}
      </ModelDetailsSidebar>
      <ConfirmationDialog
        open={openConfirmReplace}
        message={modelDetailsText.replaceModel}
        onCancel={handleConfirmReplaceClose}
        onConfirm={() => {
          handleOpenModelClick();
          handleConfirmReplaceClose();
        }}
        data-testid="replaceModel"
      />
      <ConfirmationDialog
        open={openConfirmUnselect}
        message={modelDetailsText.unselectModel}
        onCancel={handleDismissCloseModelConfirmation}
        onConfirm={() => {
          handleCloseModel();
          handleDismissCloseModelConfirmation();
        }}
        data-testid="unselectModel"
      />
    </>
  );
};
