//A React Hook that returns the thumbnail of an instance.
import { useCallback, useContext, useEffect, useState } from "react";
import AccountProjectContext from "../../../context/AccountProjectStore/AccountProject.context";
import UserContext from "../../../context/UserStore/User.context";

import NotificationContext from "../../../context/NotificationStore/Notification.context";
import { fetchFileUrl } from "../../../services/products";
import { getBase64Image } from "../../../utils/thumbnail";

import text from "../../../global/text.json";

// Cache locally the thumbnails
const downloadedVariantThumbnails = new Map<string, string>();

interface UseVariantThumbnail {
  variantThumbnail: string | null;
  isVariantThumbnailLoading: boolean;
}

export default function useVariantThumbnail(variantThumbnailId: string | undefined): UseVariantThumbnail {
  const { token } = useContext(UserContext);
  const { projectId } = useContext(AccountProjectContext);
  const { logAndShowNotification } = useContext(NotificationContext);
  const [isVariantThumbnailLoading, setIsVariantThumbnailLoading] = useState(true);

  const [variantThumbnail, setVariantThumbnail] = useState<string | null>(null);

  const fetchThumbnail = useCallback(async () => {
    setIsVariantThumbnailLoading(true);
    if (!token || !variantThumbnailId || !projectId) {
      setIsVariantThumbnailLoading(false);
      return;
    }
    // Check if the thumbnail is already downloaded
    if (downloadedVariantThumbnails.has(variantThumbnailId)) {
      setVariantThumbnail(downloadedVariantThumbnails.get(variantThumbnailId) || null);
      setIsVariantThumbnailLoading(false);
    } else {
      // Otherwise, download the thumbnail
      try {
        const thumbnailUrl = await fetchFileUrl(token, projectId, variantThumbnailId);
        if (thumbnailUrl) {
          const base64Image = await getBase64Image(thumbnailUrl);
          downloadedVariantThumbnails.set(variantThumbnailId, base64Image);
          setVariantThumbnail(base64Image);
        }
      } catch (error) {
        logAndShowNotification({
          message: text.useVariantThumbnail.thumbnailLoadError,
        });
      } finally {
        setIsVariantThumbnailLoading(false);
      }
    }
  }, [token, variantThumbnailId, projectId, logAndShowNotification]);

  useEffect(() => {
    if (variantThumbnailId) {
      fetchThumbnail();
    } else {
      setIsVariantThumbnailLoading(false);
    }
  }, [variantThumbnailId, fetchThumbnail]);

  return { variantThumbnail, isVariantThumbnailLoading };
}
