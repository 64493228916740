import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";

export const VerticalTabBarContainer = styled(Box)(() => ({
  minWidth: "100px",
  maxWidth: "100px",
  flexGrow: 0,
  height: "100%",
  overflow: "hidden",
  display: "flex",
  flexDirection: "row",
  alignItems: "stretch",
}));

export const VerticalTabTabs = styled(Tabs)(() => ({
  backgroundColor: "#eee",
  minWidth: "100px",
  maxWidth: "100px",
}));
