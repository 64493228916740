import React from "react";
import "./App.css";
import ProductsPage from "./pages/ProductsPage";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import Gatekeeper from "./components/GateKeeper/Gatekeeper";
import HeaderAppBar from "./components/Header/";
import AccountProjectContext from "./context/AccountProjectStore/AccountProject.context";
import { useAccountProjectsStore } from "./context/AccountProjectStore/accountProjectStore";
import NotificationContext from "./context/NotificationStore/Notification.context";
import { useNotificationStore } from "./context/NotificationStore/notificationStore";
import ProductContext from "./context/ProductStore/Product.context";
import { useProductStore } from "./context/ProductStore/productStore";
import { MainTabsContext, ProductsSubTabsContext, OutputsSubTabsContext } from "./context/TabStore/Tab.context";
import { useMainTabsStore, useProductsSubTabsStore, useOutputsSubTabsStore } from "./context/TabStore/tabStore";
import UserContext from "./context/UserStore/User.context";
import { useUserStore } from "./context/UserStore/userStore";
import { Alert, Snackbar, ThemeProvider } from "@mui/material";
import OutputsPage from "./pages/OutputsPage";
import { lightTheme } from "./utils/theme";
import ModelSelectionContext from "./context/ModelSelectionStore/ModelSelection.context";
import { useModelSelectionStore } from "./context/ModelSelectionStore/modelSelectionStore";
import InsightsPage from "./pages/InsightsPage";
import { mainTabs, MAIN_TABS } from "./global/constants/tabs";
import { AppContainer, TabsContainer } from "./App.style";

export const AppWithNotifications: React.FC = () => {
  const notificationStore = useNotificationStore();
  const { open, message, severity, handleClose } = notificationStore;
  return (
    <NotificationContext.Provider value={notificationStore}>
      <App />
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </NotificationContext.Provider>
  );
};

const App: React.FC = () => {
  const userStore = useUserStore();
  const accountProjectsStore = useAccountProjectsStore();
  const productStore = useProductStore({
    token: userStore.token,
    projectId: accountProjectsStore.projectId,
  });
  const modelSelectionStore = useModelSelectionStore();
  const mainTabsStore = useMainTabsStore();
  const productsSubTabsStore = useProductsSubTabsStore();
  const outputsSubTabsStore = useOutputsSubTabsStore();

  return (
    <Gatekeeper initialize={userStore.initialize}>
      <UserContext.Provider value={userStore}>
        <AccountProjectContext.Provider value={accountProjectsStore}>
          <ThemeProvider theme={lightTheme}>
            <MainTabsContext.Provider value={mainTabsStore}>
              <ProductsSubTabsContext.Provider value={productsSubTabsStore}>
                <OutputsSubTabsContext.Provider value={outputsSubTabsStore}>
                  <AppContainer>
                    <HeaderAppBar />
                    <TabsContainer>
                      <Tabs
                        value={mainTabsStore.selectedTab}
                        onChange={mainTabsStore.handleChangeTab}
                        aria-label={mainTabs[mainTabsStore.selectedTab].label}
                      >
                        <Tab
                          value={MAIN_TABS.PRODUCTS}
                          label={<Typography variant="body1">{mainTabs.PRODUCTS.label}</Typography>}
                        />
                        <Tab
                          value={MAIN_TABS.OUTPUTS}
                          label={<Typography variant="body1">{mainTabs.OUTPUTS.label}</Typography>}
                        />
                        <Tab
                          value={MAIN_TABS.INSIGHTS}
                          label={<Typography variant="body1">{mainTabs.INSIGHTS.label}</Typography>}
                        />
                      </Tabs>
                    </TabsContainer>
                    <ProductContext.Provider value={productStore}>
                      {mainTabsStore.selectedTab === MAIN_TABS.PRODUCTS && (
                        <ModelSelectionContext.Provider value={modelSelectionStore}>
                          <ProductsPage />
                        </ModelSelectionContext.Provider>
                      )}
                      {mainTabsStore.selectedTab === MAIN_TABS.OUTPUTS && <OutputsPage />}
                      {mainTabsStore.selectedTab === MAIN_TABS.INSIGHTS && <InsightsPage />}
                    </ProductContext.Provider>
                  </AppContainer>
                </OutputsSubTabsContext.Provider>
              </ProductsSubTabsContext.Provider>
            </MainTabsContext.Provider>
          </ThemeProvider>
        </AccountProjectContext.Provider>
      </UserContext.Provider>
    </Gatekeeper>
  );
};

export default App;
