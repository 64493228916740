import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import { adskColors } from "../InsightsPage.styles";
import { randomInt } from "./mocks";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
  indexAxis: "y" as const,
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: "Top Projects / # of instances per project",
    },
  },
};

const labels = [
  "Ares Building",
  "Zeus Complex",
  "Hermes Yards",
  "Apollo Square",
  "Olympus Gardens",
  "Artemis Residence",
  "Poseidon Quarters",
  "Hestia Skyline",
  "Aphrodite Palace",
];

export const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: labels
        .map(() => randomInt(100, 1000))
        .sort()
        .reverse(),
      borderColor: adskColors,
      backgroundColor: adskColors,
    },
  ],
};

export const HorizontalBarChart = (): JSX.Element => <Bar options={options} data={data} />;
