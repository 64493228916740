import { GridRowId } from "@mui/x-data-grid";
import { useState } from "react";
import { Tree } from "../../components/ProductsPage/ModelsFolderBrowser/useModelFolderBrowser";
import { FolderContentRow } from "../../components/ProductsPage/ModelsFolderContent/ModelsFolderContent.types";

export interface ModelSelectionStore {
  modelFoldersTree: Tree | undefined;
  setProductFoldersTree: React.Dispatch<React.SetStateAction<Tree | undefined>>;
  expandedModelTreeIds: string[];
  setExpandedTreeNodeIds: React.Dispatch<React.SetStateAction<string[]>>;
  selectedModelFolder: FolderContentRow | null;
  setSelectedModelFolder: React.Dispatch<React.SetStateAction<FolderContentRow | null>>;
  productFoldersContent: FolderContentRow[];
  setProductFoldersContent: React.Dispatch<React.SetStateAction<FolderContentRow[]>>;
  selectedModelId: GridRowId | undefined;
  setSelectedModelId: React.Dispatch<React.SetStateAction<GridRowId | undefined>>;
  currentlyOpenModel: FolderContentRow | null;
  setCurrentlyOpenModel: React.Dispatch<React.SetStateAction<FolderContentRow | null>>;
  resetModelSelectionStoreState: () => void;
}
export const useModelSelectionStore = (): ModelSelectionStore => {
  const [selectedModelFolder, setSelectedModelFolder] = useState<FolderContentRow | null>(null);
  const [productFoldersContent, setProductFoldersContent] = useState<FolderContentRow[]>([]);
  const [expandedModelTreeIds, setExpandedTreeNodeIds] = useState<string[]>([]);
  const [modelFoldersTree, setProductFoldersTree] = useState<Tree | undefined>(undefined);
  const [selectedModelId, setSelectedModelId] = useState<GridRowId | undefined>(undefined);
  const [currentlyOpenModel, setCurrentlyOpenModel] = useState<FolderContentRow | null>(null);

  const resetModelSelectionStoreState = () => {
    setProductFoldersTree(undefined);
    setSelectedModelFolder(null);
    setProductFoldersContent([]);
    setExpandedTreeNodeIds([]);
    setSelectedModelId(undefined);
    setCurrentlyOpenModel(null);
  };

  return {
    modelFoldersTree,
    setProductFoldersTree,
    expandedModelTreeIds,
    setExpandedTreeNodeIds,
    productFoldersContent,
    setProductFoldersContent,
    selectedModelFolder,
    setSelectedModelFolder,
    selectedModelId,
    setSelectedModelId,
    currentlyOpenModel,
    setCurrentlyOpenModel,
    resetModelSelectionStoreState,
  };
};
