export enum PollState {
  INDETERMINATE = "INDETERMINATE",
  READY = "READY",
  POLLING = "POLLING",
  FINISHED = "FINISHED",
  ERROR = "ERROR",
}

export interface UsePollingState<DataType> {
  data: DataType | null;
  pollState: PollState;
  startPolling: () => void;
}

export interface PollingConfig {
  interval?: number;
}
