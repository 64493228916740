import { IForgeConfiguration } from "@adsk/forge-appfw-forge-configuration";
import { ViewerOptions } from "../types/viewerService";
import endpoints from "../global/constants/endpoints";
import environment from "../global/constants/environment";

const {
  PRD_FORGE_API_URL,
  NON_PRD_FORGE_API_URL,
  DM_API_PATH,
  DM_STG_API_PATH,
  PROJECTS_PATH,
  ACCOUNTS_PATH,
  PROFILE_SETTING_NON_PRD_URL,
  PROFILE_SETTING_PRD_URL,
  DOCUMENT_THUMBNAIL_PATH,
  MODEL_DERIVATIVE_PATH,
  NON_PRD_DOCUMENTS_MANAGER_URL,
  PRD_DOCUMENTS_MANAGER_URL,
  PRD_OFFSITE_API_URL,
  STG_OFFSITE_API_URL,
  DEV_OFFSITE_API_URL,
  STG_INVENTOR_ADDIN_MSI,
  PRD_INVENTOR_ADDIN_MSI,
  STG_REVIT_ADDIN_MSI,
  PRD_REVIT_ADDIN_MSI,
} = endpoints;

const { PRD_VIEWER_ENV, NON_PRD_VIEWER_ENV } = environment;

export enum ServiceTypes {
  offsiteAPI = "OFFSITE_SERVICES_API",
  FORGEAPI = "FORGE_API",
}

export enum Environment {
  DEV = "dev",
  STG = "stg",
  PRD = "prd",
}

export const ServiceConfigMap = {
  [ServiceTypes.FORGEAPI]: {
    [Environment.PRD]: {
      api: PRD_FORGE_API_URL,
    },
    [Environment.STG]: {
      api: NON_PRD_FORGE_API_URL,
    },
    [Environment.DEV]: {
      api: NON_PRD_FORGE_API_URL,
    },
  },
  [ServiceTypes.offsiteAPI]: {
    [Environment.PRD]: {
      api: PRD_OFFSITE_API_URL,
    },
    [Environment.STG]: {
      api: STG_OFFSITE_API_URL,
    },
    [Environment.DEV]: {
      api: DEV_OFFSITE_API_URL,
    },
  },
};

export const isProdEnvironment = (env: Environment): boolean => env === Environment.PRD;

export const forgeConfig: IForgeConfiguration = {
  environmentBaseUrl:
    ServiceConfigMap[ServiceTypes.FORGEAPI][(process.env.REACT_APP_ENVIRONMENT as Environment) || Environment.DEV].api,
};

export const authenticationConfig = {
  clientId: process.env.REACT_APP_AUTH_CONFIG_CLIENT_ID || "CONFIGURE_IN_ENV",
  authenticationEndpoint: `${forgeConfig.environmentBaseUrl}/authentication/v1/authorize`,
  callbackUrl: (): string => window.location.origin + "/",
  scope: "data:read data:write data:create data:search bucket:create bucket:read bucket:update bucket:delete account:read",
};

const pathsConfigMap = {
  [Environment.PRD]: {
    PROJECTS_PATH: `${DM_API_PATH}/${PROJECTS_PATH}`,
    PROFILE_SETTING_PATH: PROFILE_SETTING_PRD_URL,
    VIEWER_ENV: PRD_VIEWER_ENV,
    DOCUMENTS_MANAGER_URL: PRD_DOCUMENTS_MANAGER_URL,
    INVENTOR_ADDIN_MSI: PRD_INVENTOR_ADDIN_MSI,
    REVIT_ADDIN_MSI: PRD_REVIT_ADDIN_MSI,
  },
  [Environment.STG]: {
    PROJECTS_PATH: `${DM_STG_API_PATH}/${PROJECTS_PATH}`,
    PROFILE_SETTING_PATH: PROFILE_SETTING_NON_PRD_URL,
    VIEWER_ENV: NON_PRD_VIEWER_ENV,
    DOCUMENTS_MANAGER_URL: NON_PRD_DOCUMENTS_MANAGER_URL,
    INVENTOR_ADDIN_MSI: STG_INVENTOR_ADDIN_MSI,
    REVIT_ADDIN_MSI: STG_REVIT_ADDIN_MSI,
  },
  [Environment.DEV]: {
    PROJECTS_PATH: `${DM_STG_API_PATH}/${PROJECTS_PATH}`,
    PROFILE_SETTING_PATH: PROFILE_SETTING_NON_PRD_URL,
    VIEWER_ENV: NON_PRD_VIEWER_ENV,
    DOCUMENTS_MANAGER_URL: NON_PRD_DOCUMENTS_MANAGER_URL,
    INVENTOR_ADDIN_MSI: STG_INVENTOR_ADDIN_MSI,
    REVIT_ADDIN_MSI: STG_REVIT_ADDIN_MSI,
  },
};

export const addinInstallersConfig = {
  inventorAddinMSI: pathsConfigMap[(process.env.REACT_APP_ENVIRONMENT as Environment) || Environment.DEV].INVENTOR_ADDIN_MSI,
  revitAddinMSI: pathsConfigMap[(process.env.REACT_APP_ENVIRONMENT as Environment) || Environment.DEV].REVIT_ADDIN_MSI,
};

export const bim360Config = {
  accountEntitlementsPath: ACCOUNTS_PATH,
  projectsPath: pathsConfigMap[(process.env.REACT_APP_ENVIRONMENT as Environment) || Environment.DEV].PROJECTS_PATH,
  profileSettingPath:
    pathsConfigMap[(process.env.REACT_APP_ENVIRONMENT as Environment) || Environment.DEV].PROFILE_SETTING_PATH,
  documentThumbnailPath: DOCUMENT_THUMBNAIL_PATH,
  modelDerivativePath: MODEL_DERIVATIVE_PATH,
  documentsManagerUrl:
    pathsConfigMap[(process.env.REACT_APP_ENVIRONMENT as Environment) || Environment.DEV].DOCUMENTS_MANAGER_URL,
};

export const viewerConfig: ViewerOptions = {
  isURN: true,
  env: pathsConfigMap[(process.env.REACT_APP_ENVIRONMENT as Environment) || Environment.DEV].VIEWER_ENV,
  api: undefined,
  useCredentials: true,
  useCookie: false,
  shouldInitializeAuth: true,
  documentId: undefined,
  loadLargestView: true,

  config3d: {
    extensions: [],
    loadAsHidden: false, // Need to be visible to initialize camera et al.
    isAEC: true, // Use light blue background instead of flat grey
    globalOffset: { x: 0, y: 0, z: 0 }, // Make camera operations more sane by not having to offset everything
  },
};
