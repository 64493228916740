import { useCallback, useContext, useEffect } from "react";
import ProductContext from "../../../../context/ProductStore/Product.context";
import { ViewerSelectionEvent } from "../../../../services/viewer/viewer.types";
import viewerService from "../../../../services/viewer/viewerService";
import { Instance } from "../../../../types/product";

interface UseInstancesSelectionProps {
  dataGridInstances: Instance[];
  selectedInstances: Instance[] | undefined;
  setSelectedInstances: React.Dispatch<React.SetStateAction<Instance[] | undefined>>;
  handleInstanceDetailsPanelVisibility: (showInstanceDetailsPanel: boolean) => void;
}

interface UseInstancesSelectionState {
  handleInstancesSelection: (selectedInstanceIds: string[]) => void;
}

const useInstancesSelection = ({
  dataGridInstances,
  selectedInstances,
  setSelectedInstances,
  handleInstanceDetailsPanelVisibility,
}: UseInstancesSelectionProps): UseInstancesSelectionState => {
  const { instances } = useContext(ProductContext);

  const handleInstancesSelection = (selectedInstanceIds: string[]) => {
    // Get the list of selected instances not showing in the data grid
    const notShowingSelectedInstances =
      selectedInstances?.filter(
        (selectedInstance) => !dataGridInstances.some((dataGridInstance) => dataGridInstance.id === selectedInstance.id)
      ) || [];

    // Update the selected instances currently showing
    const selectedGridInstances = dataGridInstances.filter((dataGridInstance) =>
      selectedInstanceIds.includes(dataGridInstance.id)
    );

    const combinedIntances = [...notShowingSelectedInstances, ...selectedGridInstances];
    const idsOfSelectedGridInstances: number[] = combinedIntances.map((instance) => parseInt(instance.id));
    // There is a callback handleViewerSelectionChange
    // that is triggered by the viewer and that function calls
    // handleInstanceDetailsPanelVisibility and set
    // the selected instances data
    viewerService.select(idsOfSelectedGridInstances);
  };

  const handleViewerSelectionChange = useCallback(
    (event: ViewerSelectionEvent) => {
      const selectedDbIdsInViewer: number[] = event.dbIdArray;
      const newSelectedGridInstances: Instance[] =
        instances?.filter((dataGridInstance) => selectedDbIdsInViewer?.includes(parseInt(dataGridInstance.id))) || [];

      // To open & close the Instance Details panel if new instance is selected
      handleInstanceDetailsPanelVisibility(!!newSelectedGridInstances.length);
      setSelectedInstances(newSelectedGridInstances);
    },
    [handleInstanceDetailsPanelVisibility, instances, setSelectedInstances]
  );

  // Add Viewer selection event listener
  useEffect(() => {
    if (instances && instances.length > 0) {
      viewerService.viewer.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, handleViewerSelectionChange);
      return () => viewerService.viewer.removeEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, () => undefined);
    }
  }, [handleViewerSelectionChange, instances]);

  return {
    handleInstancesSelection,
  };
};

export default useInstancesSelection;
