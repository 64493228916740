export default {
  PRD_FORGE_API_URL: "https://developer.api.autodesk.com",
  NON_PRD_FORGE_API_URL: "https://developer-stg.api.autodesk.com",
  BIM360_API_PATH: "bim360/admin/v1",
  ACC_API_PATH: "construction/admin/v1",
  DM_API_PATH: "dm/v1",
  DM_STG_API_PATH: "dm-staging/v1",
  ACCOUNTS_PATH: "ea-api/v1/account_entitlements",
  PROJECTS_PATH: "projects",
  PROFILE_SETTING_NON_PRD_URL: "https://accounts-staging.autodesk.com",
  PROFILE_SETTING_PRD_URL: "https://accounts.autodesk.com",
  DOCUMENT_THUMBNAIL_PATH: "derivativeservice/v2/thumbnails",
  MODEL_DERIVATIVE_PATH: "modelderivative/v2/designdata",
  PRD_DOCUMENTS_MANAGER_URL: "https://docs.b360.autodesk.com/projects",
  NON_PRD_DOCUMENTS_MANAGER_URL: "https://docs.b360-staging.autodesk.com/projects",
  PRD_OFFSITE_API_URL: "https://api.dc.autodesk.com",
  STG_OFFSITE_API_URL: "https://api.stg.dc.autodesk.com",
  DEV_OFFSITE_API_URL: "https://api.dev.dc.autodesk.com",
  STG_INVENTOR_ADDIN_MSI:
    "https://artifacts.stg.mid.autodesk.com/Manufacturing Informed Design Add-In 1.18.2.0 for Inventor 2023.msi",
  PRD_INVENTOR_ADDIN_MSI:
    "https://artifacts.mid.autodesk.com/Manufacturing Informed Design Add-In 1.18.2.0 for Inventor 2023.msi",
  STG_REVIT_ADDIN_MSI:
    "https://artifacts.stg.mid.autodesk.com/Manufacturing Informed Design Add-In 1.9.0.0 for Revit 2023.msi",
  PRD_REVIT_ADDIN_MSI: "https://artifacts.mid.autodesk.com/Manufacturing Informed Design Add-In 1.9.0.0 for Revit 2023.msi",
};
