import { useContext } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { AccountDetailsContainer, AccountImage, AccountTitleContainer, AccountInfo } from "./AccountDetails.style";
import AccountProjectContext from "../../../context/AccountProjectStore/AccountProject.context";
import noProjectImage from "../../../images/3d-settings-icon-light-slate.png";
import text from "../../../global/text.json";

interface AccountDetailsProps {
  projectsQty: number | undefined;
}

export const AccountDetails = ({ projectsQty }: AccountDetailsProps): JSX.Element => {
  const { setAccountId, accountName, accountImg } = useContext(AccountProjectContext);

  const handleChangeAccount = () => {
    setAccountId(null);
  };
  const accountText = text.accountDetails;

  return (
    <AccountDetailsContainer>
      <AccountImage src={accountImg ? accountImg : noProjectImage} alt="" />
      <AccountTitleContainer>
        <Typography variant="body2" noWrap component="div">
          {accountName}
        </Typography>
        <AccountInfo>
          <Typography variant="body2" noWrap component="div">
            {projectsQty} {accountText.projects}
          </Typography>
          <Button onClick={handleChangeAccount}>{accountText.changeAccount}</Button>
        </AccountInfo>
      </AccountTitleContainer>
    </AccountDetailsContainer>
  );
};
