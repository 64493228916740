import logger from "../../../global/logger";
import { DynamicContentInput, TemplateOutput } from "../../../types/dynamicContent";
import { PostVariantInput, PostVariantOutput, PostVariantPayload } from "../../../types/variants";
import text from "../../../global/text.json";

const outputsText = text.useGenerateOutputs;

export const transformToVariantPayload = (
  variantInputs: DynamicContentInput[],
  bomOutput: TemplateOutput
): PostVariantPayload => {
  const inputPayload: PostVariantInput[] = variantInputs.map((input: DynamicContentInput) => {
    const variantInput: PostVariantInput = {
      name: input.name,
      // TODO: The value of a DynamicContentInput should not be potentially undefined.
      // (Looks like this is still also used for draft templates mixing up separate concerns.)
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      value: input.value!,
    };

    return variantInput;
  });

  if (!bomOutput.options?.modelStates || bomOutput.options?.modelStates?.length === 0) {
    logger.error(outputsText.bomHasNoModelState);
    throw new Error(outputsText.bomHasNoModelState);
  }

  const outputPayload: PostVariantOutput[] = [{ type: bomOutput.type, modelState: bomOutput.options.modelStates[0] }];

  const variantPaylaod: PostVariantPayload = {
    inputs: inputPayload,
    outputs: outputPayload,
  };

  return variantPaylaod;
};
