import { useCallback, useEffect, useState, useContext } from "react";
import { batchFetchProducts, batchFetchVariants } from "../../services/products";
import { DynamicContentProduct, DynamicContentVariant } from "../../types/dynamicContent";
import { Instance } from "../../types/product";
import NotificationContext from "../NotificationStore/Notification.context";
import text from "../../global/text.json";

const productStoreText = text.productsStore;
export interface ProductStore {
  products: Map<string, DynamicContentProduct>;
  variants: DynamicContentVariant[];
  productsLoading: boolean;
  instances: Instance[] | undefined;
  filteredProductId: string | undefined;
  setFilteredProductId: React.Dispatch<React.SetStateAction<string | undefined>>;
  setInstances: React.Dispatch<React.SetStateAction<Instance[] | undefined>>;
  resetProductStoreState: () => void;
}

export interface UseProductStore {
  token: string;
  projectId: string | null;
}

export const useProductStore = ({ token, projectId }: UseProductStore): ProductStore => {
  const { logAndShowNotification } = useContext(NotificationContext);
  // products is a Map with contentId as key
  const [products, setProducts] = useState<Map<string, DynamicContentProduct>>(new Map());
  const [variants, setVariants] = useState<DynamicContentVariant[]>([]);
  const [productsLoading, setProductsLoading] = useState<boolean>(false);
  // Instances are the elements that we extract from LMV
  // We need to set instances undefined initially to indicate
  // that this data is not initialized yet. Once it is initialized,
  // it could only be empty array or an array of instances.
  const [instances, setInstances] = useState<Instance[] | undefined>();
  const [filteredProductId, setFilteredProductId] = useState<string | undefined>();

  const fetchProductsData = useCallback(async () => {
    if (projectId && instances && instances.length > 0) {
      try {
        setProductsLoading(true);

        //Find list of unique projectId's and ProductId's
        const uniqueProjects = new Set<string>();
        const uniqueProducts = new Set<string>();
        instances.forEach((currentInstance) => {
          if (!uniqueProjects.has(currentInstance.projectId)) {
            uniqueProjects.add(currentInstance.projectId);
          }
          if (!uniqueProducts.has(currentInstance.contentId)) {
            uniqueProducts.add(currentInstance.contentId);
          }
        });

        // Fetch all products from projects
        const productsResponse = await batchFetchProducts(token, [...uniqueProjects]);

        //If there are no products, return
        if (!productsResponse || productsResponse.results.length === 0) {
          logAndShowNotification({
            message: productStoreText.noProductsAvailable,
          });
          return;
        }

        const { results: productsList, errors: productErrors } = productsResponse;

        // Check if errors were produced while querying
        if (productErrors.length > 0) {
          logAndShowNotification({
            severity: "warning",
            message: productStoreText.failedToFetchProducts,
          });
        }

        // Filter products to get the one's relevant to the model
        const filteredProducts = productsList.filter((product) => uniqueProducts.has(product.contentId));
        const filteredProductsMap = new Map(filteredProducts?.map((product) => [product.contentId, product]));

        // Set new products
        setProducts(filteredProductsMap);
        const [firstProductId] = filteredProductsMap.keys();
        setFilteredProductId(firstProductId);

        //Get all Variants
        const variantsQueryParameters = filteredProducts.map((product) => ({
          productId: product.contentId,
          projectId: product.tenancyId,
        }));

        //Fetch all variants from products
        const variantsResponse = await batchFetchVariants(token, variantsQueryParameters);

        //If there are no variants available, show a warning
        if (!variantsResponse || variantsResponse.results.length === 0) {
          logAndShowNotification({
            severity: "warning",
            message: productStoreText.failedToFetchVariants,
          });
          return;
        }

        const { results: variantsList, errors: variantsErrors } = variantsResponse;

        // Check if errors were produced while querying
        if (variantsErrors.length > 0) {
          logAndShowNotification({
            severity: "warning",
            message: productStoreText.failedToFetchVariants,
          });
        }

        setVariants(variantsList);
      } catch (err) {
        resetProductStoreState();
        logAndShowNotification({
          message: productStoreText.failedToFetchAssociateProducts,
        });
      } finally {
        setProductsLoading(false);
      }
    }
  }, [projectId, instances, token, logAndShowNotification]);

  useEffect(() => {
    fetchProductsData();
  }, [fetchProductsData]);

  const resetProductStoreState = () => {
    setProducts(new Map());
    setVariants([]);
    setInstances(undefined);
    setFilteredProductId(undefined);
    setProductsLoading(false);
  };

  return {
    products,
    variants,
    instances,
    filteredProductId,
    productsLoading,
    setFilteredProductId,
    setInstances,
    resetProductStoreState,
  };
};
