import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import { adskColors } from "../InsightsPage.styles";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
      display: false,
    },
    title: {
      display: true,
      text: "Instances per Month",
    },
  },
};

const labels = [
  "Oct/21",
  "Nov/21",
  "Dec/21",
  "Jan/22",
  "Feb/22",
  "Mar/22",
  "Apr/22",
  "May/22",
  "Jun/22",
  "Jul/22",
  "Aug/22",
  "Sep/22",
];

const dataValues: number[] = [150, 167, 181, 160, 145, 230, 184, 226, 234, 275, 337, 423];

export const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: dataValues,
      backgroundColor: adskColors,
    },
  ],
};

export const VerticalBarChart = (): JSX.Element => <Bar options={options} data={data} />;
