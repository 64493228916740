import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";

import { MouseEventHandler } from "react";
import {
  ConfirmationDialogBody,
  ConfirmationDialogContainer,
  ConfirmationDialogFooter,
  ConfirmationDialogTitle,
} from "./ConfirmationDialog.styles";
import text from "../../../global/text.json";

const dialogText = text.confirmDialog;

export interface ConfirmationDialogProps {
  open: boolean;
  message: string;
  title?: string;
  onCancel: MouseEventHandler<HTMLButtonElement>;
  onConfirm: MouseEventHandler<HTMLButtonElement>;
}

export const ConfirmationDialog = ({ open, message, title, onCancel, onConfirm }: ConfirmationDialogProps): JSX.Element => (
  <Modal keepMounted open={open} onClose={onCancel}>
    <ConfirmationDialogContainer>
      <ConfirmationDialogTitle>
        <Typography variant="h6" component="h2">
          {title || dialogText.confirmation}
        </Typography>
      </ConfirmationDialogTitle>
      <Divider />
      <ConfirmationDialogBody>
        <Typography variant="body1" component="p">
          {message}
        </Typography>
      </ConfirmationDialogBody>
      <ConfirmationDialogFooter>
        <Button variant="outlined" onClick={onCancel}>
          {dialogText.cancel}
        </Button>
        <Button variant="contained" onClick={onConfirm}>
          {dialogText.confirm}
        </Button>
      </ConfirmationDialogFooter>
    </ConfirmationDialogContainer>
  </Modal>
);
