import { Tabs } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

export const ProductsPageContainer = styled(Box)(() => ({
  flexGrow: 10,
  overflow: "hidden",
  display: "flex",
  backgroundColor: "#ccc",
}));

export const ProductsPageTabBar = styled(Box)(() => ({
  minWidth: "100px",
  maxWidth: "100px",
  flexGrow: 0,
  height: "100%",
  overflow: "hidden",
  display: "flex",
  flexDirection: "row",
  alignItems: "stretch",
}));

export const ProductsTabs = styled(Tabs)(() => ({
  backgroundColor: "#eee",
  minWidth: "100px",
  maxWidth: "100px",
}));
