import { OffsiteLogger, createOffsiteLogger, OffsiteLogExtradata } from "@adsk/offsite-logger-client";
import { ErrorCode, MIDWError } from "./errors";

const logger: OffsiteLogger = createOffsiteLogger({
  service: "mid-webapp",
  moniker: process.env.MONIKER || "",
});

export interface MIDWLogExtraData extends OffsiteLogExtradata {
  code?: ErrorCode;
}

export default {
  extractErrorMessage: (error: unknown): string => {
    if (typeof error === "string") {
      return error;
    }

    if (error instanceof Error) {
      return error.message;
    }

    return "Unknown error";
  },

  error: (message: unknown, extraData?: MIDWLogExtraData): void => {
    let messageToLog = message as string;

    if (message instanceof MIDWError) {
      messageToLog = message.message;

      if (extraData) {
        extraData.code = extraData.code || message.code;
      } else {
        extraData = {
          code: message.code,
        };
      }
    } else if (message instanceof Error) {
      messageToLog = message.message;
    }

    logger.error(messageToLog, extraData);
  },
  warn: (message: string, extraData?: MIDWLogExtraData): void => {
    logger.warn(message, extraData);
  },

  info: (message: string, extraData?: MIDWLogExtraData): void => {
    logger.info(message, extraData);
  },

  debug: (message: string, extraData?: MIDWLogExtraData): void => {
    logger.debug(message, extraData);
  },
};
