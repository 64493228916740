import ClickAwayListener from "@mui/base/ClickAwayListener";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import InsertDriveFile from "@mui/icons-material/InsertDriveFile";
import { Divider, IconButton, List, ListItem } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import React, { useState } from "react";
import text from "../../global/text.json";
import { extractVersionNumber } from "../../global/utils";
import { addinInstallersConfig } from "../../services/config";
import { IconWrapper, StyledHelpOutlineIcon, StyledPaper, StyledPopper, VersionInfoWrapper } from "./VersionInfo.styles";

interface VersionInfoProps {
  MIDW_VERSION?: string;
}

export const VersionInfo: React.FC<VersionInfoProps> = ({ MIDW_VERSION }): JSX.Element => {
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null);

  const handleVersionInfoClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(anchorElement ? null : event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorElement(null);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <VersionInfoWrapper>
        <IconButton onClick={handleVersionInfoClick}>
          <StyledHelpOutlineIcon />
        </IconButton>
        {anchorElement ? (
          <StyledPopper open={Boolean(anchorElement)} anchorEl={anchorElement} placement="bottom">
            <StyledPaper>
              <List>
                <ListItem>
                  <ListItemText
                    primary={`${text.versionInfoPanel.midWebapp} ${
                      MIDW_VERSION ? extractVersionNumber(MIDW_VERSION) : null
                    }`}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary={<strong>{text.versionInfoPanel.downloadAddins}</strong>} />
                </ListItem>
                <ListItem
                  secondaryAction={
                    <a href={addinInstallersConfig.inventorAddinMSI}>
                      <IconButton edge="end">
                        <FileDownloadIcon />
                      </IconButton>
                    </a>
                  }
                >
                  <IconWrapper>
                    <InsertDriveFile />
                  </IconWrapper>
                  <ListItemText
                    primary={`${text.versionInfoPanel.midForInventor} ${extractVersionNumber(
                      addinInstallersConfig.inventorAddinMSI
                    )}`}
                  />
                </ListItem>
                <ListItem
                  secondaryAction={
                    <a href={addinInstallersConfig.revitAddinMSI}>
                      <IconButton edge="end">
                        <FileDownloadIcon />
                      </IconButton>
                    </a>
                  }
                >
                  <IconWrapper>
                    <InsertDriveFile />
                  </IconWrapper>
                  <ListItemText
                    primary={`${text.versionInfoPanel.midForRevit} ${extractVersionNumber(
                      addinInstallersConfig.revitAddinMSI
                    )}`}
                  />
                </ListItem>
              </List>
            </StyledPaper>
          </StyledPopper>
        ) : null}
      </VersionInfoWrapper>
    </ClickAwayListener>
  );
};
