import {
  ClientSideOAuth2Component,
  IClientSideOAuth2Configuration,
} from '../components/clientSideOAuth2.component';

/**
 * set up authentication, client-side
 */
export const clientSideOAuth2Providers =
  (authenticationConfig: IClientSideOAuth2Configuration) => {
    return [
      { type: 'ClientSideAuthenticationConfiguration', useValue: authenticationConfig },
      { type: 'AuthenticationComponent', useClass: ClientSideOAuth2Component },
    ];
  };
