import {
  BearerTokenFunctionType,
  ClientTokenCallbackType,
  IAuthentication,
} from './authentication';

/**
 * In some cases, an application is embedded in another app (e.g. Dev portal, webview in desktop/mobile app, Iframe)
 * wants to use functionality that requires authentication. However, going through the authentication flow is not
 * necessary since the access token can be fetched from the external context.
 *
 * This component implements the `IAuthentication` interface. An instance of it should be created by passing an access
 * token as the parameter. The accepted parameter can be a string or a function that returns a string or a promise that
 * resolves a string.
 *
 * @example
 * ```
 * const accessToken = 'foo';
 * ```
 * or
 * ```
 * const accessToken = () => 'foo';
 * ```
 * or
 * ```
 * const accessToken = Promise.resolve('foo');
 * ```
 * then
 * ```
 * const auth = new EmbeddableAuthenticationComponent(accessToken);
 * auth.bearerTokenFunction(callback);
 * ```
 */
export class EmbeddableAuthenticationComponent implements IAuthentication {
  private _accessToken: string | (() => string) | Promise<string>;

  /**
   * @param param A data object that provides the access token.
   */
  constructor(param: string | (() => string) | Promise<string>) {
    this._accessToken = param;
  }

  /**
   * Returns a function that handles authentication and provides the authentication token as a string parameter to
   * the provided callback. In case of errors, the error will be passed to the callback instead.
   * @public
   */
  public get bearerTokenFunction(): BearerTokenFunctionType {
    return (callback: ClientTokenCallbackType) => {
      if (typeof this._accessToken === 'string') {
        callback(undefined, this._accessToken);
      } else if (typeof this._accessToken === 'function') {
        try {
          callback(undefined, this._accessToken());
        } catch (error) {
          callback(error, undefined);
        }
      } else {
        this._accessToken.then((token) => {
          callback(undefined, token);
        }).catch((error) => {
          callback(error, undefined);
        });
      }
    };
  }
}
