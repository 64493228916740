import logger from "../global/logger";
import { ErrorCode, MIDWError } from "../global/errors";
import { DynamicContentVariant, DynamicContentVariantOutput } from "../types/dynamicContent";
import { PostVariantPayload } from "../types/variants";
import { ApiServiceFactory } from "./api.service";
import { ServiceTypes } from "./config";

import text from "../global/text.json";

const variantsServiceText = text.variantsService;
const apiServiceText = text.apiService;

export const postVariantToAPI = async (
  token: string,
  projectId: string,
  productId: string,
  variantPayload: PostVariantPayload
): Promise<DynamicContentVariant> => {
  if (!token) {
    throw new MIDWError(apiServiceText.unauthorizedAccessError, ErrorCode.UnauthorizedAccessError);
  }
  const apiService = ApiServiceFactory.createApiService(ServiceTypes.offsiteAPI, { token });

  const path = `projects/${projectId}/products/${productId}/variants`;
  const { response } = apiService.abortablePost(path, variantPayload);

  try {
    return (await response).data;
  } catch (e) {
    logger.error(e);
    throw new MIDWError(variantsServiceText.variantPostError, ErrorCode.VariantPostError);
  }
};

export const getVariantFromAPI = async (
  token: string,
  projectId: string,
  productId: string,
  variantId: string
): Promise<DynamicContentVariant> => {
  if (!token) {
    throw new MIDWError(apiServiceText.unauthorizedAccessError, ErrorCode.UnauthorizedAccessError);
  }
  const apiService = ApiServiceFactory.createApiService(ServiceTypes.offsiteAPI, { token });

  const path = `projects/${projectId}/products/${productId}/variants/${variantId}`;
  const { response } = apiService.abortableGet(path);

  try {
    return (await response).data;
  } catch (e) {
    logger.error(e);
    throw new MIDWError(variantsServiceText.variantLoadError, ErrorCode.VariantLoadError);
  }
};

export const getVariantOutputs = async (
  token: string,
  projectId: string,
  productId: string,
  variantId: string
): Promise<DynamicContentVariantOutput[]> => {
  if (!token) {
    throw new MIDWError(apiServiceText.unauthorizedAccessError, ErrorCode.UnauthorizedAccessError);
  }
  const apiService = ApiServiceFactory.createApiService(ServiceTypes.offsiteAPI, { token });

  const path = `projects/${projectId}/products/${productId}/variants/${variantId}/outputs`;
  const { response } = apiService.abortableGet(path);

  try {
    return (await response).data;
  } catch (e) {
    logger.error(e);
    throw new MIDWError(variantsServiceText.variantOutputsLoadError, ErrorCode.VariantOutputsLoadError);
  }
};
