import { SingleValueMetricContainer } from "./SingleValueMetricPanel.styles";
import Typography from "@mui/material/Typography";

export interface SingleValueMetricPanelProps {
  title: string;
  units?: string;
  value: string | number;
}

export const SingleValueMetricPanel = ({ title, units, value }: SingleValueMetricPanelProps): JSX.Element => (
  <SingleValueMetricContainer>
    <Typography variant="body1">{title}</Typography>
    {units !== undefined && <Typography variant="body1">({units})</Typography>}
    <Typography variant="h3">
      <strong>{value}</strong>
    </Typography>
  </SingleValueMetricContainer>
);
