import { useContext } from "react";
import VerticalTabBar from "../../components/Commons/VerticalTabBar";
import ReviewPanel from "../../components/OutputsPage/ReviewPanel";
import SettingsPanel from "../../components/OutputsPage/SettingsPanel";
import { OutputsSubTabsContext } from "../../context/TabStore/Tab.context";
import { outputsSubTabs, OUTPUTS_SUBTABS } from "../../global/constants/tabs";
import { PageContainer } from "../pages.styles";

export const OutputsPage = (): JSX.Element => {
  const { selectedOutputsSubTab, setSelectedOutputsSubTab } = useContext(OutputsSubTabsContext);
  const handleChangeOutputsSubTab = (_: any, value: string) => {
    setSelectedOutputsSubTab(value);
  };

  return (
    <PageContainer>
      <VerticalTabBar
        value={selectedOutputsSubTab}
        onChange={handleChangeOutputsSubTab}
        tabs={Object.values(outputsSubTabs)}
        ariaLabel={outputsSubTabs[selectedOutputsSubTab as keyof typeof OUTPUTS_SUBTABS].label}
      />
      {selectedOutputsSubTab === OUTPUTS_SUBTABS.REVIEW && <ReviewPanel />}
      {selectedOutputsSubTab === OUTPUTS_SUBTABS.SETTINGS && <SettingsPanel />}
    </PageContainer>
  );
};
