import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { KeyValueTableProps } from "../../../types/KeyValueTable";
import { KeyValueTableContainer, RowLabel, RowValue } from "./KeyValueTable.style";

export const KeyValueTable = ({ data }: KeyValueTableProps): JSX.Element => (
  <KeyValueTableContainer>
    <Table>
      <TableBody>
        {data.map((row) => (
          <TableRow key={randomKey(row.value)}>
            <RowLabel>
              <Typography noWrap variant="body2">
                {row.label}
              </Typography>
            </RowLabel>
            <RowValue>
              <Typography noWrap variant="body2">
                {row.value}
              </Typography>
            </RowValue>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </KeyValueTableContainer>
);

const randomKey = (prefix: string): string => `${prefix}-${Math.random().toString(36).substring(2, 7)}`;
