import { DetailsSidebarHeaderContainer, DetailsSidebarHeaderStart } from "./DetailsSidebarHeader.style";
import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

type SidebarHeaderItem = JSX.Element | boolean | null | undefined | string | number;

export interface DetailsSidebarHeaderProps {
  children?: SidebarHeaderItem[] | SidebarHeaderItem | string;
  onClose?: () => void;
}

export const DetailsSidebarHeader = ({ onClose, children }: DetailsSidebarHeaderProps): JSX.Element => (
  <DetailsSidebarHeaderContainer>
    <DetailsSidebarHeaderStart>{children}</DetailsSidebarHeaderStart>
    <IconButton onClick={onClose}>
      <Close />
    </IconButton>
  </DetailsSidebarHeaderContainer>
);
