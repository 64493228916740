import React from "react";

import { CustomPaginationContainer } from "./CustomPagination.style";

import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import NavigateBefore from "@mui/icons-material/NavigateBefore";
import NavigateNext from "@mui/icons-material/NavigateNext";

import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import text from "../../../global/text.json";

export interface CustomPaginationProps {
  count: number;
  currentIndex: number;
  onChange: (index: number, previousIndex?: number, event?: React.MouseEvent<HTMLElement>) => void;
}
export const CustomPagination = ({ count, currentIndex, onChange }: CustomPaginationProps): JSX.Element => {
  if (currentIndex < 0) {
    onChange(0, currentIndex);
  }

  if (currentIndex > count) {
    onChange(count - 1, currentIndex);
  }

  const handleFirst = (event: React.MouseEvent<HTMLElement>) => {
    if (currentIndex > 0) {
      onChange(0, currentIndex, event);
    }
  };

  const handlePrevious = (event: React.MouseEvent<HTMLElement>) => {
    if (currentIndex > 0) {
      onChange(currentIndex - 1, currentIndex, event);
    }
  };

  const handleNext = (event: React.MouseEvent<HTMLElement>) => {
    if (currentIndex < count - 1) {
      onChange(currentIndex + 1, currentIndex, event);
    }
  };

  const handleLast = (event: React.MouseEvent<HTMLElement>) => {
    if (currentIndex < count) {
      onChange(count - 1, currentIndex, event);
    }
  };
  const paginationText = text.customPagination;

  return (
    <CustomPaginationContainer>
      <IconButton onClick={handleFirst} disabled={currentIndex === 0} data-testid={"pagination-first-page-button"}>
        <FirstPage />
      </IconButton>
      <IconButton onClick={handlePrevious} disabled={currentIndex === 0} data-testid={"pagination-previous-button"}>
        <NavigateBefore />
      </IconButton>
      <Typography variant="body2">
        {currentIndex + 1} {paginationText.of} {count}
      </Typography>
      <IconButton onClick={handleNext} disabled={currentIndex === count - 1} data-testid={"pagination-next-button"}>
        <NavigateNext />
      </IconButton>
      <IconButton onClick={handleLast} disabled={currentIndex === count - 1} data-testid={"pagination-last-page-button"}>
        <LastPage />
      </IconButton>
    </CustomPaginationContainer>
  );
};
