export * from '../shared';

import {
  ClientSideOAuth2Component,
  IClientSideOAuth2Configuration,
 } from './components/clientSideOAuth2.component';

import { UserInfoComponent } from './components/UserInfo.component';

import { clientSideOAuth2Providers } from './helpers/provider_helpers';

export {
  clientSideOAuth2Providers,
  ClientSideOAuth2Component,
  IClientSideOAuth2Configuration,
  UserInfoComponent,
};

// TODO: Deprecated exports for backward compatibility
/**
 * @deprecated Should be imported as [[IClientSideOAuth2Configuration]].
 */
interface IClientSideAuthenticationConfiguration extends IClientSideOAuth2Configuration {} // tslint:disable-line

/**
 * @deprecated Should be imported as [[ClientSideOAuth2Component]].
 */
class ClientSideAuthenticationComponent extends ClientSideOAuth2Component {}

/**
 * @deprecated Should be imported as [[clientSideOAuth2Providers]].
 */
const clientSideAuthenticationProviders = clientSideOAuth2Providers;
export {
  IClientSideAuthenticationConfiguration,
  ClientSideAuthenticationComponent,
  clientSideAuthenticationProviders,
};
