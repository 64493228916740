import React, { useState } from "react";
import { usePrevious } from "../../global/hooks/hooks";

export interface AccountProjectsStore {
  accountId: string | null;
  setAccountId: React.Dispatch<React.SetStateAction<string | null>>;
  accountName: string | null;
  setAccountName: React.Dispatch<React.SetStateAction<string | null>>;
  accountImg: string | null;
  setAccountImg: React.Dispatch<React.SetStateAction<string | null>>;
  projectId: string | null;
  setProjectId: React.Dispatch<React.SetStateAction<string | null>>;
  previousProjectId: string | undefined;
  projectName: string | null;
  setProjectName: React.Dispatch<React.SetStateAction<string | null>>;
  hasProjectChanged: boolean;
}

export const useAccountProjectsStore = (): AccountProjectsStore => {
  const [accountId, setAccountId] = useState<string | null>(null);
  const [accountName, setAccountName] = useState<string | null>(null);
  const [accountImg, setAccountImg] = useState<string | null>(null);
  const [projectId, setProjectId] = useState<string | null>(null);
  const [projectName, setProjectName] = useState<string | null>(null);
  const previousProjectId = usePrevious(projectId);
  const hasProjectChanged = !!previousProjectId && previousProjectId !== projectId;

  return {
    accountId,
    setAccountId,
    accountName,
    setAccountName,
    accountImg,
    setAccountImg,
    projectId,
    previousProjectId,
    setProjectId,
    projectName,
    setProjectName,
    hasProjectChanged,
  };
};
