import { TableCell, TableContainer } from "@mui/material";
import { styled } from "@mui/material/styles";

export const KeyValueTableContainer = styled(TableContainer)(() => ({
  margin: "0px 12px",
}));

export const RowLabel = styled(TableCell)(() => ({
  padding: "5px 6px",
  backgroundColor: "#eee",
  width: "50%",
}));

export const RowValue = styled(TableCell)(() => ({
  padding: "5px 6px",
  backgroundColor: "#fff",
  width: "50%",
}));
