import { IAuthentication } from './components/authentication';
import { EmbeddableAuthenticationComponent } from './components/embeddableAuthentication.component';
import { objectToQueryString } from './utils/urlUtils';

/**
 * @hidden
 */
const Utils = {
  objectToQueryString,
};

export {
  EmbeddableAuthenticationComponent,
  IAuthentication,
  Utils,
};
