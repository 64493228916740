(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("underscore"), require("@adsk/forge-appfw-component-helpers"), require("axios"));
	else if(typeof define === 'function' && define.amd)
		define(["underscore", "@adsk/forge-appfw-component-helpers", "axios"], factory);
	else if(typeof exports === 'object')
		exports["@adsk/forge-appfw-authentication"] = factory(require("underscore"), require("@adsk/forge-appfw-component-helpers"), require("axios"));
	else
		root["@adsk/forge-appfw-authentication"] = factory(root["underscore"], root["@adsk/forge-appfw-component-helpers"], root["axios"]);
})(typeof self !== 'undefined' ? self : this, function(__WEBPACK_EXTERNAL_MODULE__5__, __WEBPACK_EXTERNAL_MODULE__7__, __WEBPACK_EXTERNAL_MODULE__18__) {
return 