"use strict";

import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import logger from "../global/logger";
import { ErrorCode, MIDWError } from "../global/errors";
import { Environment, ServiceConfigMap, ServiceTypes } from "./config";

import text from "../global/text.json";

const apiServiceText = text.apiService;

export interface GetRequestOptions {
  fetchBlob?: boolean;
}

export type ApiServiceResponse<T = any> = Promise<AxiosResponse<T>>;
export type ApiServiceAbortableResponse<T = any> = {
  abort: () => void;
  response: ApiServiceResponse<T>;
};
export type ApiServiceAbortableResponseData<T = any> = {
  abort: () => void;
  data: Promise<T>;
};

export class ApiService {
  private axios: AxiosInstance;

  constructor(baseURL: string, token = "") {
    const axiosConfig: AxiosRequestConfig = {
      baseURL,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    // create new axios instance
    this.axios = axios.create(axiosConfig);
    // setup interceptors for axios instance
    this.axios.interceptors.response.use((response) => response, handleAxiosResponseError);
  }

  public getToken(): string | undefined {
    return this.axios.defaults.headers.Authorization;
  }

  public setToken(token?: string): void {
    if (token) {
      // set header for axios instance
      this.axios.defaults.headers.Authorization = `Bearer ${token}`;
    } else {
      // remove header from axios instance
      delete this.axios.defaults.headers.Authorization;
    }
  }

  public get(path: string, config?: GetRequestOptions): ApiServiceResponse {
    // create request-scoped config
    const requestConfig: AxiosRequestConfig = config?.fetchBlob ? { responseType: "blob" } : {};
    // call get method request-scoped config
    return this.axios.get(path, requestConfig);
  }

  public post(path: string, data?: any): ApiServiceResponse {
    return this.axios.post(path, data);
  }

  public put(path: string, data: any): ApiServiceResponse {
    return this.axios.put(path, data);
  }

  public patch(path: string, data: any): ApiServiceResponse {
    return this.axios.patch(path, data);
  }

  public delete(path: string, data?: any): ApiServiceResponse {
    return this.axios.delete(path, { data });
  }

  public abortableGet = (path: string, config?: GetRequestOptions): ApiServiceAbortableResponse => {
    const source = axios.CancelToken.source();
    const abort = () => source.cancel();
    const requestConfig: AxiosRequestConfig = config?.fetchBlob ? { responseType: "blob" } : {};

    return {
      abort,
      response: this.axios.get(path, {
        ...requestConfig,
        cancelToken: source.token,
      }),
    };
  };

  public abortablePost = (path: string, data?: any): ApiServiceAbortableResponse => {
    const source = axios.CancelToken.source();
    const abort = () => source.cancel();

    return {
      abort,
      response: this.axios.post(path, data, { cancelToken: source.token }),
    };
  };

  public abortablePatch = (path: string, data?: any): ApiServiceAbortableResponse => {
    const source = axios.CancelToken.source();
    const abort = () => source.cancel();

    return {
      abort,
      response: this.axios.patch(path, data, { cancelToken: source.token }),
    };
  };

  public abortablePut = (path: string, data?: any): ApiServiceAbortableResponse => {
    const source = axios.CancelToken.source();
    const abort = () => source.cancel();

    return {
      abort,
      response: this.axios.put(path, data, { cancelToken: source.token }),
    };
  };

  public abortableDelete = (path: string, data?: any): ApiServiceAbortableResponse => {
    const source = axios.CancelToken.source();
    const abort = () => source.cancel();

    return {
      abort,
      response: this.axios.delete(path, { data, cancelToken: source.token }),
    };
  };

  public static isAbortError = (error: Error): boolean => axios.isCancel(error);
}

export class ApiServiceFactory {
  public static createApiService(serviceType: ServiceTypes, options: { token: string }): ApiService {
    const service = ServiceConfigMap[serviceType][(process.env.REACT_APP_ENVIRONMENT as Environment) || Environment.DEV];
    if (!service || !service.api) {
      throw new MIDWError(apiServiceText.invalidAPIURLError, ErrorCode.InvalidAPIURLError);
    }
    const { token } = options;
    return new ApiService(service.api, token);
  }
}

const handleAxiosResponseError = (err: AxiosError) => {
  if (!ApiService.isAbortError(err)) {
    logger.error(`${apiServiceText.axiousResponseError}: `, {
      url: window.location.href,
      endpoint: `${err.config.baseURL}/${err.config.url}`,
      error: err.toJSON(),
    });
    if (err.response?.status) {
      logger.error(apiServiceText.axiousResponseError);
      throw new MIDWError(apiServiceText.unexpectedError, ErrorCode.UnexpectedError);
    } else {
      // Possible Network Error
      logger.error(apiServiceText.serviceUnavailableError);
      throw new MIDWError(apiServiceText.serviceUnavailableError, ErrorCode.ServiceUnavailableError);
    }
  }
  return Promise.reject(err);
};
