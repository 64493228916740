import React from "react";
import { GridColDef, GridFilterModel } from "@mui/x-data-grid";
import { LinearProgress } from "@mui/material";
import { Instance } from "../../../types/product";
import { InstancesDataGrid } from "./ProductsInstancesDataGrid.style";
import { dataGridDefaultSettings } from "../../../global/constants/products";

interface ProductsInstancesDataGridProps {
  midModelInstances: Instance[];
  selectedInstancesIds: string[] | undefined;
  columns: GridColDef[];
  tableLoading: boolean;
  instancesGridFilters: GridFilterModel;
  handleInstancesSelection: (a: any) => void;
}

const ProductsInstancesDataGrid: React.FC<ProductsInstancesDataGridProps> = ({
  midModelInstances,
  selectedInstancesIds,
  columns,
  tableLoading,
  instancesGridFilters,
  handleInstancesSelection,
}): JSX.Element => (
  <InstancesDataGrid
    components={{
      LoadingOverlay: LinearProgress,
    }}
    rows={midModelInstances}
    columns={columns}
    rowsPerPageOptions={dataGridDefaultSettings.ROWS_PER_PAGE}
    checkboxSelection
    disableSelectionOnClick
    rowHeight={dataGridDefaultSettings.ROW_HEIGHT}
    headerHeight={dataGridDefaultSettings.HEADER_HEIGHT}
    selectionModel={selectedInstancesIds}
    onSelectionModelChange={handleInstancesSelection}
    loading={tableLoading}
    filterModel={instancesGridFilters}
  />
);

export default ProductsInstancesDataGrid;
