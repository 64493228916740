import { styled } from "@mui/material/styles";
import MenuList from "@mui/material/MenuList";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";

type ProjectListContainerProps = {
  visible: boolean;
};

export const ProjectListContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "visible",
})<ProjectListContainerProps>(({ visible }) => ({
  maxWidth: visible ? "400px" : "0px",
  maxHeight: !visible ? "0px" : "60vh",
  display: "flex",
  flexDirection: "column",
  transition: "all .3s ease",
  overflow: "hidden",
}));

export const ProjectsListTitle = styled(Box)(() => ({
  margin: "8px 12px 0px 12px",
}));

export const ProjectsMenuList = styled(MenuList)(() => ({
  maxHeight: "600px",
  flexGrow: 10,
  overflow: "auto",
}));

export const ProjectsMenuListItem = styled(MenuItem)(() => ({
  padding: "10px 10px 10px 12px",
}));
