import text from "../../global/text.json";
import AdskIcon from "../../images/autodesk-collections-icon-dark-slate.png";

export const MIDTabsOrientation: "horizontal" | "vertical" = "vertical";
export const tabsIconWidth = "80px";

export interface MIDTab {
  value: string;
  label: string;
  disabled?: boolean;
}

export interface MIDSubTab extends MIDTab {
  icon: string;
}

export enum MAIN_TABS {
  PRODUCTS = "PRODUCTS",
  OUTPUTS = "OUTPUTS",
  INSIGHTS = "INSIGHTS",
}

export const mainTabs: { [key in MAIN_TABS]: MIDTab } = {
  [MAIN_TABS.PRODUCTS]: {
    value: MAIN_TABS.PRODUCTS,
    label: text.mainTabs.products,
  },
  [MAIN_TABS.OUTPUTS]: {
    value: MAIN_TABS.OUTPUTS,
    label: text.mainTabs.outputs,
  },
  [MAIN_TABS.INSIGHTS]: {
    value: MAIN_TABS.INSIGHTS,
    label: text.mainTabs.insights,
  },
};

export enum PRODUCTS_SUBTABS {
  MODELS = "MODELS",
  INSTANCES = "INSTANCES",
}

export const productsSubTabs: { [key in PRODUCTS_SUBTABS]: MIDSubTab } = {
  [PRODUCTS_SUBTABS.MODELS]: {
    value: PRODUCTS_SUBTABS.MODELS,
    label: text.productsSubTabs.models,
    icon: AdskIcon,
  },
  [PRODUCTS_SUBTABS.INSTANCES]: {
    value: PRODUCTS_SUBTABS.INSTANCES,
    label: text.productsSubTabs.instances,
    icon: AdskIcon,
  },
};

export enum OUTPUTS_SUBTABS {
  REVIEW = "REVIEW",
  SETTINGS = "SETTINGS",
}

export const outputsSubTabs: { [key in OUTPUTS_SUBTABS]: MIDSubTab } = {
  [OUTPUTS_SUBTABS.REVIEW]: {
    value: OUTPUTS_SUBTABS.REVIEW,
    label: text.outputsSubTabs.review,
    icon: AdskIcon,
  },
  [OUTPUTS_SUBTABS.SETTINGS]: {
    value: OUTPUTS_SUBTABS.SETTINGS,
    label: text.outputsSubTabs.settings,
    icon: AdskIcon,
  },
};

export const instancesVisibilityFilter = {
  ALL: "all",
  SELECTED: "selected",
  NOT_SELECTED: "notSelected",
};
