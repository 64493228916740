import { useState, useCallback } from "react";
import authService from "../../services/auth";
import { bim360Config } from "../../services/config";
import { ExtendedUserInfo } from "../../types/user";
import text from "../../global/text.json";
import logger from "../../global/logger";
import { ErrorCode, MIDWError } from "../../global/errors";

export interface UserStore {
  token: string;
  userInfoLoading: boolean;
  userInfo?: ExtendedUserInfo;
  setProfile: () => Promise<void>;
  handleLogout: () => void;
  handleProfileSettingClick: () => void;
  initialize: () => Promise<void>;
}

export const useUserStore = (): UserStore => {
  const [token, setToken] = useState("");
  const [userInfoLoading, setUserInfoLoading] = useState(false);
  const [userInfo, setUserInfo] = useState<ExtendedUserInfo | undefined>(undefined);
  const userStoreText = text.userStore;

  const authenticate = async (): Promise<void> => {
    try {
      const token = await authService.authenticate();
      setToken(token);
    } catch (e) {
      logger.error(e);
      throw new Error(userStoreText.failedToAuthenticate);
    }
  };

  const initialize = async (): Promise<void> => {
    try {
      await authenticate();
    } catch (e) {
      logger.error(e);

      throw new Error(userStoreText.failedToInitialize);
    }
  };

  const setProfile = useCallback(async (): Promise<void> => {
    try {
      setUserInfoLoading(true);
      const res = await authService.getUserInfo();
      setUserInfo({
        ...res,
        name: `${res.firstName} ${res.lastName}`,
        profileImg50: res.profileImages.sizeX50,
      });
      setUserInfoLoading(false);
    } catch (e) {
      logger.error(e, {
        code: ErrorCode.UserInfoLoadError,
      });

      throw new MIDWError(userStoreText.failedToSetProfile, ErrorCode.SetProfileError);
    }
  }, [userStoreText.failedToSetProfile]);

  const handleLogout = (): void => {
    authService.logout();
  };

  const handleProfileSettingClick = (): void => {
    window.location.href = bim360Config.profileSettingPath;
  };

  return {
    token,
    userInfoLoading,
    userInfo,
    setProfile,
    handleLogout,
    handleProfileSettingClick,
    initialize,
  };
};
