import { dependencyHelper,
         IAppComponent,
         IAppComponentDependency,
         IHasRequiredStaticAppComponentMembers } from '@adsk/forge-appfw-component-helpers';
import { IForgeConfiguration } from '@adsk/forge-appfw-forge-configuration';
import { IAuthentication } from '../../shared/components/authentication';
import { requestHTTPPromise } from '../../shared/utils/utils';

/**
 * The dependencies of a [[UserInfoComponent]]
 */
export interface IUserInfoComponentDependencies {
  /**
   * An authentication component that provides access to a
   * bearer token.
   */
  AuthenticationComponent: IAuthentication;

  /**
   * A configuration dependency to specify the base url for authentication.
   */
  ForgeConfiguration: IForgeConfiguration;
}

/**
 * Fetches the information of the currently logged user
 */
export class UserInfoComponent implements IAppComponent<UserInfoComponent> {
  public static defineDependencies(): IAppComponentDependency[] {
    return [
      {type: 'AuthenticationComponent'},
      {type: 'ForgeConfiguration'},
    ];
  }

  private _params: IUserInfoComponentDependencies;
  private _initPromise: Promise<UserInfoComponent>;
  private config: IForgeConfiguration = {environmentBaseUrl: ''};
  private authenticationComponent?: IAuthentication;

  constructor(authentication: IAuthentication, forgeConfig: IForgeConfiguration) {
    this._params = { AuthenticationComponent: authentication, ForgeConfiguration: forgeConfig };
    this._initPromise = this.initializeComponent();
    this.getUserInfo = this.getUserInfo.bind(this);
    this.logout = this.logout.bind(this);
    this.getProfileSettingsUrl = this.getProfileSettingsUrl.bind(this);
  }

  /**
   * Returns the currently logged in user information
   * @return Promise with the user info object returned from OAuth2.
   */
  public getUserInfo() {
    const authenticationBaseURL = this.config.environmentBaseUrl;
    const userInfoUrl = authenticationBaseURL + '/userprofile/v1/users/@me';
    return new Promise((resolve, reject) => {
      this.authenticationComponent!.bearerTokenFunction!((authError, accessToken) => {
        if (authError) {
          reject(authError);
        } else {
          requestHTTPPromise(userInfoUrl, {Authorization: 'Bearer ' + accessToken})
          .then(({data}) => {
            return resolve(data);
          }).catch((error) => {
            return reject(error);
          });
        }
      });
    });
  }

  /**
   * Logs out the user.
   * @return changes window location to the logout page.
   */
  public logout() {
    const returnURL = window.location.origin + window.location.pathname;
    window.location.href = this.config.environmentBaseUrl!.replace(
      '.api', '').replace('developer', 'accounts').replace('-stg.', '-staging.') +
      '/Authentication/LogOut?ReturnToUrl=' + returnURL;
    return window.location;
  }

  /**
   * @return The user profile settings url.
   */
  public getProfileSettingsUrl() {
    const returnURL = window.location.origin + window.location.pathname;
    return this.config.environmentBaseUrl!.replace(
      '.api', '').replace('developer', 'accounts').replace('-stg.', '-staging.') +
      '/users/{$account name$}/view?ReturnToUrl=' + returnURL;
  }

  public initializeComponent(): Promise<UserInfoComponent> {
    if (!this._initPromise) {
      this._initPromise = dependencyHelper(this._params).then((dependencies) => {
        this.authenticationComponent = dependencies.AuthenticationComponent;
        this.config = dependencies.ForgeConfiguration;
        return this;
      });
    }
    return this._initPromise;
  }

  public uninitializeComponent(): Promise<void> {
    return Promise.resolve();
  }
}

// We want to make sure that we implement all required static functions of an AppComponent.
// This check allows us to do so at compile time.
/* tslint:disable-next-line:no-unused-variable */
const staticComponentMemberCheck: IHasRequiredStaticAppComponentMembers = UserInfoComponent;
