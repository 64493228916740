import React, { useContext } from "react";
import { format, parseISO } from "date-fns";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Chip from "@mui/material/Chip";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import { GridColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";

import { useEffect, useState } from "react";
import { HigFile, HigSearch } from "../../Commons/CustomIcon/HigIcons";
import {
  FilenameCell,
  FolderContentDatagrid,
  ModelsFolderContentContainer,
  ModelsFolderContentDataGrid,
  ModelsFolderContentHeader,
  ModelsSearchTextField,
  ModelsFileName,
} from "./ModelsFolderContent.style";
import { FolderContentRow } from "./ModelsFolderContent.types";
import AccountProjectContext from "../../../context/AccountProjectStore/AccountProject.context";
import { BIM360Document } from "types/bim360";
import UserContext from "../../../context/UserStore/User.context";
import { getThumbnail } from "../../../services/bim360";
import { bim360Config } from "../../../services/config";
import { MODELS_FOLDER_FILENAME_EXTENSION, MODELS_FOLDER_CONTENT_TIME_FORMAT } from "../../../global/constants/model";
import { dataGridDefaultSettings } from "../../../global/constants/products";
import text from "../../../global/text.json";
import ModelSelectionContext from "../../../context/ModelSelectionStore/ModelSelection.context";

import NotificationContext from "../../../context/NotificationStore/Notification.context";

const modelsFolderText = text.modelsFolderContent;

const RenderFilenameCell = ({ id, value }: GridRenderCellParams): React.ReactNode => {
  const { currentlyOpenModel } = useContext(ModelSelectionContext);

  return (
    <FilenameCell>
      <HigFile />
      <ModelsFileName>
        <Typography variant="body2" noWrap>
          {value}
        </Typography>
      </ModelsFileName>
      {currentlyOpenModel?.id === id && (
        <Chip color="primary" variant="outlined" label={<strong>{modelsFolderText.opened}</strong>} size="small" />
      )}
    </FilenameCell>
  );
};

const RenderVersionCell = ({ value }: GridRenderCellParams): React.ReactNode => <Chip label={value} size="small" />;

const columns: GridColDef[] = [
  {
    field: "fileName",
    headerName: modelsFolderText.name,
    width: 370,
    renderCell: RenderFilenameCell,
  },
  {
    field: "version",
    headerName: modelsFolderText.version,
    width: 70,
    renderCell: RenderVersionCell,
    align: "center",
  },
  {
    field: "modifiedOn",
    headerName: modelsFolderText.lastUpdated,
    width: 150,
  },
  {
    field: "productsCount",
    headerName: modelsFolderText.products,
    width: 80,
    align: "right",
    type: "number",
  },
  {
    field: "variantsCount",
    headerName: modelsFolderText.variants,
    width: 80,
    align: "right",
    type: "number",
  },
  {
    field: "instancesCount",
    headerName: modelsFolderText.instances,
    width: 80,
    align: "right",
    type: "number",
  },
];

interface ModelsFolderContentProps {
  folderUrn: string;
  lmvModelFiles: BIM360Document[] | null;
  selectedModelFolder: FolderContentRow | null;
  setSelectedModelFolder: React.Dispatch<React.SetStateAction<FolderContentRow | null>>;
  setIsPreviewLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const transformLMVModelFileListInFolderContent = (lmvModelFileList: BIM360Document[]): FolderContentRow[] =>
  lmvModelFileList
    .filter((lmvModelFile) => lmvModelFile.file_name.endsWith(MODELS_FOLDER_FILENAME_EXTENSION))
    .map((lmvModelFile: BIM360Document) => {
      const { id, urn, file_name, latest_version_create_time, latest_version } = lmvModelFile;

      const parsedUpdatedTime = parseISO(latest_version_create_time);
      const formattedUpdatedTime = format(parsedUpdatedTime, MODELS_FOLDER_CONTENT_TIME_FORMAT);
      const lmvModelFileId = btoa(lmvModelFile.current_version.bubble_urn).replace(/\//g, "_");

      return {
        id,
        urn,
        fileName: file_name,
        version: latest_version.toString(),
        modifiedOn: formattedUpdatedTime,
        lmvModelFileId,
      };
    });

export const ModelsFolderContent: React.FC<ModelsFolderContentProps> = ({
  folderUrn,
  lmvModelFiles,
  selectedModelFolder,
  setIsPreviewLoading,
  setSelectedModelFolder,
}) => {
  const { projectId } = useContext(AccountProjectContext);
  const { token } = useContext(UserContext);
  const { currentlyOpenModel, productFoldersContent, setProductFoldersContent, selectedModelId, setSelectedModelId } =
    useContext(ModelSelectionContext);
  const { logAndShowNotification } = useContext(NotificationContext);

  const [keywords, setKeywords] = useState("");

  const handleRowClick = async (rowParams: GridRowParams<FolderContentRow>) => {
    try {
      setIsPreviewLoading(true);
      if (selectedModelFolder?.id === rowParams.id) {
        setSelectedModelFolder(null);
        setSelectedModelId(undefined);
      } else {
        setSelectedModelId(rowParams.id);
        const thumbnail = await getThumbnail(token, rowParams.row.lmvModelFileId);
        const folderContentRow: FolderContentRow = {
          ...rowParams.row,
          thumbnail,
        };
        setSelectedModelFolder(folderContentRow);
      }
    } catch (error) {
      logAndShowNotification({
        message: modelsFolderText.failedToLoadThumbnail,
      });
    } finally {
      setIsPreviewLoading(false);
    }
  };

  const handleOpenFoldersInDocsClick = () => {
    const url = bim360Config.documentsManagerUrl + "/" + projectId + "/folders/" + folderUrn;
    window.open(url, "_blank", "noopener noreferrer");
  };

  // TODO: The search function is not implemented yet.
  const handleKeywordsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeywords(event.target.value);
  };

  useEffect(() => {
    if (!selectedModelId && currentlyOpenModel) {
      setSelectedModelId(currentlyOpenModel?.id);
    }
  }, [currentlyOpenModel, selectedModelId, setSelectedModelId]);

  useEffect(() => {
    if (lmvModelFiles) {
      const transformedFolderContent = transformLMVModelFileListInFolderContent(lmvModelFiles);
      setProductFoldersContent(transformedFolderContent);
    }
  }, [lmvModelFiles, setProductFoldersContent]);

  return (
    <ModelsFolderContentContainer>
      <ModelsFolderContentHeader>
        <ButtonGroup variant="outlined">
          <Button onClick={handleOpenFoldersInDocsClick}>
            <Typography variant="body2" noWrap>
              {modelsFolderText.openInDocs}
            </Typography>
          </Button>
        </ButtonGroup>
        <ModelsSearchTextField
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <HigSearch />
              </InputAdornment>
            ),
          }}
          size="small"
          placeholder={modelsFolderText.searchFiles}
          value={keywords}
          onChange={handleKeywordsChange}
        />
      </ModelsFolderContentHeader>
      <ModelsFolderContentDataGrid>
        <FolderContentDatagrid
          rows={productFoldersContent}
          columns={columns}
          rowsPerPageOptions={dataGridDefaultSettings.ROWS_PER_PAGE}
          rowHeight={dataGridDefaultSettings.ROW_HEIGHT}
          headerHeight={dataGridDefaultSettings.HEADER_HEIGHT}
          onRowClick={handleRowClick}
          selectionModel={selectedModelId}
        />
      </ModelsFolderContentDataGrid>
    </ModelsFolderContentContainer>
  );
};
