import { GridFilterModel } from "@mui/x-data-grid";
import { useState } from "react";
import { instancesVisibilityFilter } from "../../../../global/constants/tabs";

export interface UseInstanceSelectionFilterState {
  visibilityFilter: string;
  instancesGridFilters: GridFilterModel;
  handleInstanceSelectionFilterChange: (_event: React.MouseEvent<HTMLElement>, value: string) => void;
}

const useInstanceSelectionFilter = (): UseInstanceSelectionFilterState => {
  const [visibilityFilter, setVisibilityFilter] = useState(instancesVisibilityFilter.ALL);
  const [instancesGridFilters, setInstancesGridFilters] = useState<GridFilterModel>({
    items: [],
  });

  const handleInstanceSelectionFilterChange = (_event: React.MouseEvent<HTMLElement>, value: string) => {
    setVisibilityFilter(value);
    if (value === instancesVisibilityFilter.ALL) {
      setInstancesGridFilters({ items: [] });
    } else {
      setInstancesGridFilters({
        items: [
          {
            columnField: "__check__",
            operatorValue: "is",
            value: (value === instancesVisibilityFilter.SELECTED).toString(),
          },
        ],
      });
    }
  };

  return {
    visibilityFilter,
    instancesGridFilters,
    handleInstanceSelectionFilterChange,
  };
};

export default useInstanceSelectionFilter;
