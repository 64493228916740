/**
 * @fileoverview Defines the browser plugin which provides an API for
 *               parsing an URL parameters and accessing the root HTML document.
 */
import { addParameters } from '../../shared/utils/urlUtils';

/**
 * Extracts the value of a URL variable.
 * @param name - URL variable name.
 * @param searchStr - The search or hash part of the URL that contains the variable.
 * @return Value of URL variable.
 * @hidden
 */
function _extractUrlValue(name: string, searchStr: string): string | null {
  const variableArray = searchStr.split('&');
  for (let i = 0, length = variableArray.length; i < length; ++i) {
    const pair = variableArray[i].split('=');
    if (decodeURIComponent(pair[0]) === name) {
      return decodeURIComponent(pair[1]);
    }
  }
  return null;
}

/**
 * Gets the value of a URL hash variable.
 * @param name - URL variable name.
 * @return Value of URL variable.
 * @hidden
 */
function getUrlHashValue(name: string): string | null {
  const searchStr = window.top.location.hash.substring(1);
  return _extractUrlValue(name, searchStr);
}

/**
 * Gets the value of a URL search variable.
 * @param name - URL variable name.
 * @return value of URL variable.
 * @hidden
 */
function getUrlSearchValue(name: string): string | null {
  const searchStr = window.top.location.search.substring(1);
  return _extractUrlValue(name, searchStr);
}

/**
 * Removes a parameter from the URL
 * @param name - URL variable name.
 * @param searchStr - String to be processed
 * @return URL parameters without all instances of the given parameter name
 * @hidden
 */
function removeParameter(name: string, searchStr: string): string {
  const initialCharacter = searchStr[0];
  searchStr = searchStr.substring(1);
  const variableArray = searchStr.split('&');
  const resultingArray: string[] = [];
  for (let i = 0, length = variableArray.length; i < length; ++i) {
    const pair = variableArray[i].split('=');
    if (decodeURIComponent(pair[0]) !== name) {
      resultingArray.push(variableArray[i]);
    }
  }
  return initialCharacter + resultingArray.join('&');
}

/**
 * @param newParameters The parameters to append to the current url path. Current parameters or hash values will be
 *  removed.
 * @hidden
 */
function _replaceURLPath(newParameters: string): void {
  const newUrl = window.location.origin + window.location.pathname +
    ((newParameters.length === 1) ? '' : newParameters);
  window.top.history.replaceState(null, 'Application', newUrl);
}

/**
 * Remove parameter from url. For example, http://localhost:8080/Test.html?undesiredParam=value&param=value2
 * will be replaced by http://localhost:8080/Test.html?param=value2
 * @param name - parameter name
 * @hidden
 */
function removeUrlSearchValue(name: string) {
  const newParameters = removeParameter(name,
    window.top.location.search);
  _replaceURLPath((newParameters === '?' ? '' : newParameters) + window.top.location.hash);
}

/**
 * Remove parameter from url. For example, http://localhost:8080/Test.html#undesiredParam=value&param=value2
 * will be replaced by http://localhost:8080/Test.html#param=value2
 * @param name - parameter name
 * @hidden
 */
function removeUrlHashValue(name: string) {
  const newParameters = removeParameter(name,
    window.top.location.hash);
  _replaceURLPath(window.top.location.search + (newParameters === '#' ? '' : newParameters));
}

/**
 * Update the url with the given branch guid
 * @param inBranchGuid The branch guid to put in the URL
 * @param inParameterName The name of the parameter
 * @param inReplace If true, replace the value of the URL parameter in case it is present
 * @protected
 * @hidden
 */
function updateURL(inBranchGuid: string, inParameterName: string, inReplace: boolean) {
  inParameterName = inParameterName || 'branchGuid';
  let url;
  let currentURL = window.top.location.href;
  if (inReplace) {
    const parameters = removeParameter(inParameterName,
      window.top.location.search);
    currentURL =
    window.top.location.origin +
    window.top.location.pathname +
    (parameters.length ? '?' + parameters : '');
  }
  if (inBranchGuid && currentURL.indexOf(inBranchGuid) === -1) {
    const parameters = {};
    parameters[inParameterName] = inBranchGuid;
    url = addParameters(
      currentURL,
      parameters,
    );
  } else {
    url = addParameters(
      currentURL,
    );
  }
  window.top.history.replaceState(null, 'Application', url);
}

export {getUrlHashValue, getUrlSearchValue, removeUrlSearchValue, removeUrlHashValue, removeParameter, updateURL};
