import { IAppComponent,
         IAppComponentClassType,
         IHasInitialize,
         IHasStaticDefineDependencies,
         IHasUninitialize,
         ILazyLoadPlaceHolder } from './interfaces';

/**
 * Checks whether an object is a placeholder for lazy loaded dependencies (i.e. whether it implements the
 * `ILazyLoadPlaceHolder` interface).
 * @param p The object instance to test.
 * @hidden
 */
export function isLazyLoadPlaceHolder(p: any): p is ILazyLoadPlaceHolder<any> {
  const d = p as ILazyLoadPlaceHolder<any>;
  return d && d.lazyLoad && typeof d.lazyLoad === 'function';
}

/**
 * Checks whether an object has an `initializeComponent` method.
 * @param p The object instance to test.
 * @hidden
 */
export function requiresInitialization(p: any): p is IHasInitialize<any> {
  const instance = p as IHasInitialize<any>;
  return instance && instance.initializeComponent && typeof instance.initializeComponent === 'function';
}

/**
 * Checks whether an object has an `uninitializeComponent` method.
 * @param p The object instance to test.
 * @hidden
 */
export function requiresUninitialization(p: any): p is IHasUninitialize {
  const instance = p as IHasUninitialize;
  return instance && instance.uninitializeComponent && typeof instance.uninitializeComponent === 'function';
}

/**
 * Checks whether a class has a `defineDependencies` method.
 * @param p The class to test.
 * @hidden
 */
export function hasDependencies(p: any): p is IHasStaticDefineDependencies {
  const classType = p as IHasStaticDefineDependencies;
  return classType && classType.defineDependencies && typeof classType.defineDependencies === 'function';
}

/**
 * Checks whether an object is an AppComponent (i.e. whether it implements the `IAppComponent` interface).
 * @param p The object instance to test.
 * @deprecated
 * @hidden
 */
export function isAppComponent(p: any): p is IAppComponent<any> {
  console.warn('Deprecation warning: `isAppComponent` is deprecated and should not be used anymore.');
  const instance = p as IAppComponent<any>;
  const classType = instance.constructor as any as IHasStaticDefineDependencies;
  return instance && instance.initializeComponent! && typeof instance.initializeComponent === 'function' &&
         instance.uninitializeComponent! && typeof instance.uninitializeComponent === 'function' &&
         classType.defineDependencies && typeof classType.defineDependencies === 'function';
}

/**
 * Checks whether a class implements the required AppComponent interface methods (i.e. whether it implements the
 * [[IAppComponent]] interface and has the required static members as defined in
 * [[IHasRequiredStaticAppComponentMembers]]).
 * @param p The class to test.
 * @return A boolean that indicates whether the check was successful or not.
 * @deprecated
 * @hidden
 */
export function isAppComponentClass(p: any): p is IAppComponentClassType {
  console.warn('Deprecation warning: `isAppComponentClass` is deprecated and should not be used anymore.');
  let initializeFound = false;
  let unInitializeFound = false;
  let defineDependenciesFound = false;
  // Get all properties of the class, including those that are inherited from parent classes.
  let obj = p;
  do {
    // Check static members
    Object.getOwnPropertyNames(obj).forEach((d) => {
      if (!defineDependenciesFound) {
        defineDependenciesFound = (d === 'defineDependencies') && (typeof obj[d] === 'function');
      }
    });
    // Check instance members
    const i = obj.prototype ? obj.prototype : {};
    Object.getOwnPropertyNames(i).forEach((d) => {
      if (!initializeFound) {
        initializeFound = (d === 'initializeComponent') && (typeof i[d] === 'function');
      }
      if (!unInitializeFound) {
        unInitializeFound = (d === 'uninitializeComponent') && (typeof i[d] === 'function');
      }
    });
    obj = Object.getPrototypeOf(obj);
  } while (obj);
  return initializeFound && unInitializeFound && defineDependenciesFound;
}

/**
 * A helper function that triggers the initialization of an AppComponent.
 * @param param The component/object to initialize.
 * @return A promise that resolves with the fully initialized component instance (when an AppComponent is passed to
 * this helper) or with the parameter itself.
 */
export function initHelper(param: any): Promise<any> {
  if (param && requiresInitialization(param)) {
    return param.initializeComponent!();
  } else {
    return Promise.resolve(param);
  }
}

/**
 * A helper function that initializes dependencies for an AppComponent.
 * @param dependencies An object with elements of the form `<dependencyName>: <dependency>`.
 * @return A promise that resolves with an object containing elements of the form
 *  `<dependencyName>: <initializedDependency>`, or rejects if any of the dependencies failed to initialize.
 */
export function dependencyHelper(dependencies: {[key: string]: any}) {
  if (!dependencies) {
    dependencies = {};
  }
  const keys = Object.keys(dependencies);
  return Promise.all(Object.values(dependencies).map((dependency) => initHelper(dependency))).then(
    (resolvedDependenciesArray) => {
      return resolvedDependenciesArray.reduce((acc, value, index) => {
        acc[keys[index]] = value;
        return acc;
      }, {});
    },
  );
}

/**
 * A helper function to deal with lazy-loaded dependencies (i.e. dependencies that have the `ready` flag set to `false`
 * in `defineDependencies`). Any dependency can be passed, even if it is not a lazy-loaded one. The helper will treat
 * them accordingly.
 * @param dependency The dependency to initialize.
 * @return A promise that is resolved with the fully initialized dependency or rejected on error.
 */
export function lazyLoadHelper(dependency: IAppComponent<any> | ILazyLoadPlaceHolder<any>): Promise<any> {
  if (requiresInitialization(dependency)) {
    return dependency.initializeComponent!();
  } else if (isLazyLoadPlaceHolder(dependency)) {
    return dependency.lazyLoad();
  } else {
    return Promise.resolve(dependency);
  }
}
