import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";

type InstancesSidebarProps = {
  expanded: boolean;
};

export const ProductSelectDropDownContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  gap: "8px",
  alignItems: "center",
}));

export const InstancesSidebar = styled(Box, {
  shouldForwardProp: (prop) => prop !== "expanded",
})<InstancesSidebarProps>((props) => ({
  maxWidth: props.expanded ? "100%" : "500px",
  display: "flex",
  flexDirection: "column",
  flexGrow: 10,
  height: "100%",
  minWidth: "500px",
  transition: "all .5s ease-in-out",
  backgroundColor: "white",
  overflow: "hidden",
}));

export const InstanceSidebarHeader = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  padding: "12px",
  gap: "12px",
  alignItems: "",
  justifyContent: "space-between",
}));

export const InstanceSidebarHeaderRow = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  gap: "8px",
  justifyContent: "space-between",
}));

export const InstanceSidebarHeaderGroup = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  gap: "12px",
}));

export const ModelViewContainer = styled(Box)(() => ({
  flexGrow: 10,
  width: "100%",
  height: "100%",
}));

export const ProductSelectDropdown = styled(Select<string>)({
  minWidth: "200px",
});
