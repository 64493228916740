/**
 * @fileoverview Utilities for management of URLs and query strings
 */
import * as _ from 'underscore';

/**
 * Method used to parse the query parameters and return
 * them in a hashmap.
 * @param inQueryString - queryString to parse
 * @return Querystring parameters as key-value pairs
 *
 * @hidden
 */
function deserializeQueryString(inQueryString: string): {[key: string]: string} {
  const urlParts = inQueryString.split('?');
  let queryString: string | undefined;

  // Passed something like 'example.com?foo=bar' OR '?foo=bar'
  if (urlParts.length > 1) {
    queryString = urlParts[1];
  } else {
    // passed something like 'foo=bar'
    queryString = urlParts[0];
  }

  const splitParams = queryString.split('&');

  return _.reduce(splitParams, (parsedParams, param) => {
    const paramArr = param.split('=');

    parsedParams[paramArr[0]] = paramArr[1];
    return parsedParams;
  }, {});
}

/**
 * Transform ```{foo: 'bar', hello: 'world'}``` into ```?foo=bar&hello=world```
 * @param inQueryObject - Object to serialize into a query string.
 * @return Query string representation of the object.
 * @hidden
 */
function objectToQueryString(inQueryObject: {[key: string]: string | undefined}): string {
  return Object.keys(inQueryObject)
  .filter((k) => (inQueryObject[k]) !== undefined)
  .map((k) => {
    return encodeURIComponent(k) + '=' + encodeURIComponent(inQueryObject[k]!);
  }).join('&');
}

/**
 * Method used to add parameters to an existing url
 * @param in_url - base url to add parameters to
 * @param in_params - parameters to add
 * @return Newly generated url
 *
 * @hidden
 */
function addParameters(inUrl: string, inParams?: {[key: string]: string}): string {
  // Split off an anchor in the URL
  const splitURL = inUrl.split('#');
  inUrl = splitURL[0];
  const hash = splitURL[1] !== undefined ? '#' + splitURL[1] : '';

  let paramsString = objectToQueryString(inParams!);
  paramsString = '?' + paramsString;
  if (inUrl.indexOf('?') !== -1) {
    paramsString = paramsString.replace('?', '&');
  }
  return inUrl + paramsString + hash;
}

/**
 * @hidden
 */
function isURL(str: string): boolean {
  const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
  '(\\?[;,:@$!\\*/&a-z\\d%_.~+=-]*)?' + // query string
  '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return pattern.test(str);
}

/**
 * Concatenates a base url and an endpoint.
 * Example: https://developer-stg.api.autodesk.com/ + authentication/v1/gettoken
 *    -> https://developer-stg.api.autodesk.com/authentication/v1/gettoken
 * The function does also make sure that there is exactly one slash between url and endpoint.
 * It does not perform any other error checks.
 * @param baseUrl The base url.
 * @param endpoint The endpoint that should be appended to the base url.
 * @return The resulting url.
 *
 * @hidden
 */
function concatenateURL(baseUrl: string, endpoint: string): string {
  // Check whether the base url ends with a slash
  const baseSlash = baseUrl.endsWith('/');
  // Check whether the endpoint starts with a slash
  const endpointSlash = endpoint.startsWith('/');

  // Concatenate both parts
  if (baseSlash !== endpointSlash) {
    return baseUrl + endpoint;
  } else if (baseSlash && endpointSlash) {
    return baseUrl.substr(0, baseUrl.length - 1) + endpoint;
  } else {
    return baseUrl + '/' + endpoint;
  }
}

export { deserializeQueryString, objectToQueryString, addParameters, isURL, concatenateURL };
