import { useState, useCallback, useEffect, useRef } from "react";
import logger from "../logger";
import { PollingConfig, UsePollingState, PollState } from "./hooks.types";

export const DEFAULT_POLLING_INTERVAL_IN_MS = 5000;

export interface UseAsyncFetchData<DataType> {
  data: DataType | null;
  setData: React.Dispatch<React.SetStateAction<DataType | null>>;
  loading: boolean;
  error: Error | null;
}

export interface UseAsyncPostData<DataType> {
  loading: boolean;
  error: Error | null;
  postFn: (payload: any) => Promise<DataType | null>;
}

export function useAsyncFetchData<DataType>(apiCall: Function): UseAsyncFetchData<DataType> {
  const [data, setData] = useState<DataType | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const result = await apiCall();
      setData(result);
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [apiCall]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    data,
    setData,
    loading,
    error,
  };
}

export function useAsyncFetchDataWithArgs<DataType>(apiCall: Function, args?: any[]): UseAsyncFetchData<DataType> {
  const [data, setData] = useState<DataType | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = useCallback(
    async (args: any) => {
      try {
        setLoading(true);
        const result = await apiCall(...args);
        setData(result);
      } catch (error: any) {
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [apiCall]
  );

  useEffect(() => {
    if (args) {
      fetchData(args);
    } else {
      // We reset the data if the api call
      // args are not present
      setData(null);
    }
  }, [args, fetchData]);

  return {
    data,
    setData,
    loading,
    error,
  };
}

export function useAsyncPostData<DataType>(apiPostCall: Function): UseAsyncPostData<DataType> {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const postFn = async (payload: any): Promise<DataType | null> => {
    try {
      setLoading(true);
      const result = await apiPostCall(payload);
      setLoading(false);
      return result;
    } catch (error: any) {
      setLoading(false);
      setError(error);
      return null;
    }
  };

  return { loading, error, postFn };
}

export const useAsyncPollingWithArgs = <DataType>(
  callback: (...args: any) => Promise<DataType | undefined>,
  shouldPollingContinue: (data: DataType) => boolean,
  { interval }: PollingConfig = { interval: DEFAULT_POLLING_INTERVAL_IN_MS }
): UsePollingState<DataType> => {
  const [data, setData] = useState<DataType | null>(null);
  const [pollState, setPollState] = useState<PollState>(PollState.INDETERMINATE);

  const startPolling = (): void => {
    setPollState(PollState.READY);
  };

  useEffect(() => {
    async function poll() {
      try {
        setPollState(PollState.POLLING);
        const result = await callback();

        if (result) {
          setData(result);
          if (!shouldPollingContinue(result)) {
            setPollState(PollState.FINISHED);
            return;
          }
        }
        const timeoutId = setTimeout(poll, interval);
        return () => timeoutId && clearTimeout(timeoutId);
      } catch (error: any) {
        logger.error(error);
        setPollState(PollState.ERROR);
      }
    }
    // TODO: save the Variant to the dataStore?
    if (pollState === PollState.READY) {
      poll();
    }
  }, [callback, interval, pollState, shouldPollingContinue]);

  return {
    data,
    pollState,
    startPolling,
  };
};

export const usePrevious = (value: any): any => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value; //assign the value of ref to the argument
  }, [value]); //this code will run when the value of 'value' changes
  return ref.current; //in the end, return the current ref value.
};
