import { useCallback, useContext, useEffect } from "react";
import InstanceDetailsPanel from "../InstanceDetailsPanel";
import ProductsInstancesDataGrid from "../ProductsInstancesDataGrid";
import viewerService from "../../../services/viewer/viewerService";
import { ModelViewer } from "../ModelViewer/ModelViewer";

import {
  InstanceSidebarHeader,
  InstanceSidebarHeaderGroup,
  InstanceSidebarHeaderRow,
  InstancesSidebar,
  ModelViewContainer,
} from "./InstancesPanel.style";
import {
  SelectionFilterToggle,
  ExpandToggleButton,
  GenerateOutputsButton,
  ProductSelectDropDown,
} from "./InstancePanelComponents";
import { Instance } from "../../../types/product";
import ProductContext from "../../../context/ProductStore/Product.context";
import useGenerateOutputs from "./hooks/useGenerateOutputs";
import { DynamicContentProduct } from "../../../types/dynamicContent";
import useInstancePanelData from "./hooks/useInstancePanelData";
import useInstancePanelUI from "./hooks/useInstancePanelUI";
import useInstanceSelectionFilter from "./hooks/useInstanceSelectionFilter";
import useProductSelection from "./hooks/useProductSelection";
import useMIDElementsFromViewer from "./hooks/useMIDElementsFromViewer";
import useInstancesSelection from "./hooks/useInstancesSelection";

export const InstancesPanel: React.FC = () => {
  const { instances, products, variants, productsLoading, filteredProductId } = useContext(ProductContext);

  const productsList: DynamicContentProduct[] = [...products.values()];

  // Data
  const {
    dataGridInstances,
    selectedInstances,
    setSelectedInstances,
    selectedInstancesIds,
    hasDataGridInstancesBeenInitialized,
  } = useInstancePanelData();

  // UI
  const { expanded, handleToggleExpanded, showInstanceDetailsPanel, setShowInstanceDetailsPanel, dataGridColumns } =
    useInstancePanelUI({
      dataGridInstances,
    });

  // Selection Filter
  const { visibilityFilter, instancesGridFilters, handleInstanceSelectionFilterChange } = useInstanceSelectionFilter();

  // Generate Outputs
  const { handleGenerateOutputsButtonClick, disabled } = useGenerateOutputs(selectedInstances || []);

  // Product Selection Dropdown
  const { handleSelectedProductIdChange } = useProductSelection();

  // MID elements instances from Viewer
  const { instancesLoading } = useMIDElementsFromViewer();

  const handleInstanceDetailsPanelVisibility = useCallback(
    (showInstanceDetailsPanel: boolean) => {
      setShowInstanceDetailsPanel(showInstanceDetailsPanel);
    },
    [setShowInstanceDetailsPanel]
  );

  // Instances Selection
  const { handleInstancesSelection } = useInstancesSelection({
    dataGridInstances,
    selectedInstances,
    setSelectedInstances,
    handleInstanceDetailsPanelVisibility,
  });

  const getInstancesByProduct = (instancesToFilter: Instance[] | undefined) => {
    const filteredInstances = instancesToFilter?.filter((element) =>
      dataGridInstances.some((instance) => element.contentId === instance.contentId)
    );
    return filteredInstances || [];
  };

  useEffect(() => {
    viewerService.resizeViewer();
  }, [showInstanceDetailsPanel]);

  return (
    <>
      <InstancesSidebar expanded={expanded}>
        <InstanceSidebarHeader>
          <InstanceSidebarHeaderRow>
            <InstanceSidebarHeaderGroup>
              <InstanceSidebarHeaderRow>
                <ProductSelectDropDown
                  value={filteredProductId || ""}
                  onChange={handleSelectedProductIdChange}
                  productList={productsList}
                />
              </InstanceSidebarHeaderRow>
              <GenerateOutputsButton
                disabled={disabled}
                handleGenerateOutputsButtonClick={handleGenerateOutputsButtonClick}
              />
            </InstanceSidebarHeaderGroup>
            <ExpandToggleButton expanded={expanded} onToggle={handleToggleExpanded} />
          </InstanceSidebarHeaderRow>
          <SelectionFilterToggle
            value={visibilityFilter}
            onToggle={handleInstanceSelectionFilterChange}
            allInstances={getInstancesByProduct(instances)}
            selectedInstances={getInstancesByProduct(selectedInstances)}
          />
        </InstanceSidebarHeader>

        <ProductsInstancesDataGrid
          midModelInstances={dataGridInstances}
          selectedInstancesIds={selectedInstancesIds}
          handleInstancesSelection={handleInstancesSelection}
          columns={dataGridColumns}
          tableLoading={instancesLoading || productsLoading || !hasDataGridInstancesBeenInitialized}
          instancesGridFilters={instancesGridFilters}
        />
      </InstancesSidebar>

      {showInstanceDetailsPanel && filteredProductId && (
        <InstanceDetailsPanel
          visibleInstances={dataGridInstances}
          selectedInstancesIds={selectedInstancesIds || []}
          variants={variants}
          currentProduct={products.get(filteredProductId)}
          handleInstanceDetailsPanelVisibility={handleInstanceDetailsPanelVisibility}
        />
      )}
      {!expanded && (
        <ModelViewContainer>
          <ModelViewer />
        </ModelViewContainer>
      )}
    </>
  );
};
