import { useCallback, useContext, useEffect, useState } from "react";
import ProductContext from "../../../../context/ProductStore/Product.context";
import { Instance } from "../../../../types/product";

interface UseInstancePanelState {
  dataGridInstances: Instance[];
  hasDataGridInstancesBeenInitialized: boolean;
  selectedInstances: Instance[] | undefined;
  setSelectedInstances: React.Dispatch<React.SetStateAction<Instance[] | undefined>>;
  selectedInstancesIds: string[] | undefined;
}

const useInstancePanelData = (): UseInstancePanelState => {
  const { instances, filteredProductId } = useContext(ProductContext);

  // Instances to show in the dataGrid
  const [dataGridInstances, setDataGridInstances] = useState<Instance[]>([]);
  const [hasDataGridInstancesBeenInitialized, setInitializeDataGridInstances] = useState<boolean>(false);
  // Selected instances
  const [selectedInstances, setSelectedInstances] = useState<Instance[] | undefined>();

  // TODO: resolve issue with infinite loop
  // if the filter is removed. To recreate,
  // select an instance & switch product.
  // ROOT CAUSE: the selectedInstanceIds hold all selected
  // instances whereas the dataGrid only shows the slected instances
  // for a particular product. DataGrid keep re-rendering to get an updated list
  const selectedInstancesIds: string[] | undefined = selectedInstances
    ?.filter((instance) => dataGridInstances.some((dataGridInstance) => dataGridInstance.id === instance.id))
    .map((instance) => instance.id);

  const filterDataGridInstancesByProductId = useCallback(
    (selectedProductId: string) => {
      if (instances) {
        const filteredInstancesByProduct = instances.filter((instance) => instance.contentId === selectedProductId);
        setDataGridInstances(filteredInstancesByProduct);
        setInitializeDataGridInstances(true);
      }
    },
    [setDataGridInstances, instances]
  );

  // Filtering instances by selected product and finalize data grid instances
  useEffect(() => {
    //If there are not instances in model
    if (instances && instances.length === 0) {
      setInitializeDataGridInstances(true);
    }

    // If instances has been initialized and filteredProductId is not empty
    if (instances && instances?.length > 0 && filteredProductId) {
      filterDataGridInstancesByProductId(filteredProductId);
    }
  }, [filterDataGridInstancesByProductId, filteredProductId, instances]);

  return {
    dataGridInstances,
    hasDataGridInstancesBeenInitialized,
    selectedInstances,
    setSelectedInstances,
    selectedInstancesIds,
  };
};

export default useInstancePanelData;
