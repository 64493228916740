import { useCallback, useContext, useEffect, useState } from "react";
import { getSubFolders, getFolders } from "../../../services/bim360";
import UserContext from "../../../context/UserStore/User.context";
import AccountProjectContext from "../../../context/AccountProjectStore/AccountProject.context";
import ModelSelectionContext from "../../../context/ModelSelectionStore/ModelSelection.context";
import NotificationContext from "../../../context/NotificationStore/Notification.context";
export interface Tree {
  [nodeId: string]: TreeNode[];
}

export interface TreeNode {
  id: string;
  label: string;
}
export interface UseModelFolderBrowser {
  modelFoldersTree: Tree | undefined;
  rootNodes: TreeNode[];
  expandedModelTreeIds: string[];
  handleFolderToggle: (_event: React.SyntheticEvent, nodeIds: string[]) => Promise<void>;
}

const useModelFolderBrowser = (): UseModelFolderBrowser => {
  const { token } = useContext(UserContext);
  const { expandedModelTreeIds, setExpandedTreeNodeIds, modelFoldersTree, setProductFoldersTree } =
    useContext(ModelSelectionContext);
  const { projectId } = useContext(AccountProjectContext);
  const { logAndShowNotification } = useContext(NotificationContext);
  const [rootNodes, setRootNodes] = useState<TreeNode[]>([]);
  const getRootNodes = async (token: string, projectId: string): Promise<TreeNode[]> =>
    (await getFolders(token, projectId))
      .filter((folder) => folder.is_root && folder.title !== "Plans")
      .sort((a, b) => (a.title > b.title ? 1 : -1))
      .map((folder) => ({
        id: folder.urn,
        label: folder.title,
      }));

  const getRootNodesCallback = useCallback(async () => {
    if (!projectId || modelFoldersTree !== undefined) {
      return;
    }

    try {
      const rootNodes = await getRootNodes(token, projectId);
      setRootNodes(rootNodes);
      setProductFoldersTree({ root: rootNodes });
    } catch (error) {
      logAndShowNotification({ error });
    }
  }, [modelFoldersTree, projectId, setProductFoldersTree, token, logAndShowNotification]);

  useEffect(() => {
    getRootNodesCallback();
  }, [getRootNodesCallback]);

  const handleFolderToggle = async (_event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpandedTreeNodeIds(nodeIds);
    if (!projectId || !nodeIds.length) {
      return;
    }

    try {
      const subFolders = await getSubFolders(token, projectId, nodeIds[0]);
      const subFoldersTreeNodes: TreeNode[] = subFolders.map((subFolder) => ({
        id: subFolder.urn,
        label: subFolder.title,
      }));
      const newTree: Tree = {
        ...modelFoldersTree,
        [nodeIds[0]]: subFoldersTreeNodes,
      };
      setProductFoldersTree(newTree);
    } catch (error) {
      logAndShowNotification({ error });
    }
  };

  return {
    modelFoldersTree,
    rootNodes,
    expandedModelTreeIds,
    handleFolderToggle,
  };
};

export default useModelFolderBrowser;
