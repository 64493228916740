import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Logout from "@mui/icons-material/Logout";
import SettingsOutlined from "@mui/icons-material/SettingsOutlined";

export const UserSettingsHeaderContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
}));

export const UserSettingsPopoverContainer = styled(Box)(() => ({
  display: "flex",
  padding: "20px 30px ",
}));

export const UserInfoContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
}));

export const UserMenuContainer = styled(Box)(() => ({
  margin: "0px 24px 24px 24px",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
}));

export const UserSettingsAvatar = styled(Avatar)(() => ({
  width: "56px",
  height: "56px",
}));

export const UserSettingsHeaderAvatar = styled(Avatar)(() => ({
  width: "32px",
  height: "32px",
}));

export const UserSettingsContents = styled(Box)(() => ({
  paddingLeft: "10px",
}));

export const UserLogoutIcon = styled(Logout)(() => ({
  marginRight: "8px",
}));
export const UserSettingsIcon = styled(SettingsOutlined)(() => ({
  marginRight: "8px",
}));
