export const appColors = {
  // Brand Accent Colors: https://brand.autodesk.com/brand-system/color/
  brand: {
    black: "#000000",
    clay: "#d74e26",
    darkSlate: "#666666",
    gold: "#ffc21a",
    iris: "#5f60ff",
    lightSlate: "#cccccc",
    plant: "#2bc275",
    white: "#ffffff",
  },
  // HIG Colors
  primary: {
    autodeskBlue100: "#cdeaf7",
    autodeskBlue200: "#9bd5ef",
    autodeskBlue300: "#6ac0e7",
    autodeskBlue400: "#38abdf",
    autodeskBlue500: "#0696d7",
    autodeskBlue600: "#007fc6",
    autodeskBlue700: "#006EAF",
    autodeskBlue800: "#074b78",
    autodeskBlue900: "#0a324d",
    black: "#000",
    charcoal100: "#eeeeee",
    charcoal200: "#dcdcdc",
    charcoal300: "#cccccc",
    charcoal400: "#bbbbbb",
    charcoal500: "#999999",
    charcoal600: "#808080",
    charcoal700: "#666666",
    charcoal800: "#4d4d4d",
    charcoal900: "#3c3c3c",
    white: "#fff",
  },
  secondary: {
    darkBlue100: "#e1ebf7",
    darkBlue200: "#bcd3ee",
    darkBlue300: "#8babdc",
    darkBlue400: "#5f8bcb",
    darkBlue500: "#3970b9",
    darkBlue600: "#1858a8",
    darkBlue700: "#064285",
    darkBlue800: "#003163",
    darkBlue900: "#0c2c54",
    green100: "#e7f2d9",
    green200: "#cfe4b3",
    green300: "#b7d78c",
    green400: "#9fc966",
    green500: "#87b340",
    green600: "#6a9728",
    green700: "#507b16",
    green800: "#385e08",
    green900: "#234200",
    red100: "#faeded",
    red200: "#f7d6d6",
    red300: "#f9b4b4",
    red400: "#f48686",
    red500: "#eb5555",
    red600: "#dd2222",
    red700: "#af1b1b",
    red800: "#800f0f",
    red900: "#520404",
    turquoise100: "#d6f2ef",
    turquoise200: "#ade4de",
    turquoise300: "#84d7ce",
    turquoise400: "#5bc9bd",
    turquoise500: "#32bcad",
    turquoise600: "#23a597",
    turquoise700: "#168576",
    turquoise800: "#0c665b",
    turquoise900: "#04403d",
    yellowOrange100: "#feecd1",
    yellowOrange200: "#fddaa4",
    yellowOrange300: "#fcc776",
    yellowOrange400: "#fbb549",
    yellowOrange500: "#faa21b",
    yellowOrange600: "#ed8d16",
    yellowOrange700: "#d9730b",
    yellowOrange800: "#bf5808",
    yellowOrange900: "#8c3401",
  },
  theme: {
    lightGray: {
      error: "#dd2222",
      new: "#fe8d55",
      success: "#6a9728",
      warning: "#faa21b",
    },
    dark: {
      error: "#eb5555",
      new: "#fe8d55",
      success: "#87b340",
      warning: "#fbb549",
    },
  },
  // HIG Surface colors - https://weave.autodesk.com/web/basics/colors-surfaces
  surface: {
    lightGray: {
      level10: "#ffffff",
      level20: "#f5f5f5",
      level25: "#eeeeee",
      level30: "#d9d9d9",
      level35: "#cccccc",
    },
    darkBlue: {
      level10: "#454f61",
      level20: "#3b4453",
      level25: "#2e3440",
      level30: "#222933",
      level35: "#1a1f26",
    },
    dark: {
      level10: "#535353",
      level20: "#474747",
      level25: "#373737",
      level30: "#2a2a2a",
      level35: "#202020",
    },
  },
};
