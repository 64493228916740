import { useCallback, useContext, useEffect, useState } from "react";
import { SowElement, Parameter } from "types/scopeOfWork";
import ModelSelectionContext from "../../../../context/ModelSelectionStore/ModelSelection.context";
import NotificationContext from "../../../../context/NotificationStore/Notification.context";
import ProductContext from "../../../../context/ProductStore/Product.context";
import {
  MIDModelParameterPrefix,
  MIDModelContentIdParameterName,
  MIDModelVariantIdParameterName,
  MIDModelProjectIdParameterName,
} from "../../../../global/constants/products";
import logger from "../../../../global/logger";
import viewerService from "../../../../services/viewer/viewerService";
import { NOTIFICATION_STATUSES } from "../../../../types/notifications";
import { Instance } from "../../../../types/product";
import text from "../../../../global/text.json";

const instancePanelText = text.instancePanel;

interface UseMIDElementsFromViewerState {
  instancesLoading: boolean;
}

const useMIDElementsFromViewer = (): UseMIDElementsFromViewerState => {
  const { setInstances } = useContext(ProductContext);
  const { currentlyOpenModel } = useContext(ModelSelectionContext);
  const { showNotification } = useContext(NotificationContext);

  const [instancesLoading, setInstancesLoading] = useState<boolean>(true);

  const getAllMIDElements = useCallback(async () => {
    const elements = await viewerService.getAllMIDElements();
    const sowElements = await viewerService.transformRevitElementsToSowElements(
      elements,
      currentlyOpenModel?.lmvModelFileId || ""
    );
    const transformedElements = sowElements.map((sowElement: SowElement): Instance => {
      const midParameters: Parameter[] = sowElement.parameters.filter((parameter) =>
        parameter.name.includes(MIDModelParameterPrefix)
      );
      const midParametersMap = midParameters.reduce((acc: { [key: string]: string }, current: Parameter) => {
        if (!acc[current.name]) {
          acc[current.name] = current.value;
        }
        return acc;
      }, {});
      const contentIdParameterValue = midParametersMap[MIDModelContentIdParameterName];
      const variantIdParameterValue = midParametersMap[MIDModelVariantIdParameterName];
      const projectIdParameterValue = midParametersMap[MIDModelProjectIdParameterName];
      // Get digits in square brakets before eol: https://regex101.com/r/9ZPGLA/1
      const elementIDregex = /^.*\[(\d*)\]$/gm;
      const elementID = elementIDregex.exec(sowElement.name);

      return {
        id: sowElement.dbId.toString(),
        elementId: elementID ? elementID[1] : "",
        variantName: sowElement.family,
        variantId: variantIdParameterValue,
        contentId: contentIdParameterValue,
        category: sowElement.category,
        productName: sowElement.name,
        familyType: sowElement.familyType,
        projectId: projectIdParameterValue,
      };
    });
    setInstances(transformedElements);
  }, [currentlyOpenModel?.lmvModelFileId, setInstances]);

  // Loading instances from LMV
  useEffect(() => {
    const handler = async () => {
      try {
        setInstancesLoading(true);
        await getAllMIDElements();
      } catch (err) {
        logger.error(instancePanelText.failedToFetchMIDElements, { err });
        showNotification({
          severity: NOTIFICATION_STATUSES.ERROR,
          message: instancePanelText.failedToFetchMIDElements,
        });
      } finally {
        setInstancesLoading(false);
      }
    };
    viewerService.viewer.waitForLoadDone().then(handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllMIDElements, setInstancesLoading]);

  return {
    instancesLoading,
  };
};

export default useMIDElementsFromViewer;
