import React, { useCallback, useContext, useEffect, useState } from "react";
import Divider from "@mui/material/Divider";
import Popover from "@mui/material/Popover";
import noProjectImage from "../../../images/3d-settings-icon-light-slate.png";
import SearchIcon from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";
import {
  AccountLogo,
  AccountLogoTitleContainer,
  ProjectSelectorPanelPopoverContainer,
  Search,
  SearchIconWrapper,
  SelectedProjectHeaderContainer,
  SelectorsContainer,
  StyledInputBase,
  AccountArrowIcon,
  AccountAndProjectLoading,
} from "./ProjectSelectorPanel.style";
import AccountsList from "../AccountsList";
import ProjectsList from "../ProjectsList";
import { Tooltip } from "@mui/material";
import { getAccounts, getProjects } from "../../../services/bim360";
import UserContext from "../../../context/UserStore/User.context";
import { BIM360Account, BIM360Project } from "types/bim360";
import AccountProjectContext from "../../../context/AccountProjectStore/AccountProject.context";
import { BIM360StorageKeys } from "../../../global/constants/bim360";
import text from "../../../global/text.json";

import NotificationContext from "../../../context/NotificationStore/Notification.context";

export const ProjectSelectorPanel = (): JSX.Element => {
  const { token } = useContext(UserContext);
  const {
    accountId,
    setAccountId,
    accountName,
    setAccountName,
    accountImg,
    setAccountImg,
    projectId,
    setProjectId,
    projectName,
    setProjectName,
  } = useContext(AccountProjectContext);
  const [anchorElProject, setAnchorElProject] = useState<null | HTMLElement>(null);
  const [accounts, setAccounts] = useState<BIM360Account[] | undefined>();
  const [projects, setProjects] = useState<BIM360Project[] | undefined>();
  const [projectsByAccount, setProjectsByAccount] = useState<BIM360Project[] | undefined>();
  const [isLastProjectSelected, setLastProjectSelected] = useState(false);

  const { logAndShowNotification } = useContext(NotificationContext);

  const fetchAccounts = useCallback(async () => {
    try {
      const accounts = await getAccounts(token);
      setAccounts(accounts);
    } catch (error) {
      logAndShowNotification({ error });
    }
  }, [token, logAndShowNotification]);

  const fetchAllProjects = useCallback(async () => {
    try {
      const projects = await getProjects(token);
      setProjects(projects);
    } catch (error) {
      logAndShowNotification({ error });
    }
  }, [token, logAndShowNotification]);

  useEffect(() => {
    if (token && !isLastProjectSelected) {
      fetchAccounts();
      fetchAllProjects();
    }
  }, [fetchAccounts, fetchAllProjects, token, isLastProjectSelected]);

  useEffect(() => {
    if (accounts?.length && projects?.length && !isLastProjectSelected) {
      const lastSelectedAccount = window.localStorage.getItem(BIM360StorageKeys.SELECTED_ACCOUNT);
      const lastSelectedProject = window.localStorage.getItem(BIM360StorageKeys.SELECTED_PROJECT);

      // If there was an account and project saved in Storage, we set in our store
      if (lastSelectedAccount) {
        const parsedSelectedAccount = JSON.parse(lastSelectedAccount);
        setAccountId(parsedSelectedAccount.accountId);
        setAccountName(parsedSelectedAccount.accountName);
        setAccountImg(parsedSelectedAccount.accountImg);
      }
      if (lastSelectedProject) {
        const parsedSelectedProject = JSON.parse(lastSelectedProject);
        setProjectId(parsedSelectedProject.projectId);
        setProjectName(parsedSelectedProject.projectName);
      }

      setLastProjectSelected(true);
    }
  }, [
    accounts,
    projects,
    isLastProjectSelected,
    setAccountId,
    setAccountName,
    setAccountImg,
    setProjectId,
    setProjectName,
    setLastProjectSelected,
  ]);

  const fetchProjectsFromAccount = useCallback(async () => {
    const projectsFilteredByAccount = projects?.filter((project) => project.account_display_name === accountName);
    setProjectsByAccount(projectsFilteredByAccount);
  }, [accountName, projects]);

  useEffect(() => {
    if (accountId) {
      fetchProjectsFromAccount();
    }
  }, [accountId, fetchProjectsFromAccount]);

  const renderAccountProjectName = (): JSX.Element => {
    if (projectId) {
      return (
        <Tooltip title={`${accountName} - ${projectName}`}>
          <Typography variant="body2" noWrap component="div">
            {accountName} - {projectName}
          </Typography>
        </Tooltip>
      );
    }

    if (!isLastProjectSelected) {
      return <AccountAndProjectLoading />;
    }

    return (
      <Typography variant="body2" noWrap component="div">
        <strong>{projectSelectorText.title}</strong>
      </Typography>
    );
  };

  const handleOpenProjectMenu = async (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElProject(event.currentTarget);
  };

  const handleCloseProjectMenu = () => {
    setAnchorElProject(null);
  };

  const handleSearchProjects = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const { value } = event.target;
    const regex = new RegExp(value, "i");

    const filteredProjects = projects?.filter((project) => {
      const titleLowercase = project.title.toLowerCase();
      return project.account_display_name === accountName && regex.test(titleLowercase);
    });
    setProjectsByAccount(filteredProjects);
  };
  const projectSelectorText = text.projectSelectorPanel;

  return (
    <>
      <SelectedProjectHeaderContainer onClick={handleOpenProjectMenu}>
        <AccountLogoTitleContainer>
          <AccountLogo src={projectId && accountImg ? accountImg : noProjectImage} />
          {renderAccountProjectName()}
        </AccountLogoTitleContainer>
        <AccountArrowIcon anchorElProject={anchorElProject} />
      </SelectedProjectHeaderContainer>
      <Popover
        open={Boolean(anchorElProject)}
        anchorEl={anchorElProject}
        onClose={handleCloseProjectMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <ProjectSelectorPanelPopoverContainer>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder={projectSelectorText.search}
              onChange={handleSearchProjects}
              disabled={!accountId}
            />
          </Search>
          <Divider />

          <SelectorsContainer>
            <AccountsList accounts={accounts} visible={!accountId} />
            <ProjectsList
              projects={projectsByAccount}
              visible={!!accountId}
              handleCloseProjectMenu={handleCloseProjectMenu}
            />
          </SelectorsContainer>
        </ProjectSelectorPanelPopoverContainer>
      </Popover>
    </>
  );
};
