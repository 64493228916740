import { styled } from "@mui/material/styles";
import { CircularProgress } from "@mui/material";
import { DetailsSidebar } from "../../Commons/DetailsSidebar";

export const ModelDetailsSidebar = styled(DetailsSidebar)(() => ({
  backgroundColor: "#eee",
}));

export const ModelDetailsSpinner = styled(CircularProgress)(() => ({
  display: "flex",
  margin: "auto",
}));
