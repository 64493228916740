import Typography from "@mui/material/Typography";
import { appColors } from "../../../utils/colors";
import Card from "@mui/material/Card";

// This component is temporary.
interface ComingSoonProps {
  title?: string;
  children: any;
}
export const ComingSoon = ({ title, children }: ComingSoonProps): JSX.Element => (
  <Card
    elevation={3}
    sx={{
      backgroundColor: appColors.surface.dark.level30,
      color: appColors.primary.white,
      padding: "8px 18px",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: "px",
    }}
  >
    <Typography variant="h6" component="div">
      <strong>{title || "Coming soon"}</strong>
    </Typography>
    <Typography variant="body2" component="div">
      {children}
    </Typography>
  </Card>
);
