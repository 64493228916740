import { IAppComponent, IAppComponentDependency, IHasAllStaticAppComponentMembers } from './interfaces';

/**
 * An optional abstract base class for AppComponents that provides default implementations for all AppComponent
 * interface methods. This class can be used in cases where only one or two interface methods need to be overridden
 * compared to the defaults.
 */
export abstract class AppComponent implements IAppComponent<AppComponent> {
  public static defineDependencies(): IAppComponentDependency[] {
    return [];
  }

  public static isSingleton(): boolean {
    return false;
  }

  public static defineProviders(): any[] {
    return [];
  }

  public initializeComponent(): Promise<AppComponent> {
    return Promise.resolve(this);
  }

  public uninitializeComponent(): Promise<void> {
    return Promise.resolve();
  }
}

/**
 * A compile-time check that makes sure that the base class implements all static methods correctly.
 * This cannot be enforced by implementing the `IAppComponent` interface, since interfaces can only define non-static
 * members.
 * @hidden
 */
const staticComponentMemberCheck: IHasAllStaticAppComponentMembers = AppComponent;
