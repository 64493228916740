import AppBar from "@mui/material/AppBar";
import UserSettingsPanel from "../UserSettingsPanel";
import { ThemeProvider } from "@mui/material/";
import logoMid from "../../../images/logo.png";
import ProjectSelectorPanel from "../ProjectSelectorPanel";
import { AppLogoContainer, AppLogoImage, HeaderAppBarContainer, HeaderToolbarContainer } from "./HeaderAppBar.style";
import { VersionInfo } from "../../VersionInfo/VersionInfo";
import { darkTheme } from "../../../utils/theme";
export function HeaderAppBar(): JSX.Element {
  return (
    <HeaderAppBarContainer>
      <ThemeProvider theme={darkTheme}>
        <AppBar position="static" elevation={0}>
          <HeaderToolbarContainer variant="dense">
            <AppLogoContainer>
              <AppLogoImage src={logoMid} alt="" />
            </AppLogoContainer>
            <VersionInfo MIDW_VERSION={process.env.REACT_APP_MIDW_VERSION} />
            <ProjectSelectorPanel />
            <UserSettingsPanel />
          </HeaderToolbarContainer>
        </AppBar>
      </ThemeProvider>
    </HeaderAppBarContainer>
  );
}
