import { AlertColor } from "@mui/material";
import { useState, useCallback } from "react";
import { NOTIFICATION_STATUSES } from "../../types/notifications";
import { HandleCloseReason } from "./notificationStore.types";
import logger, { MIDWLogExtraData } from "../../global/logger";
import { ErrorCode, MIDWError } from "../../global/errors";

import text from "../../global/text.json";

const notificationStoreText = text.notificationStore;

interface ShowNotificationProps {
  severity: AlertColor;
  message: string;
}

interface LogAndShowNotificationProps extends Partial<ShowNotificationProps> {
  logExtraData?: MIDWLogExtraData;
  error?: Error | MIDWError | unknown;
}
export interface NotificationStore {
  open: boolean;
  severity: AlertColor;
  message: string;
  showNotification: (props: ShowNotificationProps) => void;
  logAndShowNotification: (props: LogAndShowNotificationProps) => void;
  handleClose: (event?: React.SyntheticEvent | Event, reason?: HandleCloseReason) => void;
}
export const useNotificationStore = (): NotificationStore => {
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState<AlertColor>(NOTIFICATION_STATUSES.INFO);
  const [message, setMessage] = useState("");

  const showNotification = useCallback(({ severity, message }: ShowNotificationProps) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  }, []);

  const logAndShowNotification = useCallback(
    ({ severity = NOTIFICATION_STATUSES.ERROR, message, error, logExtraData }: LogAndShowNotificationProps) => {
      let messageToLog = "",
        messageToShow = "";

      if (!message && !error) {
        throw new MIDWError(notificationStoreText.InsufficientArguments, ErrorCode.InsufficientArguments);
      }

      // determine what to show to user and log based on passed parameters
      if (!message && error) {
        messageToLog = messageToShow = logger.extractErrorMessage(error);
      } else if (message && !error) {
        messageToLog = messageToShow = message;
      } else if (message && error) {
        messageToShow = message;
        messageToLog = `${logger.extractErrorMessage(error)},${message}`;
      }

      if (severity === NOTIFICATION_STATUSES.WARNING) {
        logger.warn(messageToLog, logExtraData);
      } else {
        logger.error(messageToLog, logExtraData);
      }

      setMessage(messageToShow);
      setSeverity(severity);
      setOpen(true);
    },
    []
  );

  /**
   * event: The event source of the callback.
   *
   * reason: Can be: "timeout" (autoHideDuration expired), "clickaway", or "escapeKeyDown"
   */
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: HandleCloseReason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return {
    open,
    severity,
    message,
    showNotification,
    logAndShowNotification,
    handleClose,
  };
};
