import { GridColDef } from "@mui/x-data-grid";
import text from "../../../global/text.json";
import DownloadOutputsCell from "./DownloadOutputsCell";
import StatusOutputsCell from "./StatusOutputsCell";

const reviewPanelText = text.reviewPanel;

export const columns: GridColDef[] = [
  {
    field: "status",
    headerName: reviewPanelText.status,
    width: 100,
    renderCell: StatusOutputsCell,
    align: "center",
  },
  {
    field: "objectKey",
    headerName: reviewPanelText.download,
    width: 100,
    renderCell: DownloadOutputsCell,
    align: "center",
  },
  {
    field: "name",
    headerName: reviewPanelText.variantName,
    width: 250,
  },
  {
    field: "productName",
    headerName: reviewPanelText.productName,
    width: 200,
  },
  {
    field: "instanceCount",
    headerName: reviewPanelText.instanceCount,
    width: 100,
    align: "right",
  },
  {
    field: "modifiedAt",
    headerName: reviewPanelText.updatedOn,
    width: 200,
  },
];
