import React, { useContext } from "react";
import { ReviewPanelContainer, ReviewPanelContent, ReviewPanelHeader } from "./ReviewPanel.styles";
import { useEffect, useState } from "react";
import { LinearProgress, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { DataGrid, GridFilterModel } from "@mui/x-data-grid";
import ProductContext from "../../../context/ProductStore/Product.context";
import { Instance } from "../../../types/product";
import { format } from "date-fns";
import { getVariantFromAPI } from "../../../services/variants";
import UserContext from "../../../context/UserStore/User.context";
import AccountProjectContext from "../../../context/AccountProjectStore/AccountProject.context";
import { useAsyncPollingWithArgs } from "../../../global/hooks/hooks";
import { DynamicContentVariant, VariantOutputStatus, VariantOutputType } from "../../../types/dynamicContent";
import text from "../../../global/text.json";
import { columns } from "./ReviewPanelColumns";
import NotificationContext from "../../../context/NotificationStore/Notification.context";
import logger from "../../../global/logger";
import { NOTIFICATION_STATUSES } from "../../../types/notifications";

interface OutputsReviewMap {
  [key: string]: OutputsReviewTableDataModel;
}
interface OutputsReviewTableDataModel {
  id: string;
  status?: VariantOutputStatus;
  name: string;
  productName: string;
  instanceCount: number;
  modifiedAt: string;
  productId: string;
  objectKey?: string;
}
interface OutputStatusMetadata {
  status: VariantOutputStatus | undefined;
  modifiedAt: string;
  objectKey: string | undefined;
}

const shouldVariantOutputsPollingContinue = (variantsOutputs: OutputsReviewTableDataModel[]): boolean =>
  !variantsOutputs.every(
    (variantOutputs) =>
      variantOutputs.status === undefined ||
      [VariantOutputStatus.SUCCESS, VariantOutputStatus.CANCELLED, VariantOutputStatus.FAILED].includes(
        variantOutputs.status
      )
  );

export const ReviewPanel: React.FC = () => {
  const reviewPanelText = text.reviewPanel;
  const [status, setStatus] = useState(reviewPanelText.all);
  const [loading, setLoading] = useState(true);
  const { instances } = useContext(ProductContext);
  const { token } = useContext(UserContext);
  const { projectId } = useContext(AccountProjectContext);
  const { showNotification } = useContext(NotificationContext);
  const { data: polledAllVariantsOutputs, startPolling: startPollingVariantStatus } = useAsyncPollingWithArgs<
    OutputsReviewTableDataModel[]
  >(async (): Promise<OutputsReviewTableDataModel[]> => {
    if (projectId && instances) {
      const transformedInstances: OutputsReviewMap = instances.reduce(
        (prev: OutputsReviewMap, current: Instance): OutputsReviewMap => {
          if (!prev[current.variantId]) {
            prev[current.variantId] = {
              id: current.variantId,
              productId: current.contentId,
              status: undefined,
              name: current.variantName,
              productName: current.productName,
              instanceCount: 1,
              modifiedAt: "",
            };
          } else {
            prev[current.variantId] = {
              ...prev[current.variantId],
              instanceCount: prev[current.variantId].instanceCount + 1,
            };
          }
          return prev;
        },
        {}
      );
      // Retrieve output status for each variant
      const variantsOutputStatusPromise: Promise<OutputStatusMetadata>[] = Object.keys(transformedInstances).map(
        async (variantId): Promise<OutputStatusMetadata> => {
          const productId = transformedInstances[variantId].productId;
          try {
            // Fetch output status
            const variant: DynamicContentVariant = await getVariantFromAPI(token, projectId, productId, variantId);
            const variantBOMOutput = variant.outputs.find((output) => output.type === VariantOutputType.BOM);
            return {
              status: variantBOMOutput?.status,
              modifiedAt: format(new Date(variant.updatedAt), "MMM d, yyyy hh:mm a"),
              objectKey: variantBOMOutput?.urn,
            };
          } catch (err) {
            logger.error(reviewPanelText.failToGetVariant, {
              err,
              projectId,
              productId,
              variantId,
            });
            showNotification({
              message: reviewPanelText.failToGetVariant,
              severity: NOTIFICATION_STATUSES.ERROR,
            });
            return {
              status: undefined,
              modifiedAt: "",
              objectKey: undefined,
            };
          }
        }
      );
      const allVariantsOutputStatus = await Promise.all(variantsOutputStatusPromise);
      const mergedTableData = Object.values(transformedInstances).map((tableData, index) => ({
        ...tableData,
        ...allVariantsOutputStatus[index],
      }));
      return mergedTableData;
    }
    return [];
  }, shouldVariantOutputsPollingContinue);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });
  useEffect(() => {
    if (polledAllVariantsOutputs === null) {
      setLoading(true);
      startPollingVariantStatus();
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [polledAllVariantsOutputs]);

  const handleStatusFilterChange = (_event: React.MouseEvent<HTMLElement>, value: string) => {
    setStatus(value);
    if (value === reviewPanelText.all) {
      setFilterModel({ items: [] });
    } else {
      setFilterModel({
        items: [
          {
            columnField: "status",
            operatorValue: "equals",
            value,
          },
        ],
      });
    }
  };

  const getRowId = (row: OutputsReviewTableDataModel): string => row.id;

  return (
    <ReviewPanelContainer>
      <ReviewPanelHeader>
        <ToggleButtonGroup value={status} exclusive onChange={handleStatusFilterChange} size="small">
          <ToggleButton value={reviewPanelText.all}>{reviewPanelText.all}</ToggleButton>
          <ToggleButton value={VariantOutputStatus.PENDING}>{reviewPanelText.pending}</ToggleButton>
          <ToggleButton value={VariantOutputStatus.SUCCESS}>{reviewPanelText.success}</ToggleButton>
          <ToggleButton value={VariantOutputStatus.FAILED}>{reviewPanelText.failed}</ToggleButton>
        </ToggleButtonGroup>
      </ReviewPanelHeader>
      <ReviewPanelContent>
        <DataGrid
          components={{
            LoadingOverlay: LinearProgress,
          }}
          getRowId={getRowId}
          rows={polledAllVariantsOutputs || []}
          columns={columns}
          rowHeight={40}
          headerHeight={34}
          loading={loading}
          filterModel={filterModel}
        />
      </ReviewPanelContent>
    </ReviewPanelContainer>
  );
};
