import { DashboardCellFrame } from "./DashboardCell.styles";
export interface DashboardCellProps {
  grow?: number;
  width?: string;
  height?: string;
  elevation?: number;
  children?: any;
}

export const DashboardCell = ({ grow, width, height, elevation, children }: DashboardCellProps): JSX.Element => {
  const sx: { flexGrow?: number; width?: string; height?: string } = { height };
  if (width) {
    sx.width = width;
  } else {
    sx.flexGrow = grow || 1;
  }
  return (
    <DashboardCellFrame sx={sx} elevation={elevation === undefined ? 2 : elevation}>
      {children}
    </DashboardCellFrame>
  );
};
