import { useState } from "react";
import { MAIN_TABS, OUTPUTS_SUBTABS, PRODUCTS_SUBTABS } from "../../global/constants/tabs";

// MAIN TABS
export interface MainTabsStore {
  selectedTab: MAIN_TABS;
  handleChangeTab: (_: any, value: MAIN_TABS) => void;
}

export const useMainTabsStore = (): MainTabsStore => {
  const [selectedTab, setSelectedTab] = useState<MAIN_TABS>(MAIN_TABS.PRODUCTS);

  const handleChangeTab = (_: any, value: MAIN_TABS) => {
    setSelectedTab(value);
  };

  return {
    selectedTab,
    handleChangeTab,
  };
};

export interface ProductsSubTabsStore {
  selectedProductsSubTab: string;
  setSelectedProductsSubTab: React.Dispatch<React.SetStateAction<string>>;
}

// PRODUCTS SUBTABS
export const useProductsSubTabsStore = (): ProductsSubTabsStore => {
  const [selectedProductsSubTab, setSelectedProductsSubTab] = useState<string>(PRODUCTS_SUBTABS.MODELS);

  return {
    selectedProductsSubTab,
    setSelectedProductsSubTab,
  };
};

export interface OutputsSubTabsStore {
  selectedOutputsSubTab: string;
  setSelectedOutputsSubTab: React.Dispatch<React.SetStateAction<string>>;
}

// OUTPUTS SUBTABS
export const useOutputsSubTabsStore = (): OutputsSubTabsStore => {
  const [selectedOutputsSubTab, setSelectedOutputsSubTab] = useState<string>(OUTPUTS_SUBTABS.REVIEW);

  return {
    selectedOutputsSubTab,
    setSelectedOutputsSubTab,
  };
};
