export enum ErrorCode {
  InsufficientArguments = "insufficient-arguments",
  InvalidToken = "invalid-token",
  InvalidAPIURLError = "incalid-api-url-error",
  ServiceUnavailableError = "service-unavailable-error",
  AxiousResponseError = "axious-response-error",
  UserInfoLoadError = "user-info-load-error",
  SetProfileError = "set-profile-error",
  UnauthorizedAccessError = "unauthorized-access-error",
  UnexpectedError = "unexpected-error",
  BIM360AccountsLoadError = "bim-360-accounts-load-error",
  BIM360ProjectsLoadError = "bim-360-projects-load-error",
  BIM360FoldersLoadError = "bim-360-folders-load-error",
  BIM360SubfoldersLoadError = "bim-360-subfolders-load-error",
  BIM360FolderContentLoadError = "bim360-folder-content-load-error",
  BIM360FolderManifestLoadError = "bim360-folder-manifest-load-error",
  BIM360DocumenThumbnailLoadError = "bim360-document-thumbnail-load-error",
  ProductsLoadError = "products-load-error",
  VariantsLoadError = "variants-load-error",
  VariantPostError = "variant-post-error",
  VariantLoadError = "variant-load-error",
  VariantOutputsLoadError = "variant-outputs-error",
  FileDownloadURLLoadError = "file-download-url-load-error",
  BOMURLEmptyError = "bom-url-empty-error",
}

export class MIDWError extends Error {
  code: ErrorCode;

  constructor(message: string, code: ErrorCode) {
    super(message);
    this.code = code;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}
