import {
  ClientSideOAuth2Component,
  IClientSideOAuth2Configuration,
  UserInfoComponent,
} from "@adsk/forge-appfw-authentication";
import { IForgeConfiguration } from "@adsk/forge-appfw-forge-configuration";
import { ErrorCode, MIDWError } from "../global/errors";
import { UserInfo } from "../types/user";
import { authenticationConfig, forgeConfig } from "./config";
import text from "../global/text.json";

const authServiceText = text.authService;

export class AuthService {
  public token?: string;

  private readonly authClient: ClientSideOAuth2Component;
  private userInfoClient: UserInfoComponent;

  constructor(authConfig: IClientSideOAuth2Configuration, forgeConfig: IForgeConfiguration) {
    this.authClient = new ClientSideOAuth2Component(authConfig);
    this.userInfoClient = new UserInfoComponent(this.authClient, forgeConfig);
  }

  public async authenticate(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.authClient.bearerTokenFunction((error?: object, token?: string) => {
        if (error) {
          reject(error);
        } else if (token) {
          this.token = token;
          resolve(token);
        } else {
          reject(new MIDWError(authServiceText.invalidToken, ErrorCode.InvalidToken));
        }
      });
    });
  }

  public async getUserInfo(): Promise<UserInfo> {
    await this.userInfoClient.initializeComponent();
    const userInfo = await this.userInfoClient.getUserInfo();

    return { ...(userInfo as UserInfo), email: (userInfo as UserInfo).emailId };
  }

  public logout(): Location {
    return this.userInfoClient.logout();
  }
}

export default new AuthService(authenticationConfig, forgeConfig);
