import Typography from "@mui/material/Typography";
import { TreeItem, TreeView } from "@mui/lab";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ArrowRight from "@mui/icons-material/ArrowRight";
import Divider from "@mui/material/Divider";
import useModelFolderBrowser, { TreeNode } from "./useModelFolderBrowser";
import { HigFolder, HigFolderOpen } from "../../Commons/CustomIcon/HigIcons";
import {
  TreeViewContainer,
  TitleContainer,
  FolderContainer,
  FolderNodeContainer,
  FolderNode,
} from "./ModelsFolderBrowser.style";
import text from "../../../global/text.json";
interface ModelsFolderBrowserProps {
  handleFolderSelection: (folderUrn: string) => void;
}
const modelsFolderText = text.modelsFolderBrowser;

export const ModelsFolderBrowser: React.FC<ModelsFolderBrowserProps> = ({ handleFolderSelection }) => {
  const { modelFoldersTree, expandedModelTreeIds, handleFolderToggle } = useModelFolderBrowser();
  const renderTree = (children: TreeNode[]) =>
    children.map((child) => {
      const { id, label } = child;
      const childrenNodes =
        modelFoldersTree?.[id] && modelFoldersTree[id].length > 0 ? renderTree(modelFoldersTree[id]) : [<div key={id} />];

      return (
        <TreeItem
          key={id}
          nodeId={id}
          label={
            <FolderNodeContainer>
              <FolderNode>
                {expandedModelTreeIds?.find((nodeId) => nodeId === id) ? <HigFolderOpen /> : <HigFolder />}
              </FolderNode>
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </FolderNodeContainer>
          }
          onClick={() => handleFolderSelection(id)}
        >
          {childrenNodes}
        </TreeItem>
      );
    });

  return (
    <FolderContainer>
      <TitleContainer>
        <Typography variant="body1" noWrap>
          {modelsFolderText.selectModel}
        </Typography>
      </TitleContainer>
      <Divider />
      <TreeViewContainer>
        <TreeView
          defaultCollapseIcon={<ArrowDropDown />}
          defaultExpandIcon={<ArrowRight />}
          onNodeToggle={handleFolderToggle}
          expanded={expandedModelTreeIds}
        >
          {renderTree(modelFoldersTree?.root || [])}
        </TreeView>
      </TreeViewContainer>
    </FolderContainer>
  );
};
