import { useCallback, useContext, useEffect, useState } from "react";
import { GridColDef, GridValueGetterParams, GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid";
import { isNull, isUndefined } from "lodash";
import ProductContext from "../../../../context/ProductStore/Product.context";
import { dataGridDefaultSettings, defaultColumsForVariantInputs } from "../../../../global/constants/products";
import { DynamicContentInput, DynamicContentVariant } from "../../../../types/dynamicContent";
import { VariantsMap } from "../../../../types/variants";
import text from "../../../../global/text.json";
import { Instance } from "../../../../types/product";

interface UseInstancePanelUIProps {
  dataGridInstances: Instance[];
}

interface UseInstancePanelUIState {
  expanded: boolean;
  handleToggleExpanded: () => void;
  showInstanceDetailsPanel: boolean;
  setShowInstanceDetailsPanel: React.Dispatch<React.SetStateAction<boolean>>;
  dataGridColumns: GridColDef[];
}

const useInstancePanelUI = ({ dataGridInstances }: UseInstancePanelUIProps): UseInstancePanelUIState => {
  const { products, variants, filteredProductId } = useContext(ProductContext);

  const [expanded, setExpanded] = useState(false);
  const [showInstanceDetailsPanel, setShowInstanceDetailsPanel] = useState<boolean>(false);
  const [dataGridColumns, setDataGridColumns] = useState<GridColDef[]>([]);

  const handleToggleExpanded = () => {
    setExpanded(!expanded);
  };

  const getInstancesGridColumns = useCallback(() => {
    const variantsMap: VariantsMap = variants.reduce((acc: VariantsMap, curr: DynamicContentVariant) => {
      if (curr.contentId === filteredProductId) {
        acc[curr.variantId] = curr;
      }
      return acc;
    }, {});
    let columnsForVariantInputs: GridColDef[] = [];

    if (filteredProductId) {
      // All variants belonging to the same product
      // will always have the same inputs (not values)
      // as the inputs for Variants are merged with the Product inputs
      // on the back-end
      const inputs = products.get(filteredProductId)?.inputs || [];
      columnsForVariantInputs = inputs.map((colInput: DynamicContentInput) => ({
        field: colInput.name,
        headerName: colInput.name,
        width: dataGridDefaultSettings.COLUMN_WIDTH,
        valueGetter: (params: GridValueGetterParams) => {
          if (params.row) {
            const associatedVariant: DynamicContentVariant | undefined = variantsMap[params.row.variantId];
            const matchingInput: DynamicContentInput | undefined = associatedVariant?.inputs.find(
              (rowInput: DynamicContentInput) => rowInput.name === colInput.name
            );
            if (matchingInput) {
              return isUndefined(matchingInput.value) || isNull(matchingInput.value)
                ? text.common.noValueAvailable
                : matchingInput.value;
            }
            return text.common.noValueAvailable;
          }
        },
      }));
    }

    const columns: GridColDef[] = [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        type: "boolean",
        width: 60,
      },
      ...defaultColumsForVariantInputs,
      ...columnsForVariantInputs,
    ];

    setDataGridColumns(columns);
  }, [filteredProductId, products, setDataGridColumns, variants]);

  useEffect(() => {
    if (dataGridInstances.length > 0) {
      getInstancesGridColumns();
    }
  }, [dataGridInstances.length, getInstancesGridColumns]);

  return {
    expanded,
    handleToggleExpanded,
    showInstanceDetailsPanel,
    setShowInstanceDetailsPanel,
    dataGridColumns,
  };
};

export default useInstancePanelUI;
