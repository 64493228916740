import { VerticalTabBarContainer, VerticalTabTabs } from "./VerticalTabBar.style";
import CustomIcon from "../CustomIcon";
import Tab from "@mui/material/Tab";
import { MIDSubTab, MIDTabsOrientation, tabsIconWidth } from "../../../global/constants/tabs";

interface VerticalTabBarProps {
  value: string;
  onChange: (_: any, value: string) => void;
  tabs: MIDSubTab[];
  ariaLabel?: string;
}

export const VerticalTabBar = ({ value, onChange, tabs, ariaLabel }: VerticalTabBarProps): JSX.Element => (
  <VerticalTabBarContainer>
    <VerticalTabTabs orientation={MIDTabsOrientation} value={value} onChange={onChange}>
      {tabs.map((tab, n) => (
        <Tab
          data-testid={tab.value}
          key={`${ariaLabel}-VerticalTab-${n}`}
          value={tab.value}
          label={tab.label}
          disabled={tab.disabled}
          icon={<CustomIcon icon={tab.icon} width={tabsIconWidth} opacity={tab.disabled ? 0.5 : 1} />}
        />
      ))}
    </VerticalTabTabs>
  </VerticalTabBarContainer>
);
