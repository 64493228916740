import {
  DynamicContentInput,
  MultiValueNumericInput,
  MultiValueTextInput,
  NumericInput,
  TemplateInputType,
} from "../types/dynamicContent";

export function isNumericInput(input: DynamicContentInput): input is NumericInput {
  return input.type === TemplateInputType.Numeric;
}

export function isMultiValueNumericInput(input: DynamicContentInput): input is MultiValueNumericInput {
  return input.type === TemplateInputType.MultiValueNumeric;
}

export function isMultiValueTextInput(input: DynamicContentInput): input is MultiValueTextInput {
  return input.type === TemplateInputType.MultiValueText;
}

export function isRejected(input: PromiseSettledResult<unknown>): input is PromiseRejectedResult {
  return input.status === "rejected";
}

export function isFulfilled<T>(input: PromiseSettledResult<T>): input is PromiseFulfilledResult<T> {
  return input.status === "fulfilled";
}
