import * as _ from 'underscore';

import { dependencyHelper,
         IAppComponent,
         IAppComponentDependency,
         IHasRequiredStaticAppComponentMembers } from '@adsk/forge-appfw-component-helpers';
import { IForgeConfiguration } from '@adsk/forge-appfw-forge-configuration';

import {
  BearerTokenFunctionType,
  ClientTokenCallbackType,
  ForgeScope,
  IAuthentication,
} from '../../shared/components/authentication';
import { concatenateURL, isURL, objectToQueryString } from '../../shared/utils/urlUtils';
import { getTokenExpiry, isSuperSet, removeDuplicateScopes, requestHTTPPromise } from '../../shared/utils/utils';
import { getUrlHashValue, getUrlSearchValue, removeUrlHashValue, removeUrlSearchValue } from '../helpers/browserHelper';

/**
 * @hidden
 */
const REQUEST_OPTIONS = { withCredentials: true };

/**
 * Defines the data interface of configuration objects or components.<br><br>
 * 3-legged authentication with implicit grant requires:
 *  <blockquote>clientId, authenticationEndpoint, callbackUrl</blockquote><br>
 * 3-legged authentication with authorization code grant requires:
 *  <blockquote>clientId, authenticationEndpoint, callbackUrl, tokenExchangeEndpoint</blockquote>
 *
 * Default provider registration type: <i>ClientSideAuthenticationConfiguration</i>.
 */
export interface IClientSideOAuth2Configuration {
  /**
   * Client ID of the app.
   * See {@link https://developer.autodesk.com/en/docs/oauth/v2/tutorials/create-app/} for details on how to create a
   * client id.
   */
  clientId: string;

  /**
   * The authentication endpoint. Either a full url, or only an endpoint when
   * using the {@link ForgeConfiguration}. Note that all endpoints used in combination with the ForgeConfiguration
   * dependency must start with a slash.
   */
  authenticationEndpoint: string;

  /**
   * The callback URL you want the user to be redirected to after they grant
   * consent. If it's a function, this function should return the URL.
   */
  callbackUrl: string | ((...args: any[]) => string);

  /**
   * The endpoint of the application server that is called to fetch a token.
   * Only required for 3-legged authentication with authorization code grant in order to exchange the authorization code
   * for an access token. Either a full url, or only an endpoint when using the {@link ForgeConfiguration}. Note that
   * all endpoints used in combination with the ForgeConfiguration dependency must start with a slash.
   */
  tokenExchangeEndpoint?: string;

  /**
   * (default = tokenExchangeEndpoint) The endpoint used to refresh an expired token. Only
   * required for 3-legged authentication with authorization code grant. It defaults to the tokenExchangeEndpoint.
   */
  tokenRefreshEndpoint?: string;

  /**
   * Only used for 3-legged authentication with authorization code grant. When the
   * bearer token function of this component is called and no token or access code is stored in memory (i.e. after a
   * page reload), this endpoint, if provided, will be called to try to fetch an access token from a session handle on
   * the application server. This mechanism can be used in combination with the
   * {@link ServerSideOAuth2Component}.
   * If the application server cannot provide an access token, the 'authenticationEndpoint' will be called instead.
   */
  signedInEndpoint?: string;

  /**
   * (default = data:read) Space separated list of permissions for the requested authorization. More info
   * can be found here: {@link https://developer.autodesk.com/en/docs/oauth/v2/overview/scopes/}.
   */
  scope?: ForgeScope;

  /**
   * (default = THREE_LEGGED_IMPLICIT) The authentication type /
   * algorithm to use.
   */
  authType?: ClientSideOAuth2Type;
}

/**
 * Defines supported authentication types.
 */
enum ClientSideOAuth2Type {
  /**
   * Three-legged authentication with implicit grant.<br>
   * {@link https://developer.autodesk.com/en/docs/oauth/v2/tutorials/get-3-legged-token-implicit/}
   */
  THREE_LEGGED_IMPLICIT = 1,
  /**
   * Three-legged authentication with authorization code grant.<br>
   * {@link https://developer.autodesk.com/en/docs/oauth/v2/tutorials/get-3-legged-token/}
   */
  THREE_LEGGED_AUTHORIZATION = 2,
}

/**
 * The dependencies of a [[ClientSideOAuth2Component]]
 */
interface IClientSideOAuth2Dependencies {
  /**
   * An optional configuration dependency that needs to be registered as a provider type of
   * *ClientSideAuthenticationConfiguration*. If this dependency is not present, configuration options have to be
   * passed directly (via the params object of the initialize function).
   */
  ClientSideAuthenticationConfiguration: IClientSideOAuth2Configuration;

  /**
   * An optional configuration dependency that can be provided to simplify the configuration of endpoint urls in this
   * component. When the *environmentBaseUrl* parameter is set in the ForgeConfiguration dependency, other url
   * parameters like the *authenticationEndpoint* can be provided as relative paths.
   */
  ForgeConfiguration?: IForgeConfiguration;
}

/**
 * The expected format of the access token response sent by the auth endpoint.
 * @hidden
 */
interface IAccessTokenResponseResult {
  data: {
    access_token: string,
    refresh_token: string,
    expires_in: string,
  };
}

/**
 * Checks whether the given parameter is a function.
 * @param v
 * @private
 * @hidden
 */
function isFunction(v: any): v is (...args: any[]) => any {
  return _.isFunction(v);
}

/**
 * This component implements the 3-legged token with implicit grant authentication mechanism as well the client-side
 * part of the 3-legged authentication with authorization code grant, which can be used in combination with the
 * {@link ServerSideOAuth2Component} to fetch a 3-legged token. See
 * {@link https://developer.autodesk.com/en/docs/oauth/v2/tutorials/} for more details.
 *
 * Default provider registration type: <i>AuthenticationComponent</i>.
 *
 * It depends on:
 * - [ClientSideAuthenticationConfiguration]: An optional component or data object that provides
 *  configuration information. See [[IClientSideOAuth2Configuration]]
 *  for parameter details. If this dependency cannot be resolved, configuration information will be taken from a
 *  user-provided parameter object (which takes precedence in any case).
 * - [ForgeConfiguration]: An optional component or data object that provides configuration information for Forge
 *  services. See {@link ForgeConfiguration} for parameter details. Only the <b>environmentBaseUrl</b> of the
 *  ForgeConfiguration is considered in this component. See [[ClientSideOAuth2Component.initialize]] for
 *  details.
 *
 * You can use this component without calling its `initializeComponent` method explicitly. Note that this only holds
 * for the `bearerTokenFunction`. The other members should only be accessed after either waiting for the initialization
 * promise or getting a bearer token.
 *
 * @example
 * ```
 * const auth = new ClientSideOAuth2Component(authConfig);
 * auth.bearerTokenFunction(callback);
 * ```
 */
export class ClientSideOAuth2Component implements IAuthentication, IAppComponent<ClientSideOAuth2Component> {
  /**
   * Defines the dependencies of this component in a format that the Forge DI system is able to parse.
   * Note that the order of dependencies must match the order of constructor parameters.
   * @return An array with dependency definition objects.
   */
  public static defineDependencies(): IAppComponentDependency[] {
    return [
      { type: 'ClientSideAuthenticationConfiguration', mustExist: false },
      { type: 'ForgeConfiguration', mustExist: false },
    ];
  }

  public static get TYPE(): typeof ClientSideOAuth2Type {
    return ClientSideOAuth2Type;
  }

  private _params: IClientSideOAuth2Dependencies;
  private _initPromise!: Promise<ClientSideOAuth2Component>;
  private _config!: IClientSideOAuth2Configuration;
  private _expiresAt: number = -1;
  private _accessToken: string | undefined;
  private _code: string | undefined;
  private _refreshToken: string | undefined;

  /**
   * @param [config] A component or data object that provides configuration information.
   * @param [forgeConfig] A component or data object that provides configuration information about the Forge
   *  environment.
   */
  constructor(config: IClientSideOAuth2Configuration, forgeConfig?: IForgeConfiguration) {
    this._params = { ClientSideAuthenticationConfiguration: config, ForgeConfiguration: forgeConfig };
    this.initializeComponent();
  }

  /**
   * The initialization method of this component.
   * @return A promise that resolves with the fully initialized instance of this component. If you intend to use an
   *  instance of this component, you should only access member functions on the instance that is resolved from this
   *  promise.
   */
  public initializeComponent(): Promise<ClientSideOAuth2Component> {
    // Initialize dependencies
    if (!this._initPromise) {
      this._initPromise = dependencyHelper(this._params).then((dependencies: IClientSideOAuth2Dependencies) => {

        const defaultValues = {
          authType: ClientSideOAuth2Component.TYPE.THREE_LEGGED_IMPLICIT,
          scope: 'data:read',
        };

        // Determine parameters. User provided ones take precedence over those coming from the dependency.
        this._config = _.extend({}, defaultValues, dependencies.ClientSideAuthenticationConfiguration);
        this._config.tokenRefreshEndpoint = this._config.tokenRefreshEndpoint || this._config.tokenExchangeEndpoint;

        const forgeEnv = dependencies.ForgeConfiguration ? dependencies.ForgeConfiguration.environmentBaseUrl : '';

        /**
         * Complements the forgeEnv
         * @param {String} endpointKey endpoint key identifier. Valid values are [
         * authenticationEndpoint | tokenExchangeEndpoint | tokenRefreshEndpoint | signedInEndpoint]
         * @private
         * @hidden
         */
        const generateValidEndpoint = (endpointKey) => {
          if (this._config[endpointKey] && this._config[endpointKey].startsWith('/')) {
            this._config[endpointKey] = concatenateURL(forgeEnv!, this._config[endpointKey]);
          }
        };

        if (forgeEnv && isURL(forgeEnv)) {
          generateValidEndpoint('authenticationEndpoint');
          generateValidEndpoint('tokenExchangeEndpoint');
          generateValidEndpoint('tokenRefreshEndpoint');
          generateValidEndpoint('signedInEndpoint');
        }

        // Type checking
        if (!Object.values(ClientSideOAuth2Component.TYPE).includes(this._config.authType)) {
          return Promise.reject('ClientSideOAuth2Component: Invalid value for option \'authType\'.');
        }

        if (!this._config.clientId || !_.isString(this._config.clientId)) {
          return Promise.reject('ClientSideOAuth2Component: Client ID not provided.');
        }

        if (!this._config.authenticationEndpoint || !isURL(this._config.authenticationEndpoint)) {
          return Promise.reject('ClientSideOAuth2Component: Authentication endpoint URL is not' +
            ' a valid URL.');
        }

        if (!this._config.callbackUrl ||
          (!_.isString(this._config.callbackUrl) && !_.isFunction(this._config.callbackUrl))) {
          return Promise.reject('ClientSideOAuth2Component: Callback URL should be a function or a string.');
        }

        if (this._config.authType === ClientSideOAuth2Component.TYPE.THREE_LEGGED_AUTHORIZATION) {
          if (!this._config.tokenExchangeEndpoint || !isURL(this._config.tokenExchangeEndpoint)) {
            return Promise.reject('ClientSideOAuth2Component: Token exchange endpoint URL is not' +
              ' a valid URL.');
          }

          if (!this._config.tokenRefreshEndpoint || !isURL(this._config.tokenRefreshEndpoint)) {
            return Promise.reject('ClientSideOAuth2Component: Refresh endpoint URL is not' +
              ' a valid URL.');
          }
        }

        // Handles type checking and duplicate removal.
        try {
          this.setScope(this._config.scope!);
        } catch (error) {
          return Promise.reject(error.message);
        }

        return this;
      });
    }
    return this._initPromise;
  }

  /**
   * Uninitialize the component instance.
   * @return A promise that resolves as soon as the instance is fully uninitialized and rejects on error.
   */
  public uninitializeComponent(): Promise<void> {
    return Promise.resolve();
  }

  /**
   * Returns a function that handles authentication and passes the authentication token as a string parameter to
   * a the provided callback. In case of errors, the error will be passed to the callback instead.<br>
   * @example
   * ```
   * function callback(error: Error | null, token: string | null): void {
   *   if (error) {
   *     ...
   *   } else {
   *     ...
   *   }
   * }
   * const bearerTokenFunction = authentication.bearerTokenFunction;
   * bearerTokenFunction(callback);
   * // where callback gets either the token as a string, or an error if the authentication fails.
   * ```
   * @return A function that handles authentication and passes
   *  the authentication token as a string to the provided callback.
   * @public
   */
  public get bearerTokenFunction(): BearerTokenFunctionType {
    return (callback: ClientTokenCallbackType) => {
      // We wait for the initialization to succeed before we trigger the authentication.
      // This allows users to directly call the `bearerTokenFunction` after creating an instance of this component,
      // without explicitly waiting for `initializeComponent`.
      this._initPromise.then(() => {
        this._bearerTokenFunction(callback);
      }).catch((error) => {
        callback(new Error(error));
      });
    };
  }

  /**
   * Get the scope that is used when generating tokens.
   * @return A space separated list of scopes.
   */
  public getScope(): ForgeScope {
    return decodeURI(this._config.scope!);
  }

  /**
   * Set a new scope. Note that the current token will be invalidated when the new scopes do not match the previous
   * ones.
   * @param scope Space separated list of permissions for the requested authorization. More info
   *  can be found here: {@link https://developer.autodesk.com/en/docs/oauth/v2/overview/scopes/}.
   * @throws [[Error]] Scope is undefined, empty or not a string.
   * @public
   */
  public setScope(scope: ForgeScope): void {
    if (scope && !_.isString(scope)) {
      throw new Error('ClientSideOAuth2Component: Scope should be a string.');
    }

    // Remove duplicates
    const newScope = removeDuplicateScopes(scope!);
    const newScopes = newScope.split(' ');

    // Check if the old scopes are a superset of the new ones
    const oldScopes = this.getScope().split(' ');
    const superSet = isSuperSet(oldScopes, newScopes);

    if (superSet) {
      // Old scopes are a superset of the new ones.
      if (newScopes.length !== oldScopes.length) {
        // We have to invalidate the token, but can keep the refresh token when using three legged auth.
        this._expiresAt = -1;
        this._accessToken = undefined;
        this._code = undefined;
      } // else: The new scopes basically equal the old ones, so we're done.
    } else {
      // New scopes extend the old ones, so we cannot even refresh. Instead, we have to generate a new token.
      this._expiresAt = -1;
      this._accessToken = undefined;
      this._refreshToken = undefined;
      this._code = undefined;
    }

    // Store the new scopes
    this._config.scope = encodeURI(newScope) as ForgeScope;
  }

  /**
   * Return default request headers.
   * @return Default request headers.
   */
  private get _headers(): { [key: string]: string } {
    return {
      'content-type': 'application/x-www-form-urlencoded',
    };
  }

  /**
   * Calls the correct authentication method, based on the configuration.
   * @param callback The token callback
   * @hidden
   */
  private _bearerTokenFunction(callback: ClientTokenCallbackType): void {
    switch (this._config.authType) {
      case ClientSideOAuth2Component.TYPE.THREE_LEGGED_AUTHORIZATION:
       this._3leggedAuthorization(callback);
       break;
      case ClientSideOAuth2Component.TYPE.THREE_LEGGED_IMPLICIT:
        this._3leggedImplicit(callback);
        break;
      default:
        throw new Error('ClientSideOAuth2Component: Invalid authType.');
    }
  }

  /**
   * Extract access token from url
   */
  private _extractTokenFromURL(): void {
    const accessToken = getUrlHashValue('access_token');
    const expiresIn = getUrlHashValue('expires_in');
    if (accessToken && expiresIn) {
      this._accessToken = accessToken;
      this._expiresAt = getTokenExpiry(expiresIn);
      removeUrlHashValue('access_token');
      removeUrlHashValue('expires_in');
      removeUrlHashValue('token_type');
    }
  }

  /**
   * Extract authorization code from url
   */
  private _extractCodeFromURL(): void {
    const authCode = getUrlSearchValue('code');
    if (authCode) {
      this._code = authCode;
      removeUrlSearchValue('code');
    } else {
      const error = getUrlSearchValue('error');
      const errorDescription = getUrlSearchValue('error_description');
      if (error) {
        throw new Error('ClientSideOAuth2Component: ' + decodeURIComponent(error) + ': ' +
          decodeURIComponent(errorDescription!));
      }
    }
  }

  /**
   * Get the callback URL from the config.
   * @return The encoded callback URL.
   * @throws {Error} Callback URL is not invalid.
   */
  private _getCallbackURL(): string {
    let callbackUrl;
    if (isFunction(this._config.callbackUrl)) {
      callbackUrl = this._config.callbackUrl.call(this);
    } else {
      callbackUrl = this._config.callbackUrl;
    }
    if (!_.isString(callbackUrl) || callbackUrl.length === 0 || !isURL(callbackUrl)) {
      throw new Error('Callback URL \'' + callbackUrl + '\' is not valid');
    }
    return callbackUrl;
  }

  /**
   * Prepare a 3-legged authentication query.
   * @return The query.
   */
  private _generateThreeLeggedQuery(): string {
    const params = {
      client_id: this._config.clientId,
      redirect_uri: this._getCallbackURL(),
      response_type: (this._config.authType === ClientSideOAuth2Component.TYPE.THREE_LEGGED_AUTHORIZATION) ?
        'code' : 'token',
      scope: this._config.scope!,
    };
    return objectToQueryString(params);
  }

  /**
   * Prepare a 3-legged token exchange query.
   * @return The query.
   */
  private _generateTokenExchangeQuery(): string {
    const params = {
      client_id: this._config.clientId,
      code: this._code!,
      grant_type: 'authorization_code',
      redirect_uri: this._getCallbackURL(),
    };
    return objectToQueryString(params);
  }

  /**
   * Prepare a 3-legged token refresh query.
   * @return The query.
   */
  private _generateTokenRefreshQuery(): string {
    const params = {
      client_id: this._config.clientId,
      grant_type: 'refresh_token',
      refresh_token: this._refreshToken!,
      scope: this._config.scope!,
    };
    return objectToQueryString(params);
  }

  /**
   * Get a 3-legged access token using the implicit grant flow. This function should not be called before
   * [[initializeComponent]] has been called.
   * @param callback Invoked with an error if fetching a bearer token
   *  failed, or a string representing the value of the bearer token.
   */
  private _3leggedImplicit(callback: ClientTokenCallbackType): void {
    this._extractTokenFromURL();
    if (!this._accessToken || this._expiresAt < Date.now()) {
      const query = this._generateThreeLeggedQuery();
      window.location.replace(this._config.authenticationEndpoint + '?' + query);
    } else {
      callback(undefined, this._accessToken);
    }
  }

  /**
   * Processes the result returned by `requestHTTPPromise` when querying for an access token either with
   * a refresh token or with an authentication code.
   * @param result Object containing data of the response of the called endpoint
   */
  private _handleAccessTokenResponse(result: IAccessTokenResponseResult) {
    this._accessToken = result.data.access_token;
    this._refreshToken = result.data.refresh_token;
    const expiresIn = result.data.expires_in;
    this._expiresAt = getTokenExpiry(expiresIn);
  }

  /**
   * Get a 3-legged access token using the implicit grant flow. This function should not be called before
   * [[initializeComponent]] has been called.
   * @param callback Invoked with an error if fetching a bearer token
   *  failed, or a string representing the value of the bearer token.
   */
  private _3leggedAuthorization(callback: ClientTokenCallbackType): void {
    this._extractCodeFromURL();
    if (!this._accessToken && !this._refreshToken) {
      if (this._code) {
        // Exchange the authorization code for an access token. This is handled by a server side component.
        const data = this._generateTokenExchangeQuery();
        requestHTTPPromise(this._config.tokenExchangeEndpoint!, this._headers, 'POST', data, REQUEST_OPTIONS)
          .then((result) => {
            this._handleAccessTokenResponse(result);
            this._code = undefined; // Invalidate code
            callback(undefined, this._accessToken);
          }).catch((error) => {
            callback(error);
          });
      } else {
        if (this._config.signedInEndpoint) {
          // making a request to this endpoint if available, should provide a way of storing session information
          // on the application server and obtaining a new access token if possible. For example, a tuple of refresh
          // token and session id can be stored to get the aforementioned token
          const data = this._generateTokenRefreshQuery();
          requestHTTPPromise(this._config.signedInEndpoint, this._headers, 'POST', data, REQUEST_OPTIONS)
            .then((result) => {
              if (!_.isEmpty(result.data)) {
                this._handleAccessTokenResponse(result);
                callback(undefined, this._accessToken);
                return;
              } else {
                // Get the authorization code
                const query = this._generateThreeLeggedQuery();
                window.location.replace(this._config.authenticationEndpoint + '?' + query);
              }
            }).catch((error) => {
              // The request to the signedInEndpoint went wrong (returned some HTTP error like 400+)
              // There is nothing to handle, though. We'll just go through the usual auth flow instead.
              // TODO: Is it a good practice to silently swallow the error? -> No. Log it as soon as we have proper
              // logging.
              // Get the authorization code
              const query = this._generateThreeLeggedQuery();
              window.location.replace(this._config.authenticationEndpoint + '?' + query);
            });
        } else {
          // No code and no 'signedInEndpoint' configured, so just trigger the normal auth flow.
          const query = this._generateThreeLeggedQuery();
          window.location.replace(this._config.authenticationEndpoint + '?' + query);
        }
      }
    } else if (this._refreshToken && this._expiresAt < Date.now()) {
      // The token that we have is expired and needs to be refreshed. This is handled by a server side component.
      const params = this._generateTokenRefreshQuery();
      requestHTTPPromise(this._config.tokenRefreshEndpoint!, this._headers, 'POST', params, REQUEST_OPTIONS)
        .then((result) => {
          this._handleAccessTokenResponse(result);
          callback(undefined, this._accessToken);
        }).catch((error) => {
          callback(error);
        });
    } else {
      callback(undefined, this._accessToken);
    }
  }
}

// We want to make sure that we implement all required static functions of an AppComponent.
// This check allows us to do so at compile time.
/* tslint:disable-next-line:no-unused-variable */
const staticComponentMemberCheck: IHasRequiredStaticAppComponentMembers = ClientSideOAuth2Component;
