import { SelectChangeEvent } from "@mui/material/Select";
import { useContext } from "react";
import ProductContext from "../../../../context/ProductStore/Product.context";

interface UseProductSelectionState {
  handleSelectedProductIdChange: (event: SelectChangeEvent) => void;
}

const useProductSelection = (): UseProductSelectionState => {
  const { setFilteredProductId } = useContext(ProductContext);

  const handleSelectedProductIdChange = (event: SelectChangeEvent) => {
    const selectedProductId = event.target.value;
    setFilteredProductId(selectedProductId);
  };

  return { handleSelectedProductIdChange };
};

export default useProductSelection;
