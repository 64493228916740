import { useState } from "react";
import {
  CustomAccordionContainer,
  CustomAccordionContent,
  CustomAccordionHeader,
  CustomAccordionTitle,
} from "./CustomAccordion.style";
import IconButton from "@mui/material/IconButton";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";

export interface CustomAccordionProps {
  title: any;
  children?: any;
}
export const CustomAccordion = ({ title, children }: CustomAccordionProps): JSX.Element => {
  const [expanded, setExpanded] = useState(true);

  const handleExpandToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <CustomAccordionContainer>
      <CustomAccordionHeader>
        <IconButton data-testid="accordion-button" onClick={handleExpandToggle} size="small">
          {expanded ? <ArrowDropDown /> : <ArrowDropUp />}
        </IconButton>
        <CustomAccordionTitle variant="body2">
          <strong>{title}</strong>
        </CustomAccordionTitle>
      </CustomAccordionHeader>
      <CustomAccordionContent expanded={expanded}>{children}</CustomAccordionContent>
    </CustomAccordionContainer>
  );
};
