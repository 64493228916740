import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useContext } from "react";
import { BIM360Project } from "types/bim360";
import AccountProjectContext from "../../../context/AccountProjectStore/AccountProject.context";
import { BIM360StorageKeys } from "../../../global/constants/bim360";
import { ProjectListContainer, ProjectsListTitle, ProjectsMenuList, ProjectsMenuListItem } from "./ProjectsList.style";
import { AccountDetails } from "../AccountDetails/AccountDetails";
import text from "../../../global/text.json";
import { MainTabsContext } from "../../../context/TabStore/Tab.context";
import { MAIN_TABS } from "../../../global/constants/tabs";

interface ProjectsListProps {
  projects: BIM360Project[] | undefined;
  visible: boolean;
  handleCloseProjectMenu: () => void;
}

export const ProjectsList = ({ projects, visible, handleCloseProjectMenu }: ProjectsListProps): JSX.Element => {
  const { setProjectId, setProjectName } = useContext(AccountProjectContext);
  const { handleChangeTab } = useContext(MainTabsContext);

  const handleProjectSelectionClick = (project: BIM360Project) => {
    const { id, title } = project;
    setProjectId(id);
    setProjectName(title);
    window.localStorage.setItem(
      BIM360StorageKeys.SELECTED_PROJECT,
      JSON.stringify({
        projectId: id,
        projectName: title,
      })
    );
    handleCloseProjectMenu();
    handleChangeTab(undefined, MAIN_TABS.PRODUCTS);
  };

  const projectListText = text.projectList;

  return (
    <ProjectListContainer visible={visible}>
      <AccountDetails projectsQty={projects?.length} />
      <Divider />
      <ProjectsListTitle>
        <Typography variant="h6">{projectListText.projects}</Typography>
      </ProjectsListTitle>
      {projects && (
        <ProjectsMenuList>
          {projects.map((project) => (
            <ProjectsMenuListItem key={project.title} onClick={() => handleProjectSelectionClick(project)}>
              <Typography variant="body2">{project.name}</Typography>
            </ProjectsMenuListItem>
          ))}
        </ProjectsMenuList>
      )}
    </ProjectListContainer>
  );
};
