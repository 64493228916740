import React, { useEffect, useMemo, useState } from "react";

import { DetailsSidebarContent, DetailsSidebarHeader } from "../../Commons/DetailsSidebar";

import CustomPagination from "../../Commons/CustomPagination";
import SidebarThumbnail from "../../Commons/SidebarThumbnail";
import CustomAccordion from "../../Commons/CustomAccordion";
import { KeyValueTableRow } from "../../../types/KeyValueTable";
import KeyValueTable from "../../Commons/KeyValueTable";
import { isMultiValueNumericInput, isMultiValueTextInput, isNumericInput } from "../../../utils/typeGuards";
import { DynamicContentProduct, DynamicContentVariant } from "../../../types/dynamicContent";
import { Instance } from "../../../types/product";
import useVariantThumbnail from "./useVariantThumbnail";
import {
  InstanceDetailsSidebar,
  InstanceThumbnailSpinner,
  InstanceThumbnailSpinnerContainer,
} from "./InstanceDetailsPanel.style";
import MissingThumbnail from "../../../../src/images/missingThumbnail.jpg";
import text from "../../../global/text.json";

interface InstanceDetailsPanelProps {
  visibleInstances: Instance[];
  selectedInstancesIds: string[];
  currentProduct: DynamicContentProduct | undefined;
  variants: DynamicContentVariant[];
  handleInstanceDetailsPanelVisibility: (showInstancesDetailsPanel: boolean) => void;
}

const InstanceDetailsPanel: React.FC<InstanceDetailsPanelProps> = ({
  visibleInstances,
  selectedInstancesIds,
  currentProduct,
  variants,
  handleInstanceDetailsPanelVisibility,
}): JSX.Element => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleCloseButtonClick = () => {
    handleInstanceDetailsPanelVisibility(false);
  };
  const handlePaginationChange = (index: number) => {
    setSelectedIndex(index);
  };

  const selectedVisibleInstances = useMemo(
    () => visibleInstances.filter((instance) => selectedInstancesIds.includes(instance.id)),
    [visibleInstances, selectedInstancesIds]
  );

  const currentInstance =
    selectedIndex !== null && selectedIndex < selectedVisibleInstances.length
      ? selectedVisibleInstances[selectedIndex]
      : null;

  const currentVariant = useMemo(
    () => variants.find((variant) => variant.variantId === currentInstance?.variantId),
    [variants, currentInstance]
  );

  const { variantThumbnail, isVariantThumbnailLoading } = useVariantThumbnail(currentVariant?.thumbnail);

  //  Check if there are any instances to display, otherwise close the panel
  useEffect(() => {
    if (selectedVisibleInstances.length === 0) {
      handleInstanceDetailsPanelVisibility(false);
    } else {
      handleInstanceDetailsPanelVisibility(true);
    }
  }, [selectedVisibleInstances.length, handleInstanceDetailsPanelVisibility]);

  const instanceDetailsText = text.instanceDetailsPanel;

  // Set Table Columns
  const productInfo: KeyValueTableRow[] = [
    {
      label: instanceDetailsText.iamFile,
      value: currentProduct?.context.projectFile,
    },
    { label: instanceDetailsText.category, value: currentInstance?.category },
    {
      label: instanceDetailsText.familyType,
      value: currentInstance?.familyType,
    },
    {
      label: instanceDetailsText.elementID,
      value: currentInstance?.elementId,
    },
    { label: instanceDetailsText.product, value: currentProduct?.name },
  ];

  const inputsInfo: KeyValueTableRow[] = currentVariant
    ? currentVariant.inputs.map((input) => {
        const label =
          isNumericInput(input) || isMultiValueNumericInput(input) || isMultiValueTextInput(input)
            ? `${input.name} (${input.unit})`
            : `${input.name}`;
        return {
          label,
          value: (input.value && input.value.toString()) || "",
        };
      })
    : [];
  //TODO: Add output info
  return (
    <InstanceDetailsSidebar>
      <DetailsSidebarHeader onClose={handleCloseButtonClick}>
        <CustomPagination
          count={selectedVisibleInstances.length}
          currentIndex={selectedIndex}
          onChange={handlePaginationChange}
        />
      </DetailsSidebarHeader>
      <DetailsSidebarContent>
        <CustomAccordion title={instanceDetailsText.preview}>
          {isVariantThumbnailLoading ? (
            <InstanceThumbnailSpinnerContainer>
              <InstanceThumbnailSpinner />
            </InstanceThumbnailSpinnerContainer>
          ) : (
            <SidebarThumbnail src={variantThumbnail || MissingThumbnail} />
          )}
        </CustomAccordion>
        <CustomAccordion title={instanceDetailsText.productSummary}>
          <KeyValueTable data={productInfo} />
        </CustomAccordion>
        <CustomAccordion title={`${instanceDetailsText.inputs} (${inputsInfo.length})`}>
          <KeyValueTable data={inputsInfo} />
        </CustomAccordion>
      </DetailsSidebarContent>
    </InstanceDetailsSidebar>
  );
};

export default InstanceDetailsPanel;
