import {
  BIM360Account,
  BIM360CurrentVersion,
  BIM360Derivative,
  BIM360DerivativeChild,
  BIM360Document,
  BIM360Folder,
  BIM360Project,
} from "types/bim360";
import { BIM360CurrentVersionProcessResult, BIM360CurrentVersionProcessState } from "../global/constants/bim360";
import logger from "../global/logger";
import { ErrorCode, MIDWError } from "../global/errors";
import { ApiServiceFactory } from "./api.service";
import { bim360Config, ServiceTypes } from "./config";
import text from "../global/text.json";

const bim360ServiceText = text.bim360Service;

export const getAccounts = async (token: string): Promise<BIM360Account[]> => {
  const apiService = ApiServiceFactory.createApiService(ServiceTypes.FORGEAPI, {
    token,
  });
  const { response } = apiService.abortableGet(bim360Config.accountEntitlementsPath);

  try {
    return (await response).data.accounts;
  } catch (e) {
    logger.error(e);
    throw new MIDWError(bim360ServiceText.accountsLoadError, ErrorCode.BIM360AccountsLoadError);
  }
};

export const getProjects = async (token: string): Promise<BIM360Project[]> => {
  const apiService = ApiServiceFactory.createApiService(ServiceTypes.FORGEAPI, {
    token,
  });
  const { response } = apiService.abortableGet(bim360Config.projectsPath);

  try {
    return (await response).data.projects;
  } catch (e) {
    logger.error(e);
    throw new MIDWError(bim360ServiceText.projectsLoadError, ErrorCode.BIM360ProjectsLoadError);
  }
};

export const getFolders = async (token: string, projectID: string): Promise<BIM360Folder[]> => {
  const apiService = ApiServiceFactory.createApiService(ServiceTypes.FORGEAPI, {
    token,
  });
  const url = `${bim360Config.projectsPath}/${projectID}/folders`;
  const { response } = apiService.abortableGet(url);

  try {
    return (await response).data.folders;
  } catch (e) {
    logger.error(e);
    throw new MIDWError(bim360ServiceText.foldersLoadError, ErrorCode.BIM360FoldersLoadError);
  }
};

export const getSubFolders = async (token: string, projectID: string, folderId: string): Promise<BIM360Folder[]> => {
  const apiService = ApiServiceFactory.createApiService(ServiceTypes.FORGEAPI, {
    token,
  });
  const url = `${bim360Config.projectsPath}/${projectID}/folders/${folderId}`;
  const { response } = apiService.abortableGet(url);

  try {
    return (await response).data.folders;
  } catch (e) {
    logger.error(e);
    throw new MIDWError(bim360ServiceText.subfoldersLoadError, ErrorCode.BIM360SubfoldersLoadError);
  }
};

const isCurrentDocVersionReady = (currentVersion: BIM360CurrentVersion): Boolean =>
  currentVersion.process_state === BIM360CurrentVersionProcessState.PROCESSING_COMPLETE &&
  currentVersion.process_result === BIM360CurrentVersionProcessResult.PROCESSING_SUCCESS;

export const getFolderContent = async (token: string, projectId: string, folderUrn: string): Promise<BIM360Document[]> => {
  const apiService = ApiServiceFactory.createApiService(ServiceTypes.FORGEAPI, {
    token,
  });
  const url = `${bim360Config.projectsPath}/${projectId}/folders/${folderUrn}/documents`;

  const { response } = apiService.abortableGet(url);

  try {
    const processedDocuments = (await response).data.documents.filter((doc: BIM360Document) =>
      isCurrentDocVersionReady(doc.current_version)
    );

    return processedDocuments;
  } catch (e) {
    logger.error(e);
    throw new MIDWError(bim360ServiceText.folderContentLoadError, ErrorCode.BIM360FolderContentLoadError);
  }
};

export const getThumbnail = async (token: string, documentId: string): Promise<Blob> => {
  const apiService = ApiServiceFactory.createApiService(ServiceTypes.FORGEAPI, {
    token,
  });
  const manifestUrl = `${bim360Config.modelDerivativePath}/${documentId}/manifest`;

  const { response: getManifestResponse } = apiService.abortableGet(manifestUrl);

  let getManifestResult;

  try {
    getManifestResult = await getManifestResponse;
  } catch (e) {
    logger.error(e);
    throw new MIDWError(bim360ServiceText.folderManifestLoadError, ErrorCode.BIM360FolderManifestLoadError);
  }

  let guidPath;
  if (getManifestResult.data?.derivatives) {
    const { derivatives } = getManifestResult.data;
    const svfDerivatives = derivatives.find((derivative: BIM360Derivative) => derivative.outputType === "svf");
    const svf3DDerivatives = svfDerivatives?.children.find(
      (svfDerivative: BIM360DerivativeChild) => svfDerivative.role === "3d"
    );
    guidPath = svf3DDerivatives.guid && `&guid=${svf3DDerivatives.guid}`;
  }

  const thumbnailUrl = `${bim360Config.documentThumbnailPath}/${documentId}?type=large${guidPath}`;
  const { response: getThumbnailResponse } = apiService.abortableGet(thumbnailUrl, {
    fetchBlob: true,
  });

  try {
    const { data: thumbnailData } = await getThumbnailResponse;

    return new Blob([thumbnailData as BlobPart], { type: "image/png" });
  } catch (e) {
    logger.error(e);
    throw new MIDWError(bim360ServiceText.documentThumbnailLoadError, ErrorCode.BIM360DocumenThumbnailLoadError);
  }
};
