import { Property, RevitElement } from "types/scopeOfWork";

import { FormatOptions, convertToFeetInDimension, formatSelectedUnit, getUnitsDimension } from "../../utils/units";

export function getPropertyAttributeValueByName(elt: RevitElement, attributeName: string, defaultValue?: any): any {
  const attr = elt.properties.find((p) => p.attributeName === attributeName);
  return attr ? attr.displayValue : defaultValue;
}

/**
 * Returns ID within square brackets at the end of element name
 * @param name name of element
 * @example
 * getNameId("Test [123]") // "123"
 */
export function getNameId(name?: string): string {
  const m = name?.match(/^.*\[(\d+)\]$/);
  return m && m.length > 1 ? m[1] : "";
}

export function formatUnits(prop: Property, options: FormatOptions): string {
  const { units, displayValue } = prop;
  const unitsAndDimension = getUnitsDimension(units);

  if (unitsAndDimension) {
    const { unit, dimension } = unitsAndDimension;
    const convertedValue = convertToFeetInDimension(displayValue, unit, dimension);

    return formatSelectedUnit(convertedValue, options, dimension);
  }

  return units ? `${displayValue} ${units}` : displayValue;
}
