//TODO: handle pagination for Products and Variants

import { isRejected, isFulfilled } from "../utils/typeGuards";
import logger from "../global/logger";
import { ErrorCode, MIDWError } from "../global/errors";
import {
  DynamicContentProduct,
  DynamicContentVariant,
  VariantsQueryParameters,
  BatchFetchVariantsResult,
  BatchFetchProductsResult,
} from "../types/dynamicContent";

import { ApiServiceFactory } from "./api.service";
import { ServiceTypes } from "./config";

import text from "../global/text.json";

const productsServiceText = text.productsService;
const apiServiceText = text.apiService;

export async function fetchProducts(token: string, projectId: string): Promise<DynamicContentProduct[] | null> {
  if (!token || !projectId) {
    return null;
  }

  const apiService = ApiServiceFactory.createApiService(ServiceTypes.offsiteAPI, { token });
  const path = `projects/${projectId}/products`;
  const { response } = apiService.abortableGet(path);

  try {
    return (await response).data.results;
  } catch (e) {
    logger.error(e);
    throw new MIDWError(productsServiceText.productsLoadError, ErrorCode.ProductsLoadError);
  }
}

export async function fetchVariants(
  token: string,
  projectId: string,
  productId: string
): Promise<DynamicContentVariant[] | null> {
  if (!token || !projectId || !productId) {
    return null;
  }

  const apiService = ApiServiceFactory.createApiService(ServiceTypes.offsiteAPI, { token });
  const path = `projects/${projectId}/products/${productId}/variants`;
  const { response } = apiService.abortableGet(path);

  try {
    return (await response).data.results;
  } catch (e) {
    logger.error(e);
    throw new MIDWError(productsServiceText.variantsLoadError, ErrorCode.VariantsLoadError);
  }
}

export async function batchFetchProducts(token: string, projectIds: string[]): Promise<BatchFetchProductsResult | null> {
  if (!token) {
    throw new MIDWError(apiServiceText.unauthorizedAccessError, ErrorCode.UnauthorizedAccessError);
  }
  if (projectIds.length === 0) {
    return null;
  }

  const apiService = ApiServiceFactory.createApiService(ServiceTypes.offsiteAPI, { token });

  const resultsPromise = projectIds.map(async (projectId) => {
    const path = `projects/${projectId}/products`;
    const { response } = apiService.abortableGet(path);
    const { data } = await response;

    return data.results;
  });

  const results = await Promise.allSettled<DynamicContentProduct[]>(resultsPromise);

  return processAllSettledResults(results);
}

export async function batchFetchVariants(
  token: string,
  variantsParameters: VariantsQueryParameters[]
): Promise<BatchFetchVariantsResult | null> {
  if (!token) {
    throw new MIDWError(apiServiceText.unauthorizedAccessError, ErrorCode.UnauthorizedAccessError);
  }
  if (variantsParameters.length === 0) {
    return null;
  }

  const apiService = ApiServiceFactory.createApiService(ServiceTypes.offsiteAPI, { token });
  const resultsPromise = variantsParameters.map(async (variantInfo) => {
    const path = `projects/${variantInfo.projectId}/products/${variantInfo.productId}/variants`;

    const { response } = apiService.abortableGet(path);
    const { data } = await response;
    return data.results;
  });
  const results = await Promise.allSettled<DynamicContentVariant[]>(resultsPromise);

  return processAllSettledResults(results);
}

export async function fetchFileUrl(token: string, projectId: string, objectKey: string): Promise<string | null> {
  if (!token || !projectId || !objectKey) {
    return null;
  }

  const apiService = ApiServiceFactory.createApiService(ServiceTypes.offsiteAPI, { token });
  const path = `projects/${projectId}/data/downloadurl`;
  const postData = { objectKey };
  const { response } = apiService.abortablePost(path, postData);

  try {
    return (await response).data.signedUrl;
  } catch (e) {
    logger.error(e);
    throw new MIDWError("Can't get file download URL", ErrorCode.FileDownloadURLLoadError);
  }
}

function processAllSettledResults<T>(resultsPromise: PromiseSettledResult<T>[]) {
  const results = resultsPromise
    .filter(isFulfilled)
    .map<T>((val) => val.value)
    .flat();

  const errors = resultsPromise.filter(isRejected);

  return { results, errors };
}
