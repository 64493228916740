import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

export const AppContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  minWidth: "900px",
  overflow: "hidden",
}));

export const TabsContainer = styled(Box)(() => ({
  borderBottom: 1,
  borderColor: "divider",
}));
