import React from "react";

import TaskOutlined from "@mui/icons-material/TaskOutlined";
import ErrorOutlined from "@mui/icons-material/ErrorOutlined";
import CancelOutlined from "@mui/icons-material/CancelOutlined";
import QuestionMarkOutlined from "@mui/icons-material/QuestionMarkOutlined";

import text from "../../../global/text.json";
import { Tooltip, CircularProgress } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { VariantOutputStatus } from "../../../types/dynamicContent";

const StatusOutputsCell: React.FC<GridRenderCellParams> = ({ value }) => {
  switch (value) {
    case VariantOutputStatus.PENDING:
    case VariantOutputStatus.PROCESSING:
      return (
        <Tooltip title={value}>
          <CircularProgress size={25} />
        </Tooltip>
      );
    case VariantOutputStatus.SUCCESS:
      return (
        <Tooltip title={value}>
          <TaskOutlined />
        </Tooltip>
      );
    case VariantOutputStatus.FAILED:
      return (
        <Tooltip title={value}>
          <ErrorOutlined />
        </Tooltip>
      );
    case VariantOutputStatus.CANCELLED:
      return (
        <Tooltip title={value}>
          <CancelOutlined />
        </Tooltip>
      );
    default:
      return (
        <Tooltip title={text.common.notAvailable}>
          <QuestionMarkOutlined />
        </Tooltip>
      );
  }
};

export default StatusOutputsCell;
