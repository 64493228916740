import HigCaretLeftImage from "../../../images/hig-icons/caret-left-24.png";
import HigCaretRightImage from "../../../images/hig-icons/caret-right-24.png";
import HigEditProfileImage from "../../../images/hig-icons/edit-profile-24.svg";
import HigFileImage from "../../../images/hig-icons/file-generic-24.png";
import HigFolderImage from "../../../images/hig-icons/folder-24.png";
import HigFolderOpenImage from "../../../images/hig-icons/folder-open-24.png";
import HigSearchImage from "../../../images/hig-icons/search-24.png";

import { CustomIcon } from "./CustomIcon";

interface HigIconsProps {
  width?: string;
}

const generateHigIcon =
  (icon: string) =>
  ({ width }: HigIconsProps): JSX.Element =>
    <CustomIcon icon={icon} width={width || "24"} />;

export const HigCaretLeft = generateHigIcon(HigCaretLeftImage);
export const HigCaretRight = generateHigIcon(HigCaretRightImage);
export const HigEditProfile = generateHigIcon(HigEditProfileImage);
export const HigFile = generateHigIcon(HigFileImage);
export const HigFolder = generateHigIcon(HigFolderImage);
export const HigFolderOpen = generateHigIcon(HigFolderOpenImage);
export const HigSearch = generateHigIcon(HigSearchImage);
