import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

type AccountArrowIconProps = {
  anchorElProject: HTMLElement | null;
};

export const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
}));

export const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: "100%",
  paddingInline: theme.spacing(5, 1),
}));

export const SelectedProjectHeaderContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  flexGrow: 0,
  marginRight: "12px",
  minWidth: "300px",
  maxWidth: "500px",
  overflow: "hidden",
  justifyContent: "space-between",
}));

export const AccountLogoTitleContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "12px",
  flexGrow: "10",
  overflow: "hidden",
}));

export const AccountLogo = styled("img")(() => ({
  height: "34px",
  width: "auto",

  backgroundColor: "#666",
}));

export const ProjectSelectorPanelPopoverContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  padding: "10px",
  minWidth: "400px",
  maxWidth: "400px",
  overflow: "hidden",
  transition: "all .5s ease-in-out",
}));

export const SelectorsContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
}));

export const AccountArrowIcon = styled(ArrowDropDownIcon, {
  shouldForwardProp: (props) => props !== "anchorElProject",
})<AccountArrowIconProps>((props) => ({
  transform: props.anchorElProject ? "rotate(180deg)" : "",
}));

export const AccountAndProjectLoading = styled(LinearProgress)(() => ({
  width: "100%",
}));
