import { useContext, useState } from "react";
import AccountProjectContext from "../../../../context/AccountProjectStore/AccountProject.context";
import NotificationContext from "../../../../context/NotificationStore/Notification.context";
import UserContext from "../../../../context/UserStore/User.context";
import logger from "../../../../global/logger";
import { getVariantFromAPI, postVariantToAPI } from "../../../../services/variants";
import { DynamicContentProduct, DynamicContentVariant, VariantOutputType } from "../../../../types/dynamicContent";
import { NOTIFICATION_STATUSES } from "../../../../types/notifications";
import { transformToVariantPayload } from "../instancesPanel.utils";
import text from "../../../../global/text.json";
import { Instance } from "../../../../types/product";
import ProductContext from "../../../../context/ProductStore/Product.context";

const outputsText = text.useGenerateOutputs;
const productsText = text.productsStore;
const commonText = text.common;
export interface UseGenerateOutputsState {
  disabled: boolean;
  handleGenerateOutputsButtonClick: () => Promise<void>;
}

const useGenerateOutputs = (selectedInstances: Instance[]): UseGenerateOutputsState => {
  const [disabled, setDisabled] = useState(false);
  const { token } = useContext(UserContext);
  const { projectId } = useContext(AccountProjectContext);
  const { products } = useContext(ProductContext);
  const { showNotification } = useContext(NotificationContext);

  const _notifySelectAtLeastOneInstanceError = () => {
    logger.error(outputsText.selectAtLeastOneInstance);
    showNotification({
      severity: NOTIFICATION_STATUSES.ERROR,
      message: outputsText.selectAtLeastOneInstance,
    });
  };

  const _notifyTriggerJobFail = (err: unknown, productId: string, currentVariantId: string) => {
    logger.error(outputsText.failTriggerJob, {
      err,
      projectId,
      productId,
      currentVariantId,
    });
    showNotification({
      severity: NOTIFICATION_STATUSES.ERROR,
      message: outputsText.failTriggerJob,
    });
  };

  const _notifyProductNotSupportBOM = () => {
    showNotification({
      severity: NOTIFICATION_STATUSES.ERROR,
      message: outputsText.productNotSupportBOM,
    });
    logger.warn(outputsText.productNotSupportBOM);
  };

  const _notifyNoProjectSelected = () => {
    logger.error(commonText.noProjectSelected);
    showNotification({
      severity: NOTIFICATION_STATUSES.ERROR,
      message: commonText.noProjectSelected,
    });
  };

  const _notifySuccessTriggerJob = (currentVariantName: string) => {
    showNotification({
      severity: NOTIFICATION_STATUSES.SUCCESS,
      message: `${outputsText.successTriggerJob} ${currentVariantName}`,
    });
  };

  const _notifyVariantBOMAlreadyAvailable = (currentVariantName: string) => {
    showNotification({
      severity: NOTIFICATION_STATUSES.SUCCESS,
      message: `${outputsText.bomAlreadyAvailableForVariant} ${currentVariantName}`,
    });
  };

  const _getCurrentProduct = (productId: string) => {
    const currentProduct = products.get(productId);
    if (!currentProduct) {
      showNotification({
        severity: NOTIFICATION_STATUSES.ERROR,
        message: productsText.failedToFetchAssociateProducts,
      });
      throw new Error(productsText.failedToFetchAssociateProducts);
    }
    return currentProduct;
  };

  const _getVariantIdProductMap = () =>
    selectedInstances.reduce(
      (prev: { [key: string]: DynamicContentProduct }, current: Instance): { [key: string]: DynamicContentProduct } => {
        if (!prev[current.variantId]) {
          const currentProduct = _getCurrentProduct(current.contentId);
          prev[current.variantId] = currentProduct;
        }
        return prev;
      },
      {}
    );

  const handleGenerateOutputsButtonClick = async () => {
    if (selectedInstances.length <= 0) {
      _notifySelectAtLeastOneInstanceError();
      return;
    }
    if (projectId) {
      setDisabled(true);
      const variantIdProductMap = _getVariantIdProductMap();

      const allGenerateOutputsJobs: Promise<void>[] = Object.keys(variantIdProductMap).map(async (currentVariantId) => {
        const currentProduct = variantIdProductMap[currentVariantId];
        try {
          const currentVariant = await getVariantFromAPI(token, projectId, currentProduct.contentId, currentVariantId);
          const variantBOMOutput = currentVariant.outputs.find((output) => output.type === VariantOutputType.BOM);

          // If Variant has no BOM Output yet, we will POST to generate it
          if (!variantBOMOutput) {
            const productBOMOutput = currentProduct.outputs.find((output) => output.type === VariantOutputType.BOM);
            // If Product does not support BOM output, we just notify user and leave
            if (!productBOMOutput) {
              _notifyProductNotSupportBOM();
              return;
            }

            // Transform to variant payload
            const postVariantPayload = transformToVariantPayload(currentVariant.inputs, productBOMOutput);
            // Trigger job processing
            // Post Variant
            const postVariantResponse: DynamicContentVariant = await postVariantToAPI(
              token,
              projectId,
              currentProduct.contentId,
              postVariantPayload
            );
            _notifySuccessTriggerJob(postVariantResponse.name);
            return;
          }
          _notifyVariantBOMAlreadyAvailable(currentVariant.name);
        } catch (err) {
          _notifyTriggerJobFail(err, currentProduct.contentId, currentVariantId);
        }
      });

      await Promise.all(allGenerateOutputsJobs);
      setDisabled(false);
    } else {
      _notifyNoProjectSelected();
    }
  };

  return {
    disabled,
    handleGenerateOutputsButtonClick,
  };
};

export default useGenerateOutputs;
